import React, { useState } from "react";
import axios from "axios";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import Button from "@mui/material/Button";
import PrintGrid from "../../components/printGrid";
import styled from "styled-components";
import { useNavigate, useParams } from "react-router-dom";
import "../../config";
import { Footer } from "../../components/formLayout";
import ComboLookupCode from "../../components/comboLookup";

const BoxButtons = styled.div`
  display: flex;
  flex-direction: row-reverse;
  background-color: #4a4c52;
  & button {
    margin: 10px;
  }
`;
const FormContainer = styled.div`
  background-color: #f4f4f4;
  padding: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 50px;
  & div {
    margin-right: 10px;
  }
`;

const PatientSearchContainer = styled.div`
  height: 90vh;
  display: flex;
  flex-direction: column;
  flex: 1;

  .title {
    background-color: #4a4c52;
    height: 40px;
    text-align: center;

    h2 {
      margin: 8px;
      color: #ffffff;
      font-size: 18px;
    }
  }
`;

export const PatientListContainer = styled.div`
  .MuiDataGrid-columnHeaders {
    background-color: #ebf3fc;

    .MuiDataGrid-columnHeader {
      border-right: 1px solid #ededed;
    }
  }

  .MuiDataGrid-cell {
    border-right: 1px solid #ededed;
  }

  .MuiDataGrid-row {
    &:nth-child(even) {
      background-color: #fafafa;
    }
  }
`;

const gridRows=[
              {
                id: 1,
                code: "DSL1F2F",
                name: "Waiting List Addition - Patient",
                action: "Doc/PDF",
                destination: "Word",
                copies: 1,
                printStatus: "Printer",
              },
              {
                id: 2,
                code: "OUTAPPNEW2",
                name: "Appointment Notification - Patient",
                action: "Printer",
                destination: "Letter",
                copies: 1,
                printStatus: "Printer",
              },
              {
                id: 3,
                code: "REFACKP12",
                name: "Referral Acknowledgement - Patient",
                action: "Printer",
                destination: "Letter",
                copies: 1,
                printStatus: "Printer",
              },
            ];

function PrintDialogue(props) {
  const { onClose, value, open } = props;
  const [patients, setPatients] = useState([]);
  const cfg = global.config.restAPI;

  return (
    <Dialog
      fullWidth={true}
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          maxWidth: "lg",
          minHeight: "70vh",
        },
      }}
    >
      <DialogTitle>Print</DialogTitle>
      <PatientSearchContainer>
        <PatientListContainer style={{ flex: 1, overflow: "scroll" }}>
          <PrintGrid
            rows={gridRows}
          />
        </PatientListContainer>
        <FormContainer>
          <div>Document Riders </div>
          <ComboLookupCode value="TRFC" />
          <ComboLookupCode />
        </FormContainer>
      </PatientSearchContainer>
      <Footer dialog>
        <div>
          <BoxButtons>
            <Button
              variant="contained"
              color="primary"
              onClick={onClose}
              className="btn-primary"
            >
              Print
            </Button>
            <Button variant="outlined" onClick={onClose} className="btn-cancel">
              Cancel
            </Button>
          </BoxButtons>
        </div>
      </Footer>
    </Dialog>
  );
}
export default PrintDialogue;
