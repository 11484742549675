import React, { useState, useEffect } from "react";
import styled from "styled-components";

const MiyaRecordSection = styled.div`
  margin: 0 10%;
  padding: 1rem;
  flex: 1 1 48%;
  border-radius: 4px;
  min-height: 50px;
  margin-top: 1rem;
  background-color: #fff;
  & .detail {
    display: flex;
    flex-flow: row wrap;
    align-items: flex-start;
    align-content: flex-start;
  }
  & .info {
    flex: 0 1 200px;
    margin-bottom: 10px;
    font-size: 14px;
  }
  & .info dl dt {
    margin-bottom: 2px;
    color: #828282;
    margin: 0;
    padding: 0;
    font-weight: 400;
    line-height: normal;
  }
  & .info dl dd {
margin: 0;
    padding: 0;
    font-weight: 400;
    line-height: normal;
    }

}
`;
const Title = styled.span`
    font-size: 16px;
    font-weight: 600;
    height: 17.5938px;
    line-height: 17.6px;
    color: rgb(0, 0, 0);
    display: block;
    font-family: "Source Sans Pro", sans-serif;
`

const DisplaySection = ({title, labelMap, data}) => {
  const sectionItems = Object.entries(labelMap).map(([key, label]) => {
    const value = data[key];
    return value !== null ? (
      <div class="info">
        <dl key={key}>
          <dt>{label}</dt>
          <dd>{value}</dd>
        </dl>
      </div>
    ) : null;
  });

  return (
    <MiyaRecordSection>
      <Title>{title}</Title>
      <div class="detail">{sectionItems}</div>
    </MiyaRecordSection>
  );
};
export default DisplaySection;
