import React, { useState } from "react";
import {
  IconButton,
  Button,
  InputLabel,
  TextField,
  InputAdornment,
} from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import ManageSearchIcon from "@mui/icons-material/ManageSearch";

import ClinicSearchDialogue from "./clinicSearchDialogue";
import styled from "styled-components";
import { Row, Column, SectionTitle, VSpace } from "../../components/formLayout";

const ClinicSlotSelector = ({
  selectedSlotObj,
  onChange,
  clinicCode,
  specialtyCode,
  label
}) => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
    onChange(value);
  };

  const slotDisplay = (obj) => {
    if (obj) {
      return `${obj.slotdate} ${obj.slottime}`;
    } else return "-";
  };

  return (
    <React.Fragment>
      <Column>
        <InputLabel className="input-label">{label}</InputLabel>

        <TextField
          value={slotDisplay(selectedSlotObj)}
          variant="outlined"
          InputLabelProps={{ shrink: false }}
          InputProps={{
            readOnly: true,
            endAdornment: 
            <InputAdornment style={{ marginTop: -5 }}>
              <IconButton >
                <ManageSearchIcon />
              </IconButton>
            </InputAdornment>,
        
          }}onClick={handleClickOpen}
          size="small"
          style={{ width: 300, marginTop: 0 }}
        />
      </Column>
      <ClinicSearchDialogue
        value={selectedSlotObj}
        clinicCode={clinicCode}
        specialtyCode={specialtyCode}
        open={open}
        onClose={handleClose}
      />
    </React.Fragment>
  );
};

export default ClinicSlotSelector;
