import React, { useState, useEffect } from "react";
import axios from "axios";
import styled from "styled-components";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Grid, InputLabel, Select, MenuItem } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

import WaitlistGrid from "../components/waitlist/waitlistGrid";
import BannerReferral from "../components/bannerReferral";
import NavAppBar from "../components/navAppBar";
import "../config";
import { PatientListContainer } from "./patientList";

const BannerBox = styled.div`
  background: rgb(234, 235, 242);
`;

export const ListContainer = styled.div`
  height: 90vh;
  display: flex;
  flex-direction: column;
  flex: 1;

  .title-bar {
    .title {
      margin-right: 0;
    }
  }
`;

const WaitlistList = () => {
  const cfg = global.config.restAPI; //"http://localhost:3000/";
  const [rttList, setRttList] = useState([]);

  const navigate = useNavigate();
  const { patientId, referralId } = useParams();

  useEffect(() => {
    // Fetch rtt for the referral
    axios
      .get(
        `${cfg.url + cfg.endpoints.rtt}/?q={"internalpatientid":{"$eq":"${patientId}"},"referralnumber":{"$eq":"${referralId}"}}`,
      )
      .then((response) => {
        const allItems = response.data.items;
        setRttList(allItems);
      });
  }, [patientId, referralId, cfg]);

  return (
    <ListContainer>
      <NavAppBar title="Waitlist Details">
        <Button
          variant="outlined"
          onClick={() => navigate(`/clinic/patient/${patientId}/referral/${referralId}/appointment/create`)}
          startIcon={<AddIcon />}
        >
          Appointment{" "}
        </Button>
        <Button
            variant="outlined"
          >
          More{" "}
          </Button>
      </NavAppBar>
      <BannerBox>
        <BannerReferral
          patientId={patientId}
          referralId={referralId}
          onSelect={() => navigate(`/referral/patient/${patientId}/referral/referralId`)}
        />
      </BannerBox>
      <ListContainer style={{ flex: 1, overflow: "scroll" }}>
{!rttList && 
        <WaitlistGrid rttList={rttList} onSelect={(rttId)=>navigate(`../patient/${patientId}/referral/${referralId}/rtt/${rttId}`)} />}
{!rttList && <p style={{ marginLeft: 24 }}>Loading...</p>}
      </ListContainer>
    </ListContainer>
  );
};

export default WaitlistList;
