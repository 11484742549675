import React, { useState, useEffect } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import axios from "axios";
import "../config";

const ComboOrgDets = ({ label, value, error, onChange }) => {
  const [data, setData] = useState([]);
  const cfg = global.config.restAPI;

  useEffect(() => {
    const query = `${cfg.url + cfg.endpoints.orgDets.referringSource}`;
    const fetchData = async () => {
      axios.get(query).then((response) => {
        setData(response.data.items);
      });
    };

    if (cfg.endpoints.discipDets.specialty) fetchData();
    //    else

    //throw(new Error(`Can't find ${refDomain} in config.js`));
  }, [cfg]);

  return (
    <Autocomplete
      isOptionEqualToValue={(option, value) => option.obj_disc === value}
      onChange={(e, newData) => {
        const val = newData ? newData.obj_disc : null;
        onChange(val);
      }}
      options={data}
      getOptionLabel={(o) => o.obj_desc || ""}
      style={{ width: 300 }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          variant="outlined"
          error={error ? true : false}
          helperText={error}
          InputLabelProps={{ shrink: false }}
          style={{ marginTop: 0 }}
        />
      )}
      size="small"
    />
  );
};

export default ComboOrgDets;
