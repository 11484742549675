import React, { useEffect, useState } from "react";
import {
  HashRouter as Router,
  Route,
  Routes,
  useNavigate,
  useLocation,
  Navigate,
  Outlet,
} from "react-router-dom";
import PatientList from "./pages/patientList";
import PatientDetails from "./pages/patientDetails";
import ReferralMgr from "./pages/referralMgr";
import ReferralList from "./pages/referralList";
import ReferralForm from "./pages/referralForm";
import ReferralDetails from "./pages/referralDetails";
import WaitlistMgr from "./pages/waitlistMgr";
import WaitlistList from "./pages/waitlistList";
import WaitlistForm from "./pages/waitlistForm";
import WaitlistInpatientForm from "./pages/waitlistInpatientForm";
import WaitlistDetails from "./pages/waitlistDetails";
import WaitlistInpatientDetails from "./pages/waitlistInpatientDetails";
import AppointmentForm from "./pages/appointmentForm"; //Clinic Appointment
import AppointmentDetails from "./pages/appointmentDetails";
import RttPatientList from "./pages/rttPatientList";
import RttPatientDetails from "./pages/rttPatientDetails";
import ClinicMgr from "./pages/clinicMgr";
import ConfigClinicMgr from "./pages/configClinicMgr";
import ClinicForm from "./pages/clinicForm";
import ClinicSlotList from "./pages/clinicSlotList";
import ClinicSlotForm from "./pages/clinicSlotForm";
import TheatreMgr from "./pages/theatreMgr";
import BookingForm from "./pages/bookingForm"; //Theatre Booking
import BookingDetails from "./pages/bookingDetails"; //Theatre Booking
import ConfigTheatreMgr from "./pages/configTheatreMgr";
import TheatreForm from "./pages/theatreForm";
import TheatreSlotList from "./pages/theatreSlotList";
import TheatreSlotForm from "./pages/theatreSlotForm";
import NotFound from "./pages/notFound";

import { AppBar, Button, Toolbar } from "@mui/material";
import Logo from "./assets/miya-pas-logo-blackbg.svg"; //logo.svg"; // Import the logo image
import { ThemeProvider, createTheme } from "@mui/material/styles";
import styled from "styled-components";
import "./muiTheme";
import MiyaAppMenu from "./components/miyaAppMenu";
import AppointmentList from "./pages/AppointmentList";
import WardMgr from "./pages/wardAttender";
import WardForm from "./pages/wardForm";
import WardDetails from "./pages/wardDetails";

import Login from "./pages/login";
import UserMenu from "./components/userMenu";
import { AuthenticatedTemplate, useIsAuthenticated } from "@azure/msal-react";

const Title = styled.h3`
  color: white;
`;
const ToolbarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: end;
`;

const theme = createTheme(global.muiTheme);

const Layout = ({ type }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const menuButtons = [
    { type: "root", path: "/referral/mgr", title: "Referrals" },
    { type: "root", path: "/waitlist/mgr", title: "Waitlists" },
    //{ type: "theatre", path: "config", title: "Configuration" },
    { type: "root", path: "/clinic/mgr", title: "Clinics" },
    { type: "root", path: "/theatre/mgr", title: "Theatres" },
    //{ type: "outpatient", path: "wardAttenders", title: "Ward Attenders" },
    //{ type: "outpatient", path: "config/clinics", title: "Configuration" },
  ];

  const menuSelected = (menuPath) => {
    const match = location.pathname.startsWith(menuPath.slice(0,6));
    return match ? "contained" : "";
  };

  return (
    <ThemeProvider theme={theme}>
      <AppBar position="fixed" style={{ backgroundColor: "black" }}>
        <ToolbarContainer>
          <Toolbar>
            <MiyaAppMenu />
            <img
              src={Logo}
              alt="Logo"
              style={{ marginRight: "10px", height: "35px", padding: "15px 0px" }}
            />

            {menuButtons
              //.filter((i) => i.type === type)
              .map((item, idx) => (
                <Button
                  variant={menuSelected(item.path)}
                  onClick={() => navigate(item.path)}
                  style={idx === 0 ? { marginLeft: 16 } : {}}
                >
                  {item.title}
                </Button>
              ))}
          </Toolbar>
          <AuthenticatedTemplate>
            <UserMenu />
          </AuthenticatedTemplate>
        </ToolbarContainer>
      </AppBar>
      <main style={{ paddingTop: 60, display: "flex" }}>
        {" "}
        <Outlet />{" "}
      </main>
    </ThemeProvider>
  );
};

const PrivateRoutes = () => {
  const isAuthenticated = useIsAuthenticated();
  const location = useLocation();

  if (isAuthenticated === undefined) {
    return null; // or loading indicator/spinner/etc
  } else if (location.pathname === "/") {
    return <Navigate to="/referral/mgr" />;
  }

  return isAuthenticated ? (
    <Outlet />
  ) : (
    <Navigate to="/login" replace state={{ from: location }} />
  );
};

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<PrivateRoutes />}>
            <Route path="/patient" element={<Layout type="patient" />}>
              <Route path="search" exact Component={PatientList} />
              <Route path=":id" Component={PatientDetails} />
            </Route>
            <Route path="/waitlist" element={<Layout type="waitlist" />}>
              <Route path="mgr" Component={WaitlistMgr} />
              <Route
                path="patient/:patientId/referral/:referralId"
                Component={WaitlistList}
              />
              <Route
                path="patient/:patientId/referral/:referralId/create"
                Component={WaitlistForm}
              />
              <Route path="create" Component={WaitlistForm} />
              <Route path="theatre/:listRef/create" Component={WaitlistInpatientForm} />
              <Route
                path="patient/:patientId/referral/:referralId/:waitlistId"
                Component={WaitlistDetails}
              />
              <Route
                path="inpatient/patient/:patientId/:waitlistId"
                Component={WaitlistInpatientDetails}
              />
              <Route
                path="patient/:patientId/referral/:referralId/rtt/"
                Component={RttPatientList}
              />
              <Route
                path="patient/:patientId/referral/:referralId/rtt/:rttId"
                Component={RttPatientDetails}
              />
            </Route>
            <Route path="/theatre" element={<Layout type="theatre" />}>
              <Route path="mgr/" Component={TheatreMgr} />
              <Route path="mgr/:view" Component={TheatreMgr} />
              <Route path="config" Component={ConfigTheatreMgr} />
              <Route path="config/create" Component={TheatreForm} />
              <Route
                path="config/:theatreCode/slots"
                Component={TheatreSlotList}
              />
              <Route
                path="config/:theatreCode/slot/create"
                Component={TheatreSlotForm}
              />
              <Route
                path="patient/:patientId/booking/create"
                Component={BookingForm}
              />
              <Route
                path=":sessionCode/:sessionDate/booking/create"
                Component={BookingForm}
              />
              <Route
                path=":sessionCode/booking/create"
                Component={BookingForm}
              />
              <Route
                path=":patientId/:sessionCode/:sessionDate/booking"
                Component={BookingDetails}
              />
            </Route>
            <Route path="/referral" element={<Layout type="referral" />}>
              <Route path="mgr" element={<ReferralMgr />} />
              <Route path="patient/:patientId/" Component={ReferralList} />
              <Route
                path="patient/:patientId/create"
                Component={ReferralForm}
              />
              <Route
                path="patient/:patientId/referral/:referralId/edit"
                Component={ReferralForm}
              />
              <Route path="referral/create" Component={ReferralForm} />
              <Route
                path="patient/:patientId/referral/:referralId"
                Component={ReferralDetails}
              />
            </Route>
            <Route path="/clinic" element={<Layout type="clinic" />}>
              <Route path="mgr" Component={ClinicMgr} />
              <Route path="mgr/:view" Component={ClinicMgr} />
              <Route
                path="patient/:patientId/referral/:referralId/appointment/"
                Component={AppointmentList}
              />
              <Route
                path="patient/:patientId/referral/:referralId/appointment/:appointmentId"
                Component={AppointmentDetails}
              />
              <Route
                path="patient/:patientId/referral/:referralId/appointment/create"
                Component={AppointmentForm}
              />
              <Route
                path=":clinicCode/appointment/create"
                Component={AppointmentForm}
              />
              <Route
                path=":clinicCode/slot/:slotUniqueKey/appointment/create"
                Component={AppointmentForm}
              />
              <Route path="config/" Component={ConfigClinicMgr} />
              <Route path="config/create" Component={ClinicForm} />
              <Route
                path="config/clinic/:clinicCode/slots"
                Component={ClinicSlotList}
              />
              <Route
                path="config/clinic/:clinicCode/slot/create"
                Component={ClinicSlotForm}
              />
              <Route path="wardAttenders/" Component={WardMgr} />
              <Route
                path="patient/:patientId/referral/:referralId/wardAttender/:wardCode"
                Component={WardDetails}
              />
              <Route
                path="patient/:patientId/referral/:referralId/wardAttender/create"
                Component={WardForm}
              />
              <Route path="wardAttender/create" Component={WardForm} />
            </Route>
          </Route>

          <Route path="/login" element={<Login />} />

          {/* Error route (matches any path not matched by the above routes) */}
          <Route component={NotFound} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
