import React, { useState } from "react";
import { DataGrid } from "@mui/x-data-grid";

const columns = [
  { field: "clinic", headerName: "Clinic", width: 150 },
  { field: "clinicdescription", headerName: "Description", width: 300 },
  {
    field: "slotdate",
    type: "date",
    headerName: "Date",
    flex: 1,
    valueFormatter: (params) => {
      return params.value.toLocaleDateString("en-GB");
    },
    valueGetter: (params) => {
      if (!params || !params.value) return;
      const p = params.value.split("/");
      const d = { day: 0, month: 1, year: 2 };
      const date = new Date(p[d.year], p[d.month] - 1, p[d.day]);
      return date;
    },
  },
  { field: "slottime", headerName: "Time", flex: 1 },
  { field: "slottypedescription", headerName: "Slot Type", flex: 1 },
  {
    field: "availableindicatordescription",
    headerName: "Slot Status",
    flex: 1,
  },
  { field: "slotprioritydescription", headerName: "Priority", flex: 1 },
  {
    field: "bookedtypeindicatordescription",
    headerName: "Booked Indicator",
    flex: 1,
  },
  {
    field: "appointmentslottypedescription",
    headerName: "Appointment Type",
    flex: 1,
  },
];

const ClinicSlotGrid = ({ clinicSlots, onSelect }) => {
  const handleRowClick = (params) => {
    console.log("Selected Row ID:", params.sequence);
    console.log("Selected Row Data:", params);
    onSelect(params);
  };

  return (
    <DataGrid
      rows={clinicSlots}
      columns={columns}
      pageSize={10}
      rowsPerPageOptions={[10, 25, 50, 100]}
      pagination
      autoheight
      onRowClick={handleRowClick}
      getRowId={(row) =>
        `${row.clinic}_${row.elem_ref}_${row.sequence}_${row.slotdate}`
      }
      columnHeaderHeight={25}
    />
  );
};

export default ClinicSlotGrid;
