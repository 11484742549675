import React, { useState } from "react";
import { DataGrid } from "@mui/x-data-grid";

const columns = [
  { field: "pasid", headerName: "PAS ID", width: 100 },
  { field: "patientname", headerName: "Patient", width: 200 },
  { field: "referralnumber", headerName: "Referral Number", width: 150 },
  { field: "currentdateonlist",  headerName: "Date on List", width: 110, type:'date', valueGetter: (params) => {
        if(!params || !params.value) return;
        const p= params.value.split('/');
        const d={day:0,month:1,year:2}
        const date = new Date(p[d.year],p[d.month]-1,p[d.day]);
        return date;
      }},
  { field: "prioritytypedescription", headerName: "PriorityCode", width: 120 },
  { field: "mainprocedure", headerName: "Procedure", width: 100 },
  { field: "screeningrequired", headerName: "Screening", width: 100 },
  { field: "managementintentdescription", headerName: "Intention", width: 220 },
  { field: "admissioncategorydescription", headerName: "Admission", width: 120 },
  {
    field: "opappointmentnumber",
    headerName: "Appointment Booked",
    width: 200,
    valueGetter: (params) => {
      return params.row.opappointmentnumber ? "Yes" : "No";
    },
  },
];

const WaitlistGrid = ({ records, onSelect }) => {
  const handleRowClick = (params) => {
    console.log("Selected Row ID:", params.id);
    console.log("Selected Row Data:", params);
    onSelect(params.id, params.row);
  };

  return (
    <DataGrid
      rows={records}
      columns={columns}
      pageSize={10}
      rowsPerPageOptions={[10, 25, 50, 100]}
      pagination
      autoHeight
      onRowClick={handleRowClick}
      getRowId={(row) => `${row.episodenumber}_${row.internalpatientid}`}
      columnHeaderHeight={25}
    />
  );
};

export default WaitlistGrid;
