// pages/PatientList.js
import React, { useState, useEffect } from "react";
import axios from "axios";
import styled from "styled-components";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "dayjs/locale/en-gb";

import TheatreSearchForm from "./theatreSearchForm";
import TheatreSessionGrid from "./theatreSessionGrid";
import TheatreSlotForm from "./theatreSlotForm";
import TheatreNavBar from "./theatreNavBar";
import TheatreCalendar from "./theatreCalendar";
import "../../config";

const FormContainer = styled.div`
  background-color: #f4f4f4;
  padding: 20px;
`;

const TheatreSearchContainer = styled.div`
  height: 90vh;
  display: flex;
  flex-direction: column;
  flex: 1;

  .title-bar {
    background-color: #4a4c52;
    height: 40px;
  }
  .title {
    text-align: center;
    color: #ffffff;
  }
`;

export const TheatreListContainer = styled.div`
  .MuiDataGrid-columnHeaders {
    background-color: #ebf3fc;

    .MuiDataGrid-columnHeader {
      border-right: 1px solid #ededed;
    }
  }

  .MuiDataGrid-cell {
    border-right: 1px solid #ededed;
  }

  .MuiDataGrid-row {
    &:nth-child(even) {
      background-color: #fafafa;
    }
  }

  .title-bar {
    button {
      color: #ffffff !important;
      border-color: #ffffff !important;

      &:hover {
        color: #4a4c52 !important;
        background-color: #ffffff;
      }
    }
  }
`;

const TheatreSearch = ({ sessionObj, onSelect }) => {
  const [theatres, setTheatres] = useState();
  const [theatreSelected, setTheatreSelected] = useState();
  const [slots, setSlots] = useState();
  const [showCal, setShowCal] = useState(false);
  const cfg = global.config.restAPI;

  const sessionCode = sessionObj?.theatresessioncode;

  useEffect(() => {
    const query = {sessiondatesearchable: { $gt: { $date: new Date() } } };
    // Fetch referral detailData s based on the ID from the URL params
    if (sessionCode){
        query.theatresessioncode = { $eq: sessionCode};

      axios
        .get(
          `${cfg.url + cfg.endpoints.theatre.sessions}/?q=${JSON.stringify(query)}`,
        )
        .then((response) => {
          setTheatres(response.data.items);
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      axios
        .get(`${cfg.url + cfg.endpoints.theatre.sessions}`)
        .then((response) => {
          setTheatres(response.data.items);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [sessionCode, cfg]);

  const handleSlotSelect = (rowData) => {
    onSelect(rowData.row)
  };


  const handleFormSubmit = (formData) => {
    console.log("Form Data in Parent:", formData);

    // Filtering out empty values from formData
    const nonEmptyFormData = Object.fromEntries(
      Object.entries(formData).filter(([key, value]) => value !== ""),
    );

    const queryParams = new URLSearchParams(nonEmptyFormData).toString();

    // Fetch patients from the API
    axios
      .get(`${cfg.url + cfg.endpoints.theatreSessions}/?${queryParams}`)
      .then((response) => {
        setTheatres(response.data.items);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  if (!theatres && !slots) {
    return <p style={{ marginLeft: 24 }}>Loading...</p>;
  }

  return (
    <TheatreSearchContainer>
      {!showCal && (
        <FormContainer>
          <TheatreSearchForm onSubmit={handleFormSubmit} />
        </FormContainer>
      )}
      {(theatres && showCal === false ) &&(
        <TheatreListContainer style={{ flex: 1, overflow: "scroll" }}>
          <TheatreNavBar
            title={`${sessionCode ? sessionCode : ""} - Calendar`}
          >
            <ButtonGroup variant="outlined" size="small">
              <Button
                variant={!showCal ? "outlined" : "primary"}
                onClick={() => setShowCal(false)}
              >
                List
              </Button>
              <Button
                variant={showCal ? "outlined" : "primary"}
                onClick={() => setShowCal(true)}
              >
                Calendar
              </Button>
            </ButtonGroup>
          </TheatreNavBar>

          <TheatreSessionGrid
            theatreSessions={theatres}
            onSelect={handleSlotSelect}
          />
        </TheatreListContainer>
      )}

      {theatres && showCal && (
        <TheatreListContainer style={{ flex: 1, overflow: "scroll" }}>
          <TheatreNavBar
            title={`${theatreSelected ? theatreSelected.descript : ""} - Calendar`}
          >
            <ButtonGroup variant="outlined" size="small">
              <Button
                variant={!showCal ? "outlined" : "primary"}
                onClick={() => setShowCal(false)}
              >
                List
              </Button>
              <Button
                variant={showCal ? "outlined" : "primary"}
                onClick={() => setShowCal(true)}
              >
                Calendar
              </Button>
            </ButtonGroup>
          </TheatreNavBar>
          <TheatreCalendar
            theatreSessions={theatres}
            theatreSlots={slots}
            onSelect={onSelect}
          />
        </TheatreListContainer>
      )}
    </TheatreSearchContainer>
  );
};

export default TheatreSearch;
