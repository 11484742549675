import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import NavAppBar from "../components/navAppBar";
import { Box, Menu, MenuItem } from "@mui/material";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import "../config";
import styled from "styled-components";
import DisplaySection from "../components/displaySection";
import AddIcon from "@mui/icons-material/Add";
import BannerPatient from "../components/bannerPatient";
import PrintDialog from "../components/waitlist/waitlistPrint";

const BannerBox = styled.div`
  background: rgb(234, 235, 242);
`;

export const WaitListDetailsContainer = styled.div`
  .title-bar {
    .back-btn {
      margin-right: 150px;
    }
    .title {
      margin-right: 0;
    }
  }
`;

const labelMapping = {
  patient_information: {
    internalpatientid: "Internal Patient ID",
    episodenumber: "Episode Number",
    pasid: "PAS ID",
    nhs_id: "NHS ID",
    patientsurname: "Patient Surname",
    patientfirstname: "Patient First Name",
    patientmiddlename: "Patient Middle Name",
    title: "Title",
    patientname: "Patient Name",
    patientnameupper: "Patient Name (Upper Case)",
    originaldateonlist: "Original Date on List",
    currentdateonlist: "Current Date on List",
    entrystatuscode: "Entry Status Code",
    entrystatusdescription: "Entry Status Description",
    entrystatuslongdescription: "Entry Status Long Description",
  },
  appointment_information: {
    appointmentprioritycode: "Appointment Priority Code",
    appointmentprioritydescription: "Appointment Priority Description",
    appointmentprioritylongdescription: "Appointment Priority Long Description",
    consultant: "Consultant",
    specialty: "Specialty",
    clinic: "Clinic",
    clinicgroup: "Clinic Group",
    locationtype: "Location Type",
    location: "Location",
    owninghospital: "Owning Hospital",
    expectedbookingsystemcode: "Expected Booking System Code",
    ebsdescription: "EBS Description",
    ebslongdescription: "EBS Long Description",
    estimatedcontactdate: "Estimated Contact Date",
    appointmentprocedure: "Appointment Procedure",
  },
  referral_information: {
    referralnumber: "Referral Number",
    opepisodenumber: "OP Episode Number",
    opappointmentnumber: "OP Appointment Number",
    completeddate: "Completed Date",
    removaldate: "Removal Date",
    removalreasoncode: "Removal Reason Code",
    removalreasondescription: "Removal Reason Description",
    removalreasonlongdescription: "Removal Reason Long Description",
    removaltextcomment: "Removal Text Comment",
  },
  provider_and_commissioner: {
    providercode: "Provider Code",
    commissionercode: "Commissioner Code",
    slacodeid: "SLA Code ID",
    contractreference: "Contract Reference",
  },
  review_and_status: {
    reviewindicator: "Review Indicator",
    lastreviewsequence: "Last Review Sequence",
    reviewdate: "Review Date",
    shortcallavailabilityindicator: "Short Call Availability Indicator",
    noticerequired: "Notice Required",
    transportrequired: "Transport Required",
    trasnferfromclinic: "Transfer From Clinic",
    transferfromconsultant: "Transfer From Consultant",
    transferfromspecialty: "Transfer From Specialty",
    transferfromdate: "Transfer From Date",
    pendingstatus: "Pending Status",
    currentsequence: "Current Sequence",
  },
  hddr_and_comments: {
    hddr_group: "HDDR Group",
    freetextcomments: "Free Text Comments",
  },
  patient_category_and_appointment_flags: {
    patientcategorycode: "Patient Category Code",
    patientcategorydescription: "Patient Category Description",
    patientcategorylongdescription: "Patient Category Long Description",
    appointmentslottypecode: "Appointment Slot Type Code",
    appointmentslottypedescription: "Appointment Slot Type Description",
    appointmentslottypelongdescription:
      "Appointment Slot Type Long Description",
    coronavirusprioritycode: "Coronavirus Priority Code",
    coronaviruscategorycode: "Coronavirus Category Code",
    coronavirussubcategorycode: "Coronavirus Subcategory Code",
    cornonavirusdate: "Coronavirus Date",
    plannedappointmentflagcode: "Planned Appointment Flag Code",
    plannedappointmentflagdescription: "Planned Appointment Flag Description",
    plannedappointmentflaglongdescription:
      "Planned Appointment Flag Long Description",
    plannedappointmentdate: "Planned Appointment Date",
    plannedappointmentnotes: "Planned Appointment Notes",
  },
  ubrn: {
    ubrn: "UBRN",
  },
};

const WaitlistInpatientDetails = () => {
  const [waitlistItem, setWaitlistItem] = useState(null);
  const [menuAnchorEl, setMenuAnchorEl] = React.useState(null);
  const [openPrintDialog, setOpenPrintDialog] = React.useState(false);
  const navigate = useNavigate();
  const { patientId, waitlistId } = useParams();
  const cfg = global.config.restAPI; //"http://localhost:3000/";

  useEffect(() => {
    // Fetch referral details based on the ID from the URL params
    const query = {
      internalpatientid: { $eq: patientId },
      waitinglistepisodenumber: { $eq: waitlistId }
    };
    axios
      .get(
        `${cfg.url + cfg.endpoints.theatre.waitlistentry}/?q=${JSON.stringify(query)}`,
      )
      .then((response) => {
        const firstItem = response.data.items[0];
        setWaitlistItem(firstItem);
      });
  }, [patientId, waitlistId, cfg]);

  if (!waitlistItem) {
    return <p style={{ marginLeft: 24 }}>Loading...</p>;
  }

  const menuOpen = Boolean(menuAnchorEl);

  const handleMenuClick = (event) => {
    setMenuAnchorEl(event.currentTarget);
  };
  const handleMenuClose = (e) => {
    setMenuAnchorEl(null);
    switch (e.currentTarget.innerText) {
      case "Print Waitlist Letters":
        setOpenPrintDialog(true);
        break;
      default:
        return;
    }
  };

  return (
    <WaitListDetailsContainer style={{ background: "#eaebf2", flex: 1 }}>
      <NavAppBar title="Waitlist Inpatient Item Details">
        <Button
          type="primary"
          variant="outlined"
          startIcon={<AddIcon />}
          onClick={() =>
            navigate(
              `/theatre/patient/${patientId}/booking/create`,
            )
          }
        >
          Booking
        </Button>
        <Button
          variant="outlined"
          style={{ marginLeft: 10 }}
          onClick={handleMenuClick}
        >
          More
        </Button>
        <Menu anchorEl={menuAnchorEl} open={menuOpen} onClose={handleMenuClose}>
          <MenuItem onClick={handleMenuClose}>Print Waitlist Letters</MenuItem>
          <MenuItem onClick={handleMenuClose}>Modify Waitlist</MenuItem>
          <MenuItem onClick={handleMenuClose}>Suspend Waitlist</MenuItem>
          <MenuItem onClick={handleMenuClose}>Manage Notes</MenuItem>
          <MenuItem onClick={handleMenuClose}>View Referral</MenuItem>
          <MenuItem onClick={handleMenuClose}>Schedule Appointment</MenuItem>
          <MenuItem onClick={handleMenuClose}>RTT Pathway</MenuItem>
          <MenuItem onClick={handleMenuClose}>Remove from Waitlist</MenuItem>
          <MenuItem onClick={handleMenuClose}>Patient Communications</MenuItem>
          <MenuItem onClick={handleMenuClose}>Appointment List</MenuItem>
          <MenuItem onClick={handleMenuClose}>Waitlist Management</MenuItem>
        </Menu>
      </NavAppBar>

      <BannerBox>
        <BannerPatient
          patientId={patientId}
          onSelect={() =>
            navigate(`/patient/${patientId}/`)
          }
        />
      </BannerBox>

      <DisplaySection
        title="Patient Information"
        labelMap={labelMapping.patient_information}
        data={waitlistItem}
      />
      <DisplaySection
        title="Appointment Details"
        labelMap={labelMapping.appointment_information}
        data={waitlistItem}
      />
      <DisplaySection
        title="Referral Details"
        labelMap={labelMapping.referral_information}
        data={waitlistItem}
      />
      <DisplaySection
        title="Provider and Commissioner Details"
        labelMap={labelMapping.provider_and_commissioner}
        data={waitlistItem}
      />
      <DisplaySection
        title="Review Details"
        labelMap={labelMapping.review_and_status}
        data={waitlistItem}
      />
      <DisplaySection
        title="Appointment Details"
        labelMap={labelMapping.hddr_and_comments}
        data={waitlistItem}
      />
      <DisplaySection
        title="Flags and Other Details"
        labelMap={labelMapping.patient_category_and_appointment_flags}
        data={waitlistItem}
      />
      <PrintDialog open={openPrintDialog} onClose={() => setOpenPrintDialog(false)} />
    </WaitListDetailsContainer>
  );
};

export default WaitlistInpatientDetails;
