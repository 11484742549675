import React from "react";
import { DataGrid } from "@mui/x-data-grid";
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';


const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === 'light' ? '#51C4D2' : '#308fe8',
  },
}));

const columns = [
  { field: "clinic", headerName: "Clinic", flex: 1 },
  { field: "descript", headerName: "Name", flex: 1 },
  { field: "spec", headerName: "Speciality", flex: 1 },
  { field: "instanceDate", headerName: "Date", flex: 1 },
{
    field: 'progress',
    headerName: 'Slots',
    width: 150,
    renderCell: (params) => {
      const avail = params.row.countAvailable;
      const total = params.row.countTotal;
      const progress = ((total-avail)/ total) * 100;

      return (
        <div style={{ width: '100%', color: 'blue.500' }}>
          <BorderLinearProgress variant="determinate" value={progress}  />
          <span>{`${avail} available`}</span>
        </div>
      );
    },
  },
  
  { field: "stime", headerName: "Start", flex: 1 },
  { field: "etime", headerName: "End", flex: 1 },
/*
  { field: "availableindicatordescription", headerName: "Available", flex: 1 },
  { field: "bookedtypeindicatordescription", headerName: "Booking Type", flex: 1 },
  { field: "slotdate", headerName: "Date", flex: 1 },
  { field: "slottime", headerName: "Time", flex: 1 },
  { field: "slottypecode", headerName: "Slot Type", flex: 1 },
  { field: "slotprioritycode", headerName: "Priority", flex: 1 },
*/
 ];

const AvailableSessionGrid = ({ sessionDates, onSelect }) => {
  const handleRowClick = (params) => {
    console.log("Selected Row ID:", params.sequence);
    console.log("Selected Row Data:", params);
    onSelect(params);
  };

  return (
    <DataGrid
      rows={sessionDates}
      columns={columns}
      pageSize={10}
      rowsPerPageOptions={[10, 25, 50, 100]}
      pagination
      autoheight
      onRowClick={handleRowClick}
      getRowId={(row) => row.clinic + row.instanceDate}
      columnHeaderHeight={25}
    />
  );
};

export default AvailableSessionGrid;
