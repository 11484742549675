import React, { useState, useEffect } from "react";
import {
  TextField,
  Divider,
  Button,
  Box,
  FormControlLabel,
  Checkbox,
  InputLabel,
} from "@mui/material";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "dayjs/locale/en-gb";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";

import NavAppBar from "../components/navAppBar";
import ComboLookupCode from "../components/comboLookup";
import ComboProfDets from "../components/comboProfDets";
import ComboSpecialty from "../components/comboSpecialty";
import ComboOrgDets from "../components/comboOrgDets";
import ComboICDCode from "../components/comboICDCode";
import styled from "styled-components";
import "../config";
import InfoSnackbar from "../components/infoSnackbar";
import DialogComplexQuestion from "../components/dialogComplexQuestion";
import XDatePicker from "../components/xDatePicker";
import XCheckbox from "../components/xCheckbox";
import PatientSelector from "../components/patientSelector";
import LinkReferralSelector from "../components/referralSelector";
import BannerPatient from "../components/bannerPatient";
import {
  Footer,
  Form,
  Row,
  Column,
  SectionTitle,
} from "../components/formLayout";

const BoxButtons = styled.div`
  display: flex;
  flex-direction: row-reverse;
  background-color: #4a4c52;
  & button {
    margin: 10px;
  }
`;

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  .input-label {
    font-size: 12px;
  }
`;

// Function to format date as "DD/MMM/YYYY"
const formatDate = (inputDate) => {
  const options = { day: "2-digit", month: "short", year: "numeric" };
  return new Date(inputDate).toLocaleDateString("en-GB", options);
};

const ReferralForm = ({ match }) => {
  const navigate = useNavigate();
  const { patientId } = useParams();
  const [formErrors, setFormErrors] = useState({});

  const [referralId, setReferralId] = useState();
  const [formData, setFormData] = useState({
    internalPatientId: patientId,
    patientObj: null,
    referralDate: null, // "11/JAN/2024"
    referralTime: null, // null
    referralMethodCode: null, // "E"
    dateRecorded: null, // "11/JAN/2024"
    receivingLocation: null, // "OPD"
    referringSourceCode: null, // "03"
    referringType: null, // "SURG"
    referralSource: null, // "A86018"
    referringPersonType: null, // "GMP"
    referringPerson: null, // "G9803099"
    referringDiscipline: null, // null
    referralReason: null, // "A"
    referralPriority: null, // "U"
    suspectedCancer: null, // "Y"
    suspectedCancerTypeCode: null, // "13"
    referralReference: null, // null
    referredToPerson: null, // "HAPA"
    referredToSpecialty: null, // "GS"
    referralText: null, // null
    currentLocation: null, // "CONS"
    diagnosticType: null, // null
    diagnosticCode: null, // null
    diagnosticText: null, // null
    closureDate: null, // null
    closureTime: null, // null
    personTypeClosing: null, // "NC"
    personClosing: null, // "RAND"
    closingReason: null, // "D"
    usedCount: null, // null
    userCodeType: null, // null
    userCode1: null, // null
    userCode2: null, // null
    userCode3: null, // null
    userCode4: null, // null
    userCode5: null, // null
    userCode6: null, // null
    userCode7: null, // null
    userCode8: null, // null
    userCode9: null, // null
    userCode10: null, // null
    refOrg: null, // null
    rtt: null, // null
    refCtxt: null, // null
  });
  const [showInfoSnack, setShowInfoSnack] = useState();
  const [openWaitlistQuestion, setOpenWaitlistQuestion] = useState(false);
  const resturl =
    global.config.restAPI.url + global.config.restAPI.endpoints.referrals + "/";

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setFormData({
      ...formData,
      [name]: checked,
    });
  };

  const handleComboChange = (domain, val) => {
    setFormData({ ...formData, [domain]: val });
  };

  const checkFormErrors = (postObj) => {
    const required = [
      "internalPatientId", // url parameter
      "dateReceived",
      "referralDate",
      /*"referralMethodCode",
      "referralPriority",
      "referralReason",
      "referralSource", */
    ];

    // Check to post obj has required fields containing data 
    const errors = required.filter((key) => (!postObj[key]));
    const newFormErrors = Object.fromEntries(errors.map(key => [key, "Required"]));
    console.log("Missing Fields: " + errors.toString())

    setFormErrors(newFormErrors);
    return errors.length;

  }

  const handleSave = () => {
    // Make a POST request to save or update the data
    if (checkFormErrors(formData) > 0) return;

    //translate for the API
    const data = {
      internalpatientid: formData.internalPatientId, // url parameter
      closingreason: null,
      closuredate: null,
      closuretime: null,
      currentlocation: null,
      daterecorded: formData.dateReceived.utc(), //.format("DD/MM/YYYY"),
      personclosing: null,
      persontypeclosing: null,
      receivinglocation: null,
      refctxt: null,
      reforg: null,
      referraldate: formData.referralDate.utc(), //format("DD/MM/YYYY"),
      referralmethodcode: formData.referralMethodCode,
      referralpriority: formData.referralPriority,
      referralreason: formData.referralReason,
      referralreference: null,
      referralsource: formData.referralSource,
      referraltext: formData.referralText,
      referraltime: null, //formData.referral'2024-02-03t15:00:00.000z',
      referredtoperson: formData.referredToPerson, //'udol',
      referredtospecialty: formData.referredToSpecialty, //''130b',
      referringdiscipline: formData.referringDiscipline, //'card',
      referringpersontype: null,
      referringsourcecode: formData.referringSourceCode, //''05',
      referringtype: null,
      rtt: formData.rtt ? "Y" : "N",
      suspectedcancer: formData.suspectedCancer ? "Y" : "N",
      suspectedcancertypecode: null,
    };

    axios
      .post(resturl, data)
      .then((response) => {
        setShowInfoSnack(
          `Referral Created (${JSON.stringify(response.data)}).`,
        );
        setReferralId(response.data.ReferralNumber);
        setOpenWaitlistQuestion(true); // ask to create referral
      })
      .catch((error) => {
        console.error("Error saving data:", error);
        setShowInfoSnack(`Sorry, there was a problem.`);
      });
  };

  return (
    <MainContainer>
      <NavAppBar title="Add Referral"></NavAppBar>
      {(patientId || formData.patientObj) && (
        <BannerPatient patientRec={formData.patientObj} patientId={patientId}
          onSelect={(id) => {
            navigate(`/patient/${id}/`)
          }}
        />
      )}
      <Form>
        <Box spacing={2} style={{ margin: 20 }}>
          {!patientId && (
            <div>
              <SectionTitle>Patient Selection </SectionTitle>
              <Row>
                <Column>
                  <PatientSelector
                    selectedObj={formData.patientObj}
                    onChange={(id, rowData) => {
                      setFormData({
                        ...formData,
                        patientObj: rowData,
                        internalPatientId: id,
                      });
                    }}
                    error={formErrors.internalPatientId}
                  />
                </Column>
                <Column></Column>
                <Divider />
              </Row>
            </div>
          )}
          <SectionTitle>Request Information</SectionTitle>
          <Row>
            <Column>
              <ComboLookupCode
                label="Source of Referral"
                name="referringSourceCode"
                value={formData.referringSourceCode}
                refDomain="referringSourceCode"
                onChange={(newData) => {
                  handleComboChange("referringSourceCode", newData);
                }}
              />
            </Column>
            <Column>
              <InputLabel className="input-label">
                Method of Referral
              </InputLabel>
              <ComboLookupCode
                name="referralMethodCode"
                value={formData.referralMethodCode}
                refDomain="referralMethodCode"
                onChange={(newData) => {
                  handleComboChange("referralMethodCode", newData);
                }}
              />
            </Column>
          </Row>
          <Row>
            <LocalizationProvider
              dateAdapter={AdapterDayjs}
              adapterLocale="en-gb"
            >
              <Column>
                <InputLabel className="input-label">Referral Date*</InputLabel>
                <XDatePicker
                  value={formData.referralDate}
                  onChange={(newValue) =>
                    setFormData({ ...formData, referralDate: newValue })
                  }
                  slotProps={{ textField: { size: "small" } }}
                  error={formErrors.referralDate}
                />
              </Column>
              <Column>
                <InputLabel className="input-label">Referral Time</InputLabel>
                <TimePicker
                  value={formData.referralTime}
                  onChange={(newValue) =>
                    setFormData({ ...formData, referralTime: newValue })
                  }
                  slotProps={{
                    textField: {
                      size: "small",
                      style: { width: 300, marginTop: 0 },
                    },
                  }}
                />
              </Column>
            </LocalizationProvider>
          </Row>
          {formData.referringSourceCode === "03" /*When source is GP */ && (
            <Row>
              <Column>
                <ComboProfDets label="GP" name="unknown" type="gp" />
              </Column>
              <Column></Column>
            </Row>
          )}
          {formData.referringSourceCode ===
            "05" /* When source is consultant*/ && (
              <Row>
                <Column>
                  <InputLabel className="input-label">
                    Referring Specialty
                  </InputLabel>
                  <ComboSpecialty
                    name="referringDiscipline"
                    value={formData.referringDiscipline}
                    onChange={(code) => {
                      setFormData({ ...formData, referringDiscipline: code });
                    }}
                    slotProps={{
                      textField: {
                        size: "small",
                        style: { width: 300, marginTop: 0 },
                      },
                    }}
                  />
                </Column>
                <Column>
                  <InputLabel className="input-label">
                    Referring Consultant
                  </InputLabel>
                  <ComboProfDets
                    name="referringPerson"
                    value={formData.referringPerson}
                    type="consultant"
                    specialty={formData.referringDiscipline}
                    onChange={(code) => {
                      setFormData({ ...formData, referringPerson: code });
                    }}
                    slotProps={{
                      textField: {
                        size: "small",
                        style: { width: 300, marginTop: 0 },
                      },
                    }}
                  />
                </Column>
              </Row>
            )}

          <Row>
            <Column>
              <InputLabel className="input-label">
                Referring Organisation
              </InputLabel>
              <ComboOrgDets
                name="referralSource"
                value={formData.referralSource}
                onChange={(code) => {
                  setFormData({ ...formData, referralSource: code });
                }}
              />
            </Column>
            {formData.patientObj && (
              <Column>
                <LinkReferralSelector
                  label="Linked Referral"
                  selectedObj={formData.linkedReferralObj}
                  patientId={formData.internalPatientId}
                  onChange={(id, rowData) => {
                    setFormData({
                      ...formData,
                      linkedReferralObj: rowData,
                    });
                  }}
                />
              </Column>
            )}
          </Row>
          <SectionTitle>Referral Details</SectionTitle>
          <Row>
            <Column>
              <InputLabel className="input-label">Referral Reason</InputLabel>
              <ComboLookupCode
                name="referralReason"
                value={formData.referralReason}
                refDomain="referralReason"
                onChange={(newData) => {
                  handleComboChange("referralReason", newData);
                }}
              />
            </Column>
            <Column>
              <InputLabel className="input-label">Referral Priority</InputLabel>
              <ComboLookupCode
                name="referralPriority"
                value={formData.referralPriority}
                refDomain="referralPriority"
                onChange={(newData) => {
                  handleComboChange("referralPriority", newData);
                }}
              />
            </Column>
          </Row>
          <Row>
            <Column>
              <InputLabel className="input-label">
                Refer To Specialty
              </InputLabel>
              <ComboSpecialty
                name="referredToSpecialty"
                value={formData.referredToSpecialty}
                onChange={(code) => {
                  setFormData({ ...formData, referredToSpecialty: code });
                }}
              />
            </Column>
            <Column>
              <InputLabel className="input-label">
                Referred To Consultant
              </InputLabel>
              <ComboProfDets
                name="referredToPerson"
                value={formData.referredToPerson}
                type="consultant"
                specialty={formData.referredToSpecialty}
                onChange={(code) => {
                  setFormData({ ...formData, referredToPerson: code });
                }}
              />
            </Column>
          </Row>
          <Row>
            <Column>
              <XCheckbox
                label="Cancer/Breast Symptoms"
                checked={formData.suspectedCancer}
                onChange={handleCheckboxChange}
                name="suspectedCancer"
              />
            </Column>
            <Column>
              <XCheckbox
                label="Part of RTT"
                checked={formData.rtt}
                onChange={handleCheckboxChange}
                name="rtt"
              />
            </Column>
          </Row>
          <Row>
            <Column>
              <InputLabel className="input-label">
                Referral Reference
              </InputLabel>
              <TextField
                name="referralReference"
                value={formData.referralReference}
                onChange={handleInputChange}
                size="small"
                style={{ width: 300, marginTop: 0 }}
              />
            </Column>
            <Column>
              <InputLabel className="input-label">Date Received*</InputLabel>
              <LocalizationProvider
                dateAdapter={AdapterDayjs}
                adapterLocale="en-gb"
              >
                <XDatePicker
                  value={formData.dateReceived}
                  name="dateReceived"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(newValue) =>
                    setFormData({ ...formData, dateReceived: newValue })
                  }
                  slotProps={{
                    textField: {
                      size: "small",
                      style: { width: 300, marginTop: 0 },
                    },
                  }}
                  error={formErrors.dateReceived}
                />
              </LocalizationProvider>
            </Column>
          </Row>
          <Row>
            <Column>
              <InputLabel className="input-label">Current Location</InputLabel>
              <TextField
                name="currentLocation"
                value={formData.currentLocation}
                onChange={handleInputChange}
                size="small"
                style={{ width: 300, marginTop: 0 }}
              />
            </Column>
            <Column>
              <InputLabel className="input-label">Addition Text</InputLabel>
              <TextField
                name="referralText"
                multiline
                rows={4}
                defaultValue=""
                value={formData.referralText}
                onChange={handleInputChange}
                size="small"
                style={{ width: 300, marginTop: 0 }}
              />
            </Column>
          </Row>
          <Row>
            <Column>
              <ComboICDCode
                label="Diagnosis Code"
                name="diagnosisCode"
                value={formData.diagnosisCode}
                onChange={(code) => {
                  setFormData({ ...formData, diagnosisCode: code });
                }}
              />
            </Column>
            <Column>
              <InputLabel className="input-label">Diagnosis Note</InputLabel>
              <TextField
                placeholder="note"
                multiline
                rows={4}
                defaultValue=""
                size="small"
                style={{ width: 300, marginTop: 0 }}
              />
            </Column>
          </Row>

          <InfoSnackbar
            showMessage={showInfoSnack}
            onClose={() => setShowInfoSnack(false)}
          />
          <DialogComplexQuestion
            title="Followup Actions"
            desc="Would you like to add one of the following options? "
            open={openWaitlistQuestion}
            onClose={(questionResponse) => {
              setOpenWaitlistQuestion(false);
              console.log("question response:" + questionResponse);
              const patId = patientId || formData.internalPatientId;

              switch (questionResponse) {
                case "waitlist":
                  navigate(
                    `/waitlist/patient/${patId}/referral/${referralId}/create`,
                  );
                  break;
                case "appointment":
                  navigate(
                    `/clinic/patient/${patId}/referral/${referralId}/appointment/create`,
                  );
                  break;
                default: // create waitlist
                  navigate(`../patient/${patId}/referral/${referralId}`);
              }
            }}
          />
        </Box>
      </Form>
      <Footer>
        <div>
          <BoxButtons>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSave}
              className="btn-primary"
            >
              Save
            </Button>
            <Button
              variant="outlined"
              onClick={() => {
                navigate(-1);
              }}
              className="btn-cancel"
            >
              Cancel
            </Button>
          </BoxButtons>
        </div>
      </Footer>
    </MainContainer>
  );
};

export default ReferralForm;
