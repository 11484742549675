
import React, { useState, useEffect } from "react";
import axios from "axios";
import styled from "styled-components";
import { useParams, useNavigate } from "react-router-dom";
import { Button, FormControl, MenuItem, Select } from "@mui/material";
import XButtonGroup from "../../components/xButtonGroup";
import AddIcon from "@mui/icons-material/Add";
import WaitlistGridTheatre from "./waitlistGridTheatre";
import ComboWaitlistRef from "../../components/comboWaitlistRef";
import SearchByTypesForm from "../../components/searchByTypesForm";
import PlaylistAddCheckIcon from "@mui/icons-material/PlaylistAddCheck";
import "../../config";
import {
  FormContainer,
  MainContainer,
  GridContainer,
} from "../../components/containers";

const ListSelectPanel = styled.div`
  margin: 0px -10px -8px 25px;
  & > div {
    background-color: white;
  }
`;

const WaitlistTabTheatres = () => {
  const [waitlist, setWaitlist] = useState();
  const [listRef, setListRef] = useState("ALL");
  const cfg = global.config.restAPI;
  const navigate = useNavigate();
  //const [viewMode, setViewMode] = useState("Bookings");

  const query = { upcoming: "true" };

  const getSlotQuery = (clinicCode, startDate) => {
    const query = {
      clinic: { $eq: clinicCode },
      slotdatesearchable: { $gt: { $date: startDate } },
      $orderby: { slotdatesearchable: "ASC" },
      //slotdatesearchable:{$lt: endDate}
    };

    return JSON.stringify(query);
  };

  useEffect(() => {
    // Fetch referral detailData s based on the ID from the URL params
    if (listRef === "ALL") {
      const query = {
        slotdatesearchable: { $gt: { $date: new Date() } },
        $orderby: { slotdatesearchable: "ASC" },
      };
      axios
        .get(`${cfg.url + cfg.endpoints.theatre.waitlistentry}/`)
        .then((response) => {
          setWaitlist(response.data.items);
        });
    } else {
      axios
        .get(
          `${cfg.url + cfg.endpoints.theatre.waitlistentry}/?q={"list_id":{"$eq":"${listRef}"} }`,
        )
        .then((response) => {
          const items = response.data.items;
          setWaitlist(items);
        });
    }
  }, [listRef, cfg]);

  const handleSelect = (id, rowData) => {
    navigate(
      `/waitlist/inpatient/patient/${rowData.internalpatientid}/${rowData.waitinglistepisodenumber}`,
    );
  };

  const handleFormSubmit = (formData) => {
    // Filtering out empty values from formData
    const nonEmptyFormData = Object.fromEntries(
      Object.entries(formData).filter(([key, value]) => {
        return value && value !== "";
      }),
    );

    //Start the query
    const queryClinics = {}; // firstspecialty: { $eq: tab } }; //, appointmentdate: { $gt: "01/01/2001" } };
    const querySlots = {
      slotdatesearchable: { $gt: { $date: new Date() } },
      $orderby: { slotdatesearchable: "ASC" },
    };

    for (const [key, value] of Object.entries(nonEmptyFormData)) {
      if (key.includes("date"))
        querySlots[key] = { $gt: { $date: value.utc() } };
      else if (key.includes("clinicSearch"))
        querySlots.clinicdescription = { $instr: value }; //.toUpperCase() };
      else if (key.includes("firstclinic"))
        queryClinics.clinic = { $eq: value };
      else if (key.includes("specialty")) queryClinics.spec = { $eq: value };
      else if (key.includes("upcoming"))
        querySlots.slotdatesearchable = { $gt: { $date: new Date() } }; //spec = { $eq: value };
      else querySlots[key] = { $eq: value };
    }

    axios
      .get(
        //`${cfg.url + cfg.endpoints.clinicSessions}/?q={"spec":{"$eq":"${specialtyCode}"} }`,
        `${cfg.url + cfg.endpoints.clinicSessions}/?q=${JSON.stringify(queryClinics)}`,
      )
      .then((response) => {
        const items = response.data.items;
        setWaitlist(items);
      });
  };

  return (
    <MainContainer>
      <FormContainer>
        <ListSelectPanel>
          <ComboWaitlistRef
            label=""
            value={listRef}
            onChange={(id) => setListRef(id)}
          />
        </ListSelectPanel>
        <div className={["flex1"]}>
          <SearchByTypesForm
            title=""
            onSubmit={handleFormSubmit}
            defaultQuery={query}
            types={[
              "firstclinic",
              "firstspecialty",
              "slotdatesearchable",
              "slottypecode",
              "slotprioritycode",
              "availableindicator",
              "appointmentslottypecode",
              "searchPatient",
            ]}
          >
            <Button
              className="search-btn"
              type="primary"
              variant="outlined"
              startIcon={<AddIcon />}
              onClick={() => navigate(`/waitlist/theatre/${listRef}/create`)}
            >
              Waitlist
            </Button>
          </SearchByTypesForm>
        </div>
      </FormContainer>
      {waitlist && (
        <GridContainer style={{}}>
          <WaitlistGridTheatre
            records={waitlist}
            onSelect={handleSelect}
          />
        </GridContainer>
      )}

      {!waitlist && <p style={{ marginLeft: 24 }}>Loading...</p>}
    </MainContainer>
  );
};

export default WaitlistTabTheatres;
