import React, { useState } from "react";
import { DataGrid } from "@mui/x-data-grid";

const columns = [
  { field: "referralnumber", headerName: "Referral Number", width: 150 },
  { field: "pasid", headerName: "PAS ID", width: 100 },
  { field: "patientname", headerName: "Patient", width: 230 },
  { field: "firstclinic", headerName: "Clinic", width: 120 },
  { field: "firstspecialty", headerName: "Specialty", width: 150 },
  { field: "appointmentdate", headerName: "Date", width: 100, type:'date', valueGetter: (params) => {
        if(!params || !params.value) return;
        const p= params.value.split('/');
        const d={day:0,month:1,year:2}
        const date = new Date(p[d.year],p[d.month]-1,p[d.day]);
        return date;
      }},
  { field: "appointmenttime", headerName: "Time", width: 80 },
  { field: "appointmentstatusdescription", headerName: "Status", width: 150 },
  { field: "appointmentprioritycode", headerName: "PriorityCode", width: 120 },
  { field: "attendancetypedescription", headerName: "Type", width: 120 },
  { field: "appointmentduration", headerName: "Duration", width: 100 },
  { field: "bookingsystem", headerName: "Booking System", width: 120 },
];

const AppointmentGrid = ({ records, onSelect }) => {
  const handleRowClick = (params) => {
    console.log("Selected Row ID:", params.id);
    console.log("Selected Row Data:", params);
    onSelect(params.id, params.row);
  };

  return (
    <DataGrid
      rows={records}
      columns={columns}
      pageSize={10}
      rowsPerPageOptions={[10, 25, 50, 100]}
      pagination
      autoHeight
      onRowClick={handleRowClick}
      getRowId={(row) => `${row.uniqueappointmentid}`}
      columnHeaderHeight={25}
    />
  );
};

export default AppointmentGrid;
