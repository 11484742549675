// pages/ConfigTheatreMgr.js
import React, { useState, useEffect } from "react";
import axios from "axios";
import styled from "styled-components";
import { useParams, useNavigate } from "react-router-dom";
import { Button } from "@mui/material";

import AddIcon from "@mui/icons-material/Add";
import SearchByTypesForm from "../components/searchByTypesForm";
import ClinicSessionGrid from "../components/clinic/clinicSessionGrid";
import "../config";

const FormContainer = styled.div`
  background-color: #f4f4f4;
`;

const PageContainer = styled.div`
  height: 90vh;
  width: 100vw;

  display: flex;
  flex-direction: column;
  flex: 1;

  .title {
    background-color: #4a4c52;
    height: 40px;
    text-align: center;

    h2 {
      margin: 8px;
      color: #ffffff;
      font-size: 18px;
    }
  }

  .search-btn {
    margin-top: 4px;
  }
`;

export const BodyContainer = styled.div`
  .MuiDataGrid-columnHeaders {
    background-color: #ebf3fc;

    .MuiDataGrid-columnHeader {
      border-right: 1px solid #ededed;
    }
  }

  .MuiDataGrid-cell {
    border-right: 1px solid #ededed;
  }

  .MuiDataGrid-row {
    &:nth-child(even) {
      background-color: #fafafa;
    }
  }
  flex: 1;
  overflow: scroll;
`;

const ConfigTheatreMgr = () => {
  const [clinics, setClinics] = useState();
  const cfg = global.config.restAPI;
  const navigate = useNavigate();
  const { types } = useParams();

  useEffect(() => {
    // Fetch everything to start with
    const query = { }; // Filter out closed and removed
    axios
      .get(
        `${cfg.url + cfg.endpoints.clinicSessions}/?q=${JSON.stringify(query)}`,
      )
      .then((response) => {
        setClinics(response.data.items);
      });
  }, [cfg]);

  const handleSelect = (rowData) => {
    navigate(
      `../config/clinic/${rowData.id}/slots/`,
    );
  };

  const handleFormSubmit = (formData) => {
    // Filtering out empty values from formData
    const nonEmptyFormData = Object.fromEntries(
      Object.entries(formData).filter(([key, value]) => {
        return value && value !== "";
      }),
    );

    const query = {}; // entrystatuscode: { $eq: "A" } }; //new URLSearchParams(nonEmptyFormData).toString();
    for (const [key, value] of Object.entries(nonEmptyFormData)) {
      if (key.includes("date")) query[key] = { $gt: value };
      else if (key.includes("clinicSearch"))
        query['descript'] = { $instr: value.toUpperCase() };
      else query[key] = { $eq: value };
    }

    // Fetch from the API
    axios
      .get(
        `${cfg.url + cfg.endpoints.clinicSessions}/?q=${JSON.stringify(query)}`,
      )
      .then((response) => {
        setClinics(response.data.items);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <PageContainer>
      <FormContainer>
        <SearchByTypesForm
          title="Theatre Configuration"
          onSubmit={handleFormSubmit}
          types={[
            "theatre",
            "specialty",
            "currentdateonlist",
            "searchClinic"
          ]}
        >
          <Button
            className="search-btn"
            type="primary"
            variant="outlined"
            startIcon={<AddIcon />}
            onClick={() => navigate(`../config/create`)}
          >
            Theatre Session
          </Button>
        </SearchByTypesForm>
      </FormContainer>
      {clinics && (
        <BodyContainer style={{}}>
          <ClinicSessionGrid
            clinicSessions={clinics}
            onSelect={handleSelect}
          />
        </BodyContainer>
      )}
      {!clinics && <p style={{ marginLeft: 24 }}>Loading...</p>}
    </PageContainer>
  );
};

export default ConfigTheatreMgr;
