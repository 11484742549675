import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import NavAppBar from "../components/navAppBar";
import { Box, Menu, MenuItem } from "@mui/material";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import "../config";
import styled from "styled-components";
import DisplaySection from "../components/displaySection";
import AddIcon from "@mui/icons-material/Add";
import BannerReferral from "../components/bannerReferral";
import DialogQuestion from "../components/dialogQuestion";
import DialogReasonQuestion from "../components/dialogReasonQuestion";
import InfoSnackbar from "../components/infoSnackbar";
import PrintDialog from "../components/appointmentPrint";
import AppointmentConfirm from "../components/appointmentConfirm";

const BannerBox = styled.div`
  background: rgb(234, 235, 242);
`

export const AppDetailsContainer = styled.div`
  .title-bar {
    .back-btn {
      margin-right: 250px;
    }
    .title {
      margin-right: 0;
    }
  }
`;

const labelMapping = {
  "patient_information": {
    "internalpatientid": "Internal Patient ID",
    "pasid": "Patient Administration System ID",
    "nhs_id": "NHS ID",
    "patientsurname": "Patient Surname",
    "patientfirstname": "Patient First Name",
    "patientmiddlename": "Patient Middle Name",
    "title": "Patient Title",
    "patientname": "Patient Full Name",
    "patientnameupper": "Patient Full Name (Upper Case)",
    "patientcategory": "Patient Category",
    "uniqueappointmentid": "Unique Appointment ID",
    "episodedischargedate": "Episode Discharge Date",
    "episodedischargetime": "Episode Discharge Time",
    "episodeoutcome": "Episode Outcome",
    "recalldate": "Recall Date",
    "recallreasoncode": "Recall Reason Code",
    "recallreasondescription": "Recall Reason Description",
    "firstclinic": "First Clinic",
    "firstspecialty": "First Specialty",
    "episodefirstappointmentidentifer": "Episode First Appointment Identifier",
    "waitstartdate": "Wait Start Date",
    "attendedflag": "Attended Flag",
    "episodenotes": "Episode Notes",
    "referralnumber": "Referral Number",
    "codingflagcode": "Coding Flag Code",
    "codingflagdescription": "Coding Flag Description",
    "hrgcode": "HRG Code",
    "hrgversion": "HRG Version",
    "contractstatuscode": "Contract Status Code",
    "contractstatusdescription": "Contract Status Description",
    "provider": "Provider",
    "purchaser": "Purchaser",
    "contractid": "Contract ID"
  },
  "clinic_details": {
    "cliniccode": "Clinic Code",
    "clinicmanagerprofessiontype": "Clinic Manager Profession Type",
    "clinicmanager": "Clinic Manager",
    "clinicspecialty": "Clinic Specialty",
    "cliniclocation": "Clinic Location",
    "clinicappointmentsystem": "Clinic Appointment System",
    "clinictypecode": "Clinic Type Code",
    "clinictypedescription": "Clinic Type Description",
    "includeinstats": "Include in Stats"
  },
  "Appointment Details": {
    "appointmentidentifier": "Appointment Identifier",
    "attendancetypecode": "Attendance Type Code",
    "appointmentstatuscode": "Appointment Status Code",
    "appointmentstatusdescription": "Appointment Status Description",
    "attendancetypedescription": "Attendance Type Description",
    "attendancetypelongdescription": "Attendance Type Long Description",
    "appointmentprioritycode": "Appointment Priority Code",
    "appointmentprioritydescription": "Appointment Priority Description",
    "appointmentduration": "Appointment Duration",
    "bookingsystem": "Booking System",
    "transportrequired": "Transport Required",
    "xrayrequired": "X-ray Required",
    "pathologyrequired": "Pathology Required",
    "numberofchangebypatient": "Number of Changes by Patient",
    "numberofchangesbylcinic": "Number of Changes by Clinic",
    "numberofreschedules": "Number of Reschedules",
    "numberofcancelbypatient": "Number of Cancel by Patient",
    "numberofcancelbyclinic": "Number of Cancel by Clinic",
    "numberofdnatotal": "Number of DNA Total",
    "numberofdnaconsecutive": "Number of DNA Consecutive",
    "nextappointmentidentifier": "Next Appointment Identifier",
    "previousappointmentidentifier": "Previous Appointment Identifier",
    "linkednextappointmentidentifer": "Linked Next Appointment Identifier",
    "linkedpreviousappointmentidentifier": "Linked Previous Appointment Identifier",
    "appointmentcreateddate": "Appointment Created Date",
    "searchableappointmencreatedtdate": "Searchable Appointment Created Date",
    "appointmenttime": "Appointment Time",
    "searchdateflag": "Search Date Flag",
    "searchdate": "Search Date",
    "searchtimeflag": "Search Time Flag",
    "searchtime": "Search Time",
    "dnaflag": "DNA Flag",
    "attendeddate": "Attended Date",
    "attendedtime": "Attended Time",
    "appointmentslotused": "Appointment Slot Used",
    "personseenprofessiontypecode": "Person Seen Profession Type Code",
    "personseenprofessiontypedescription": "Person Seen Profession Type Description",
    "personseen": "Person Seen",
    "timearrived": "Time Arrived",
    "timeseen": "Time Seen",
    "timedepart": "Time Depart",
    "cancelledbycode": "Cancelled By Code",
    "cancelledbydescription": "Cancelled By Description",
    "cancelledate": "Cancel Date",
    "cancelledreasoncode": "Cancelled Reason Code",
    "cancelledreasondescription": "Cancelled Reason Description",
    "suspendeddate": "Suspended Date",
    "suspendedreasoncode": "Suspended Reason Code",
    "suspendedreasondescription": "Suspended Reason Description",
    "reasonforbreachcode": "Reason for Breach Code",
    "reasonforbreachdescription": "Reason for Breach Description",
    "reasonforappointmentspec": "Reason for Appointment Specialty"
  },
  "general_information": {
    "uniqueepisodeappointmentnumber": "Unique Episode Appointment Number",
    "lastchangedate": "Last Change Date",
    "lastchangereason": "Last Change Reason",
    "lastchangereasondescription": "Last Change Reason Description",
    "lastchangereasonlongdescription": "Last Change Reason Long Description",
    "ebsdescription": "EBS Description",
    "ebslongdescription": "EBS Long Description",
    "elementreference": "Element Reference",
    "sessiontime": "Session Time",
    "sequencenumber": "Sequence Number",
    "hddrgroup": "HDDR Group",
    "waitexc": "Wait Exception",
    "linkedtosysdomicil": "Linked to Sys Domicil",
    "slstatuscode": "SL Status Code",
    "slstatusdescription": "SL Status Description",
    "copstatus": "COP Status",
    "specialflag": "Special Flag",
    "overbookedflag": "Overbooked Flag",
    "reasonforslotoverbookedcode": "Reason for Slot Overbooked Code",
    "reasonforslotoverbookeddescript": "Reason for Slot Overbooked Description",
    "interpreterrequired": "Interpreter Required",
    "earliestreasonableofferdate": "Earliest Reasonable Offer Date",
    "linkedappointmentsystem": "Linked Appointment System",
    "linkedtablenumber": "Linked Table Number",
    "linkedkeyalpha": "Linked Key Alpha",
    "linkedkeynumeric1": "Linked Key Numeric 1",
    "linkedkeynumeric2": "Linked Key Numeric 2",
    "linkedkeynumeric3": "Linked Key Numeric 3",
    "cdssubmissionstatuscode": "CDSS Submission Status Code",
    "cdssubmissionnumber": "CDSS Submission Number"
  }
};

const AppointmentDetails = () => {
  const [appointmentItem, setAppointmentItem] = useState(null);
  const [menuAnchorEl, setMenuAnchorEl] = React.useState(null);
  const [openPrintDialog, setOpenPrintDialog] = React.useState(false);
  const navigate = useNavigate();
  const { patientId, referralId, appointmentId } = useParams();
  const cfg = global.config.restAPI; //"http://localhost:3000/";
  const [openAppointmentQuestion, setOpenAppointmentQuestion] = useState(false);
  const [openDNAQuestion, setOpenDNAQuestion] = useState(false);
  const [openConfirmDialogue, setOpenConfirmDialogue] = useState(false);

  useEffect(() => {
    // Fetch referral details based on the ID from the URL params
    const query = {
      // weird server error  internalpatientid: { $eq: patientId },
      referralnumber: { $eq: referralId },
      uniqueepisodeappointmentnumber: { $eq: appointmentId },
    };
    axios
      .get(
        `${cfg.url + cfg.endpoints.appointments}/?q=${JSON.stringify(query)}`,
      )
      .then((response) => {
        const allItems = response.data.items;
        setAppointmentItem(allItems[0]);
      });
  }, [patientId, referralId, appointmentId, cfg]);
  if (!appointmentItem) {
    return <p style={{ marginLeft: 24 }}>Loading...</p>;
  }

  const menuOpen = Boolean(menuAnchorEl);

  const handleMenuClick = (event) => {
    setMenuAnchorEl(event.currentTarget);
  };
  const handleMenuClose = (e) => {
    setMenuAnchorEl(null);
    switch (e.currentTarget.innerText) {
      case "Appointment List":
        navigate(`/clinic/patient/${patientId}/referral/${referralId}/appointment/`)
        break;
      case "Waitlist Management":
        navigate(`/waitlist/patient/${patientId}/referral/${referralId}/`)
        break;
      case "RTT pathway":
        navigate(`/waitlist/patient/${patientId}/referral/${referralId}/rtt/`)
        break;
      case "Print Appointment Letters":
        setOpenPrintDialog(true);
        break;
      default:
        return
    }
  };

  return (
    <AppDetailsContainer style={{ background: "#eaebf2", flex: 1 }}>
      <NavAppBar title="Appointment Item Details">
        <Button
          variant="outlined"
          onClick={() =>
            navigate(
              `../patient/${patientId}/referral/${referralId}/appointment/create`,
            )
          }
        >
          Reschedule
        </Button>
        <Button
          variant="outlined"
          onClick={() => {
            setOpenAppointmentQuestion(true);
          }}
          style={{ marginLeft: 10 }}
        >
          Cancel
        </Button>
        <Button
          variant="outlined"
          onClick={() => {
            setOpenDNAQuestion(true);
          }}
          style={{ marginLeft: 10 }}
        >
          DNA
        </Button>
        <Button variant="outlined" style={{ marginLeft: 10 }}
          onClick={handleMenuClick}
        >
          More
        </Button>
        <Menu anchorEl={menuAnchorEl} open={menuOpen} onClose={handleMenuClose}>
          <MenuItem onClick={handleMenuClose}>Print Appointment Letters</MenuItem>
          <MenuItem onClick={handleMenuClose}>Modify Appointment</MenuItem>
          <MenuItem onClick={handleMenuClose}>Manage notes</MenuItem>
          <MenuItem onClick={handleMenuClose}>View referral</MenuItem>
          <MenuItem onClick={handleMenuClose}>RTT pathway</MenuItem>
          <MenuItem onClick={handleMenuClose}>Remove from waitlist</MenuItem>
          <MenuItem onClick={handleMenuClose}>Patient communications</MenuItem>
          <MenuItem onClick={handleMenuClose}>Waitlist Management</MenuItem>
        </Menu>
      </NavAppBar>

      <BannerBox>
        <BannerReferral referralId={referralId} patientId={patientId} onSelect={() => navigate(`/referral/patient/${patientId}/referral/${referralId}`)} />
      </BannerBox>

      <DisplaySection
        title="Patient Information"
        labelMap={labelMapping.patient_information}
        data={appointmentItem}
      />
      <DisplaySection
        title="Clinic Details"
        labelMap={labelMapping.clinic_details}
        data={appointmentItem}
      />
      <DisplaySection
        title="General Information"
        labelMap={labelMapping.general_information}
        data={appointmentItem}
      />
      <DialogReasonQuestion
        title="Cancel Appointment?"
        desc="Confirm the cancellation reason for this outpatient clinic appointment"
        open={openAppointmentQuestion}
        reasons={["Cancelled for Clinical Reasons", "Cancelled for Non-clinical Reasons"]}
        onClose={(isOK) => {
          setOpenAppointmentQuestion(false);
          console.log("question response:" + (isOK ? "OK" : "Cancel"));
          if (isOK) {
            setOpenConfirmDialogue(true);
          } else {
            navigate(`../patient/${patientId}/referral/${referralId}/appointment/${appointmentId}`);
          }
        }}
      />
      <DialogReasonQuestion
        title="Missed Appointment?"
        desc="Confirm the DNA reason for this outpatient clinic appointment"
        open={openDNAQuestion}
        reasons={["Did not attend - no advance warning given", "Arrived late and could not be seen"]}
        onClose={(isOK) => {
          setOpenDNAQuestion(false);
          console.log("question response:" + (isOK ? "OK" : "Cancel"));
          if (isOK) {
            setOpenConfirmDialogue(true);
          } else {
            navigate(`../patient/${patientId}/referral/${referralId}/appointment/${appointmentId}`);
          }
        }}
      />
      <DialogQuestion
        title="Followup Appointment?"
        desc="Do you want to book a new appointment?"
        open={openConfirmDialogue}
        onClose={(isOK) => {
          setOpenConfirmDialogue(false);
          console.log("question response:" + (isOK ? "OK" : "Cancel"));
          if (isOK) {
            navigate(`../patient/${patientId}/referral/${referralId}/appointment/create`);
          } else {
            navigate(`../patient/${patientId}/referral/${referralId}/appointment/${appointmentId}`);
          }
        }}
      />
      <PrintDialog
        open={openPrintDialog}
        onClose={() => {
          setOpenPrintDialog(false);
        }}
      />
    </AppDetailsContainer>
  );
};

export default AppointmentDetails;
