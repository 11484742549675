import React from "react";
import { DataGrid, } from "@mui/x-data-grid";
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';


const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === 'light' ? '#51C4D2' : '#308fe8',
  },
}));


const columns = [
  { field: "theatresessioncode", headerName: "Session", flex: 1 },
  { field: "theatrelocationcode", headerName: "Location", flex: 1 },
  { field: "duration", headerName: "Session Duration", flex: 1 },
{
    field: 'progress',
    headerName: 'Duration Remaining',
    width: 150,
    renderCell: (params) => {
      const duration = params.row.duration;
      const durationremaining = params.row.durationremaining;
      const progress = (durationremaining / duration) * 100;

      return (
        <div style={{ width: '100%', color: 'blue.500' }}>
          <BorderLinearProgress variant="determinate" value={progress}  />
          <span>{`${durationremaining}mins`}</span>
        </div>
      );
    },
  },

  { field: "sessiondate", headerName: "Date", flex: 1 },
  { field: "actualstarttime", headerName: "Start", flex: 1 },
  { field: "actualendtime", headerName: "End", flex: 1 },
  { field: "consultant", headerName: "Consultant", flex: 1 },
 { field: "specialty", headerName: "Specialty", flex: 1 },
 ];

const TheatreAvailabilityGrid = ({ records, onSelect }) => {
  const handleRowClick = (params) => {
    console.log("Selected Row ID:", params.sequence);
    console.log("Selected Row Data:", params);
    onSelect(params);
  };

  return (
    <DataGrid
      rows={records}
      columns={columns}
      pageSize={10}
      rowsPerPageOptions={[10, 25, 50, 100]}
      pagination
      autoheight
      onRowClick={handleRowClick}
      getRowId={(row) => row.theatresessioncod + row.sessiondatesearchable + row.theatrelocationcod}
      columnHeaderHeight={25}
    />
  );
};

export default TheatreAvailabilityGrid;
