// pages/ClinicMgr.js
import React, { useState, useEffect } from "react";
import axios from "axios";
import styled from "styled-components";
import { useParams, useNavigate } from "react-router-dom";
import { Button, FormControl, MenuItem, Select } from "@mui/material";
import XButtonGroup from "../../components/xButtonGroup";
import AddIcon from "@mui/icons-material/Add";
import ClinicSlotGrid from "./clinicSlotGrid";
import ClinicNavBar from "./clinicNavBar";
import AvailableSessionGrid from "./availableSessionGrid";
import SearchByTypesForm from "../../components/searchByTypesForm";
import PlaylistAddCheckIcon from "@mui/icons-material/PlaylistAddCheck";
import "../../config";

const FormContainer = styled.div`
  background-color: #f4f4f4; //gray
  display: flex;

  &.alt {
    background-color: #3b4e6b; //darkblue
    height: 58px;
    display: flex;
    padding-left: 20px;
    color: white;
    justify-content: flex-start;
    align-items: flex-end;
  }
  & h2 {
    padding-right: 25px;
    font-size: 18px;
  }

  align-items: center;

  & div.flex1 {
    flex: 1;
  }
  & div.view-mode {
    padding-left: 20px;
  }
`;

const MainContainer = styled.div`
  height: 90vh;
  width: 100vw;

  display: flex;
  flex-direction: column;
  flex: 1;

  .title {
    background-color: #4a4c52;
    height: 40px;
    text-align: center;

    h2 {
      margin: 8px;
      color: #ffffff;
      font-size: 18px;
    }
  }

  .search-btn {
    margin-top: 4px;
    margin-left: 14px;
  }
`;

export const GridContainer = styled.div`
  .MuiDataGrid-columnHeaders {
    background-color: #ebf3fc;

    .MuiDataGrid-columnHeader {
      border-right: 1px solid #ededed;
    }
  }

  .MuiDataGrid-cell {
    border-right: 1px solid #ededed;
  }

  .MuiDataGrid-row {
    &:nth-child(even) {
      background-color: #fafafa;
    }
  }
  flex: 1;
  overflow: scroll;

  & .title-bar {
    background: #fafafa;
    button {
      color: rgba(69, 69, 69) !important;
      border-color: rgba(69, 69, 69, 0.5)!important;

      &:hover {
        color: #4a4c52 !important;
        background-color: #ffffff;
      }
    }
    .title { 
      background: #fafafa; 
      margin-left: 20px; 
    }
justify-content: flex-start;
`;

const ClinicTabBookings = ({ tab, onViewModeChange }) => {
  const [clinics, setClinics] = useState();
  const [slots, setSlots] = useState();
  const [selectedClinic, setSelectedClinic] = useState();
  const cfg = global.config.restAPI;
  const navigate = useNavigate();

  const viewMode = "Availability";
  const specialtyCode = tab; // Get the speciality from the tab code

  const query = { upcoming: "true" };

  const getSlotQuery = (clinicCode, startDate) => {
    const query = {
      clinic: { $eq: clinicCode },
      slotdatesearchable: { $gt: { $date: startDate } },
      $orderby: { slotdatesearchable: "ASC" },
      //slotdatesearchable:{$lt: endDate}
    };

    return JSON.stringify(query);
  };

  const countSlots = (slots, date, filter) => {
    switch (filter) {
      case "available":
        return slots.filter(
          (s) =>
            s.availableindicatordescription === "Available" &&
            s.slotdate === date,
        ).length;
      case "unavailable":
        return slots.filter((s) => s.slotdate === date).length;
      default:
        return null;
    }
  };

  const calculateAvailability = (sessions, rSlots) => {
          // iterate sessions to get slot availability
          return sessions.map((s, idx) => {
            const slots = rSlots.filter((slot) => slot.clinic === s.clinic);

            // Extracting unique dates from slots associated with session
            const uniqueDates = [
              ...new Set(slots.map((session) => session.slotdate)),
            ];

            // Creating an expanded session array with an item for each date and count of availability
            return uniqueDates.map((date) => {
              return {
                ...s,
                instanceDate: date,
                countAvailable: countSlots(slots, date, "available"),
                countTotal: countSlots(slots, date, "unavailable"),
              };
            });
          });

  }

  useEffect(() => {
    // Fetch referral detailData s based on the ID from the URL params
    if (specialtyCode === "ALL") {
      const query = {
        slotdatesearchable: { $gt: { $date: new Date() } },
        $orderby: { slotdatesearchable: "ASC" },
      };
      const p1 = axios.get(`${cfg.url + cfg.endpoints.clinicSessions}/`);
      const p2 = axios.get(
        `${cfg.url + cfg.endpoints.clinicSessionSlots}/?q=${JSON.stringify(query)}`,
      );

      Promise.all([p1, p2])
        .then((responses) => {
          const sessions = responses[0].data.items; //session defined without date instances
          const rSlots = responses[1].data.items; // all slots
          setSlots(rSlots);
          
          const newSessions = calculateAvailability(sessions, rSlots);
          setClinics([].concat.apply([], newSessions));
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      axios
        .get(
          `${cfg.url + cfg.endpoints.clinicSessions}/?q={"spec":{"$eq":"${specialtyCode}"} }`,
        )
        .then((response) => {
          const sessions = response.data.items;

          let promises = sessions.map((item) => {
            const clinicCode = item.clinic;
            return axios.get(
              `${cfg.url + cfg.endpoints.clinicSessionSlots}/?q=${getSlotQuery(clinicCode, new Date())}`,
            );
          });

          Promise.all(promises)
            .then((responses) => {
              // Get slots
              const slots = [];
              responses.forEach((r) => slots.push(...r.data.items));
              setSlots(slots);

              const newSessions = calculateAvailability(sessions, slots);
              
              setClinics([].concat.apply([], newSessions));
            })
            .catch((error) => {
              console.log(error);
            });
        });
    }
  }, [specialtyCode, cfg]);

  const handleSelect = (rowData) => {
    if (selectedClinic)
      navigate(
        `/clinic/${selectedClinic.clinic}/slot/${rowData.row.uniquekey}/appointment/create`,
      );
    else setSelectedClinic(rowData.row);
  };

  const handleFormSubmit = (formData) => {
    // Filtering out empty values from formData
    const nonEmptyFormData = Object.fromEntries(
      Object.entries(formData).filter(([key, value]) => {
        return value && value !== "";
      }),
    );

    //Start the query
    const queryClinics = {}; // firstspecialty: { $eq: tab } }; //, appointmentdate: { $gt: "01/01/2001" } };
    const querySlots = {
      slotdatesearchable: { $gt: { $date: new Date() } },
      $orderby: { slotdatesearchable: "ASC" },
    };

    if (tab !== "ALL") queryClinics.spec = { $eq: tab };

    for (const [key, value] of Object.entries(nonEmptyFormData)) {
      if (key.includes("date"))
        querySlots[key] = { $gt: { $date: value.utc() } };
      else if (key.includes("clinicSearch"))
        querySlots.clinicdescription = { $instr: value }; //.toUpperCase() };
      else if (key.includes("firstclinic"))
        queryClinics.clinic = { $eq: value };
      else if (key.includes("specialty")) queryClinics.spec = { $eq: value };
      else if (key.includes("upcoming"))
        querySlots.slotdatesearchable = { $gt: { $date: new Date() } }; //spec = { $eq: value };
      else querySlots[key] = { $eq: value };
    }

    axios
      .get(
        //`${cfg.url + cfg.endpoints.clinicSessions}/?q={"spec":{"$eq":"${specialtyCode}"} }`,
        `${cfg.url + cfg.endpoints.clinicSessions}/?q=${JSON.stringify(queryClinics)}`,
      )
      .then((response) => {
        const sessions= response.data.items;

        let promises = sessions.map((item) => {
          querySlots.clinic = { $eq: item.clinic };
          return axios.get(
            //    `${cfg.url + cfg.endpoints.clinicSessionSlots}/?q=${getSlotQuery(clinicCode, new Date())}`,
            `${cfg.url + cfg.endpoints.clinicSessionSlots}/?q=${JSON.stringify(querySlots)}`,
          );
        });

        Promise.all(promises)
          .then((responses) => {
            const s = [];
            responses.forEach((r) => s.push(...r.data.items));
            setSlots(s);
            const newSessions = calculateAvailability(sessions, s);
            setClinics([].concat.apply([], newSessions));
          })
          .catch((error) => {
            console.log(error);
          });
      });
  };

  return (
    <MainContainer>
      <FormContainer>
        <div className={["view-mode"]}>
          <XButtonGroup
            key="avail"
            value={viewMode}
            list={["Bookings", "Availability"]}
            onChange={onViewModeChange}
          />
        </div>
        <div className={["flex1"]}>
          <SearchByTypesForm
            title=""
            onSubmit={handleFormSubmit}
            defaultQuery={query}
            types={[
              "firstclinic",
              "firstspecialty",
              "slotdatesearchable",
              "slottypecode",
              "slotprioritycode",
              "availableindicator",
              "appointmentslottypecode",
              "searchClinic",
            ]}
          >
            {tab !== "ALL" && (
              <Button
                type="primary"
                className="search-btn"
                variant="outlined"
                startIcon={<PlaylistAddCheckIcon />}
                onClick={() => {
                  window
                    .open(global.config.miya.demoClinic, "miyaFlow")
                    .focus();
                }}
              >
                Miya Clinic
              </Button>
            )}
            <Button
              className="search-btn"
              type="primary"
              variant="outlined"
              startIcon={<AddIcon />}
              onClick={() => navigate(`/clinic/${tab}/appointment/create`)}
            >
              Appointment
            </Button>
          </SearchByTypesForm>
        </div>
      </FormContainer>
      {selectedClinic && slots && (
        <GridContainer style={{}}>
          <ClinicNavBar
            title={`${selectedClinic.clinic} ${selectedClinic.instanceDate} ${selectedClinic.loc}`}
            onBack={() => setSelectedClinic(null)}
          />
          <ClinicSlotGrid
            clinicSlots={slots.filter(
              (d) =>
                d.clinic === selectedClinic.clinic &&
                d.slotdate === selectedClinic.instanceDate,
            )}
            onSelect={handleSelect}
          />
        </GridContainer>
      )}
      {!selectedClinic && clinics && (
        <GridContainer style={{}}>
          <AvailableSessionGrid
            sessionDates={clinics}
            onSelect={handleSelect} //() => console.log("clicked row")}
          />
        </GridContainer>
      )}
      {!clinics && !slots && <p style={{ marginLeft: 24 }}>Loading...</p>}
    </MainContainer>
  );
};

export default ClinicTabBookings;
