import React, { useState } from "react";
import {
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import styled from "styled-components";

const GridParent = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
`;

const GridItem = styled.div`
  display: block;
  flex: 1;
  padding: 0px 4px;
  align-items: center;
  
  &.btn-group {
    margin-top: 15px;
    text-align: right;
  }
  
  .btn-primary {
    background-color: #337ab7;
    border-color: #204d74;
    font-size: 14px;
    text-transform: none;
    &:hover {
      color: #fff;
      background-color: #286090;
      border-color: #204d74;
    }
  }
  
  &.small-cell {
    max-width: 220px;
  }
  
  & > div {
    background-color: #fff;
  }
  
  .input-label {
    color: #333;
    font-size: 12px;
    font-weight: 700;
  }
`;

const SearchForm = ({ onSubmit }) => {
  //const classes = useStyles();
  const [formData, setFormData] = useState({
    nhs_id: "",
    patientnameupper: "",
    date_of_birth: new Date(0),
    sex: "",
    postCode: "",
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let fd = formData;

    if (formData.date_of_birth.toString() === new Date(0).toString()) fd.date_of_birth = "";
    // Call the onSubmit prop with formData
    onSubmit(fd);
  };

  return (
    <form onSubmit={handleSubmit}>
      <GridParent>
        <GridItem xs={12} sm={6}>
          <InputLabel className="input-label">Patient Name</InputLabel>
          <TextField
              fullWidth
              placeholder="Family Name"
              name="patientnameupper"
              value={formData.patientnameupper}
              onChange={handleChange}
              size="small"
              InputLabelProps={{shrink: false}}
          />
        </GridItem>
        <GridItem>
          <InputLabel className="input-label">Identifiers</InputLabel>
          <TextField
            fullWidth
            placeholder="Identifiers"
            name="nhs_id"
            value={formData.nhs_id}
            onChange={handleChange}
            size="small"
          />
        </GridItem>
        <GridItem xs={12} sm={6}>
          <InputLabel className="input-label">Date of Birth</InputLabel>
          <TextField
            fullWidth
            type="date"
            placeholder="Date of Birth"
            name="date_of_birth"
            value={formData.date_of_birth}
            onChange={handleChange}
            size="small"
          />
        </GridItem>
        <GridItem xs={12} sm={4} className="small-cell">
          <InputLabel className="input-label">Gender</InputLabel>
          <FormControl fullWidth className={"classes.formControl"} size="small">
            <Select
              name="sex"
              value={formData.sex}
              onChange={handleChange}
              size="small"
              displayEmpty
            >
              <MenuItem value="">---</MenuItem>
              <MenuItem value="M">Male</MenuItem>
              <MenuItem value="F">Female</MenuItem>
              <MenuItem value="other">Other</MenuItem>
            </Select>
          </FormControl>
        </GridItem>
        <GridItem xs={12} sm={4} className="small-cell">
          <InputLabel className="input-label">Post Code</InputLabel>
          <TextField
              fullWidth
              placeholder="Post Code"
              name="postCode"
              value={formData.postCode}
              onChange={handleChange}
              size="small"
          />
        </GridItem>
      </GridParent>
      <GridParent>
        <GridItem className="btn-group" xs={12} sm={6}>
          <Button className="btn-primary" type="submit" variant="contained">
            Search Patient
          </Button>
        </GridItem>
      </GridParent>
    </form>
  );
};

export default SearchForm;
