import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogActions,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
} from "@mui/material";
import axios from "axios";
import DialogTitle from "@mui/material/DialogTitle";
import styled from "styled-components";
import { useNavigate, useParams } from "react-router-dom";
import ComboEquipment from "../comboEquipment";
import "../../config";

const FormContainer = styled.div`
  background-color: #f4f4f4;
  padding: 20px;
`;

const MainContainer = styled.div`
  height: 90vh;
  display: flex;
  flex-direction: column;
  flex: 1;
  margin: 20px 50px;

  .title {
    background-color: #4a4c52;
    height: 40px;
    text-align: center;

    h2 {
      margin: 8px;
      color: #ffffff;
      font-size: 18px;
    }
  }
`;
const InputContainer = styled.div`
  height: 100px;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  
`;
export const GridContainer = styled.div`
  flex: 1;
  margin: 20px 0px;
  display: flex;
  flex-direction: column;
  & .row {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 5px 0px;
    padding: 0px 10px;
  }
  & .row.header {
    font-weight: bold;
    font-size: smaller;
    padding: 10px;
    background: #ebf3fc;
}
  }
  & .type {
    flex: 1;
  }
  & .desc {
    flex: 3;
  }
  & .actions {
    width: 250px;
    display: flex;
    align-items: center;
  }
`;

const Increment = styled.div`
  -webkit-border-radius: 28;
  -moz-border-radius: 28;
  border-radius: 28px;
  font-family: Arial;
  color: #8f8f8f;
  font-size: 20px;
  padding: 10px 20px 10px 20px;
  border: solid #d1d1d1 2px;
  text-decoration: none;
  margin: 0px 5px;

  &:hover {
    background: #e8eaeb;
    text-decoration: none;
  }
`;
const Delete = styled.div`
  -webkit-border-radius: 28;
  -moz-border-radius: 28;
  border-radius: 28px;
  font-family: Arial;
  color: #8f8f8f;
  font-size: 16px;
  padding: 10px 20px 10px 20px;
  border: solid #d1d1d1 2px;
  text-decoration: none;

  &:hover {
    background: #e8eaeb;
    text-decoration: none;
  }
`;
function EquipmentDialogue(props) {
  const { onClose, value, open, theatreCode } = props;
  const [equipment, setEquipment] = useState([]);
  const [newItem, setNewItem] = useState({ quantity: 0 });
  const cfg = global.config.restAPI;

  useEffect(() => {
    // Fetch defaults based on theater code
    const fetchDefaults = async () => {
      // Simulating fetching defaults from an API
      const defaults = await getDefaultEquipment(theatreCode);
      setEquipment(defaults);
    };

    fetchDefaults();
  }, []);

  const getDefaultEquipment = async (code) => {
    // Define default equipment based on theater code
    let defaults = [];
    switch (code) {
      case "theater1":
        defaults = [
          { type: "CONS", equipment: "PPE", quantity: 0 },
          { type: "MISC", equipment: "CARDIO", quantity: 0 },
          { type: "TRAY", equipment: "WIPE", quantity: 0 },
        ];
        break;
      case "theater2":
        defaults = [
          { type: "CONS", equipment: "PPE", quantity: 0 },
          { type: "MISC", equipment: "CARDIO", quantity: 0 },
          { type: "TRAY", equipment: "WIPE", quantity: 0 },
        ];
        break;
      // Add more cases for different theater codes as needed
      default:
        defaults = [
          { type: "CONS", equipment: "PPE", quantity: 1 },
          { type: "TRAY", equipment: "Disinfectant", quantity: 1 },
        ];
        break;
    }
    return defaults;
  };

  const handleChange = (index, key, value) => {
    const updatedEquipment = [...equipment];
    updatedEquipment[index][key] = value;
    setEquipment(updatedEquipment);
  };
  const handleRemove = (index, key, value) => {
    const updatedEquipment = [...equipment];
    setEquipment(updatedEquipment.slice(index, 1));
  };

  const handleSubmit = () => {
    onClose(equipment);
  };

  return (
    <Dialog
      fullWidth={true}
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          maxWidth: "lg",
          minHeight: "50vh",
        },
      }}
    >
      <DialogTitle>Select Equipment</DialogTitle>
      <MainContainer>
        <InputContainer>
          <ComboEquipment
            value={newItem.type}
            label="Select Equipment"
            onChange={(val) => {
              setNewItem({ ...val, quantity: 1 });
            }}
          />
          <div>
            <InputLabel className="input-label">Quantity</InputLabel>
            <TextField
              type="number"
              value={newItem.quantity}
              onChange={(e) => {
                setNewItem({ ...newItem, quantity: parseInt(e.target.value) });
              }}
              size="small"
              style={{ width: 300, marginTop: 0 }}
            />
          </div>
          <Button
            type="button"
            variant="contained"
            color="primary"
            onClick={() => {
              setEquipment([...equipment, newItem]);
            }}
          >
            Add
          </Button>
        </InputContainer>

        <GridContainer>
          <div className="row header">
            <div className="type">Type</div>
            <div className="desc"> Equipment</div>
            <div className="actions">Quanity</div>
          </div>
          {equipment.map((item, index) => (
            <div className="row" key={index}>
              <div className="type">{item.type}</div>
              <div className="desc"> {item.equipment}</div>
              <div className="actions">
                <Increment
                  onClick={() =>
                    handleChange(index, "quantity", ++item.quantity)
                  }
                >
                  +
                </Increment>
                {item.quantity}
                <Increment
                  onClick={() =>
                    handleChange(index, "quantity", --item.quantity)
                  }
                >
                  -
                </Increment>
                <Delete onClick={() => handleRemove(index)}>Remove</Delete>
              </div>
            </div>
          ))}
        </GridContainer>
        <DialogActions>
          <form onSubmit={handleSubmit}>
            <Button type="submit" variant="contained" color="primary">
             Done 
            </Button>
          </form>
        </DialogActions>
      </MainContainer>
    </Dialog>
  );
}
export default EquipmentDialogue;
