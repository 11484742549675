import React, { useState, useEffect } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { InputLabel } from "@mui/material";
import axios from "axios";
import "../config";

const ComboConsumables = ({ label, value, error, onChange }) => {
  const [data, setData] = useState([]);
  const cfg = global.config.restAPI;

  useEffect(() => {
    const query = `${cfg.url + cfg.endpoints.theatre.consumableslist}`;
    const fetchData = async () => {
      axios.get(query).then((response) => {
        setData(response.data.items);
      });
    };

    if (cfg.endpoints.theatre.consumableslist) fetchData();
  }, [cfg]);

  return (
    <Autocomplete
      isOptionEqualToValue={(option, value) => option.consumablecode === value}
      onChange={(e, newData) => {
        onChange({
          consumable: newData.description,
          consumablecode: newData.consumablecode,
        });
      }}
      options={data}
      getOptionLabel={(o) => ` ${o.consumablecode} -  ${o.description} `}
      style={{ width: "50%"}}
      renderInput={(params) => (
        <React.Fragment>
          <InputLabel className="input-label">{label}</InputLabel>
          <TextField
            {...params}
            placehoder={label}
            variant="outlined"
            error={error ? true : false}
            helperText={error}
            InputLabelProps={{ shrink: false }}
            style={{ marginTop: 0 }}
          />
        </React.Fragment>
      )}
      size="small"
    />
  );
};

export default ComboConsumables;
