import React from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import styled from "styled-components";
import Badge from "@mui/material/Badge";
import Tooltip from "@mui/material/Tooltip";

const Booking = styled.div`
  width: 100%;
  align-items: center;
  height: 50px;
  display: flex;
  flex-direction: row;
  border: 1px solid #dfe1e4;
  border-radius: 5px;
  margin: 3px 0px;
  background: white;
  font-size: small;

  & .row {
    display: flex;
    flex-direction: row;
  }
  & .box {
    display: flex;
    flex-direction: column;
    &.pip {
      flex: 1;
      margin: 5px;
      & .blob {
        height: 30px;
        border-radius: 5px;
        color: white;
        font-size: small;
        display: flex;
        align-items: center;
        padding: 4px;
        &.blob.A {
          background: #1003b1;
        }
        &.blob.M {
          background: #1003b1;
        }
        &.blob.C {
          background: #1003b1;
        }
        &.blob.D {
          background: #2d9cdb; /* Med */
        }
        &.blob.E {
          background: #56ccf2; /* Low */
        }
      }
    }
    &.body {
      flex: 2;
    }
    &.pop {
      flex: 1;
    }
  }
  & .mainProc {
    font-weight: bold;
  }
  & .duration {
    font-size: x-small;
  }
  & .sequence {
    font-weight: bold;
  }
`;

export function Item(props) {
  const { id, bookings, dirtyItem } = props;

  // Find the bookings record
  const b = bookings.find((i) => i.key === id);
  const procList = [];
  if (b.mainprocedure) procList.push(b.mainprocedure);
  for (let i = 0; i < 10; i++) {
    if (b[`subsidaryprocedure${i}`]) procList.push(b[`subsidaryprocedure${i}`]);
  }

  return (
    <Booking>
      <div className={"box pip " + b.operationtypecode}>
        <Badge color="secondary" variant="dot" invisible={!dirtyItem}>
          <div className={"blob " + b.operationseveritycode}>
            <Tooltip title={b.mainsidedescription}>
              <div className="mainProc">{procList[0]}</div>
              <div className="">{procList[1]}</div>
            </Tooltip>
          </div>
        </Badge>
      </div>
      <div className="box body">
        <div className="pasid">{b.pasid}</div>
        <div className="name">{b.patientname}</div>
      </div>
      <div className="box pop">
        <div className="duration">Dur. {b.intendedoperationduration} mins</div>
        <div className="anaesthetictype">
          {b.anaesthetictypecode === "G" ? "General" : "Local"}
        </div>
      </div>
    </Booking>
  );
}

export default function SeqSortableItem(props) {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: props.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
      <Item id={props.id} bookings={props.bookings} />
    </div>
  );
}
