import React, { useState } from "react";
import {
  IconButton,
  Button,
  InputLabel,
  TextField,
  InputAdornment,
} from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import ManageSearchIcon from "@mui/icons-material/ManageSearch";

import ReferralSearchDialogue from "../components/referralSearchDialogue";
import styled from "styled-components";

const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;
const Column = styled.div`
  flex: 1;
  margin: 0% 5%;
  button {
    margin-top: 15px;
  }
  .btn-primary {
    background-color: #337ab7;
    border-color: #204d74;
    font-size: 14px;
    text-transform: none;
    color: #ffffff;
    &:hover {
      color: #fff;
      background-color: #286090;
      border-color: #204d74;
    }
  }
`;

function isNumeric(value) {
  return !isNaN(value) && typeof value !== 'boolean';
}

const ReferralSelector = ({
  patientId,
  selectedObj,
  onChange,
  error,
  label,
}) => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (id, row) => {
    setOpen(false);
    //Handle tap away - no change
    if (isNumeric(id))
      onChange(id, row);
  };

  const display = (obj) => {
    if(!obj) return '-'

    const spec = obj.referredtospecialty || obj.referralnumber;
    const date = obj.referraldate || ""
    if (obj) {
      return `${spec} - ${date}`;
    } else return "-";
  };

  return (
    <React.Fragment>
      <InputLabel className="input-label">{label?label:"Referral*"}</InputLabel>

      <TextField
        style={{ width: 300, marginTop: 0 }}
        value={display(selectedObj)}
        variant="outlined"
        InputLabelProps={{ shrink: false }}
        InputProps={{
          readOnly: true,
          endAdornment: (
            <InputAdornment>
              <IconButton>
                <ManageSearchIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
        onClick={handleClickOpen}
      error={error ? true:false }
      helperText={error}
        size="small"
      />
      <ReferralSearchDialogue
        patientId={patientId}
        open={open}
        onClose={handleClose}
      />
    </React.Fragment>
  );
};

export default ReferralSelector;
