import React from "react";
import { useDroppable } from "@dnd-kit/core";
import {
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { styled as muiStyled } from "@mui/material/styles";

import SortableItem from "./seqSortableItem";
import { Button, Card, Menu, MenuItem } from "@mui/material";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import DoneIcon from "@mui/icons-material/Done";
import styled from "styled-components";

const BorderLinearProgress = muiStyled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#51C4D2" : "#308fe8",
  },
}));

const Session = styled.div`
  padding: 10px;
  margin: 10px;
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 300px;
  height: 600px;
  & .title {
    font-weight: bold;
  }
  & .subTitle {
    font-size: smaller;
  }
  & .header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 30px;
  }
  & .footerBox {
    flex: 1;
    align-content: end;
  }
  & .footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
`;

export default function SeqContainer(props) {
  const { id, items, session, bookings } = props;
  const [confirmed, setConfirmed] = React.useState();
  const [menuAnchorEl, setMenuAnchorEl] = React.useState(null);
  const { setNodeRef } = useDroppable({
    id,
  });

  const menuOpen = Boolean(menuAnchorEl);

  const handleMenuClick = (event) => {
    setMenuAnchorEl(event.currentTarget);
  };
  const handleMenuClose = (e) => {
    setMenuAnchorEl(null);
    switch (e.currentTarget.innerText) {
      case "Appointment List":
        console.log("clicked");
        break;
      default:
        return;
    }
  };

  const calcAvail = () => {
    const sessionDuration = session.duration;
    const sumItems = items.length * 60;
    const time = sessionDuration - sumItems;
    return time.toString();
  };

  const percentAvail = () => {
    const sessionDuration = session.duration;
    const sumItems = items.length * 60;
    const percent = sumItems / sessionDuration;
    return percent * 100;
  };

  const container = (
    <SortableContext
      id={id}
      items={items}
      strategy={verticalListSortingStrategy}
    >
      <Session ref={setNodeRef}>
        <div className="header">
          <div>
            <div className="title">{session.sessiondate}</div>
            <div className="subTitle">{`${session.actualstarttime} - ${session.actualendtime}`}</div>
            <div style={{ width: "100%", paddingTop: 10 }}>
              <BorderLinearProgress
                variant="determinate"
                value={percentAvail()}
              />
            </div>
          </div>
          <Button
            variant="outlined"
            color={confirmed ? "success" : "secondary"}
            onClick={() => setConfirmed((value) => !value)}
          >
            {confirmed && <DoneIcon fontSize="small" />}
            {confirmed ? "Confirmed" : "Unconfirmed"}
          </Button>
        </div>
        {items.map((id) => (
          <SortableItem key={id} id={id} bookings={bookings} />
        ))}
        <div className="footerBox">
          <div className="footer">
            <div className="availableLabel">
              <div className="avail">{calcAvail()} mins Available</div>
            </div>
            <Button
              variant="outlined"
              onClick={handleMenuClick}
            >
              More
            </Button>
            <Menu
              anchorEl={menuAnchorEl}
              open={menuOpen}
              onClose={handleMenuClose}
            >
              <MenuItem onClick={handleMenuClose}>Cancel Session</MenuItem>
              <MenuItem onClick={handleMenuClose}>Edit Session</MenuItem>
              <MenuItem onClick={handleMenuClose}>Transfer Booking</MenuItem>
            </Menu>
          </div>
        </div>
      </Session>
    </SortableContext>
  );
  if (id !== "root") {
    return (
      <Card variant="outlined" style={{ marginRight: 10 }}>
        {container}
      </Card>
    );
  } else {
    return container;
  }
}
