/* theatreForm.js - Form to create theatre sessions */
import React, { useState, useEffect } from "react";
import {
  Autocomplete,
  TextField,
  Divider,
  Button,
  Box,
  FormControlLabel,
  Checkbox,
  InputLabel,
} from "@mui/material";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "dayjs/locale/en-gb";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";

import NavAppBar from "../components/navAppBar";
import {
  Footer,
  Form,
  Row,
  Column,
  SectionTitle,
  VSpace,
} from "../components/formLayout";
import ComboTheatre from "../components/comboTheatre";
import ComboLookupCode from "../components/comboLookup";
import ComboProfDets from "../components/comboProfDets";
import ComboSpecialty from "../components/comboSpecialty";
import ComboOrgDets from "../components/comboOrgDets";
import styled from "styled-components";
import "../config";
import InfoSnackbar from "../components/infoSnackbar";
import DialogQuestion from "../components/dialogQuestion";
import XDatePicker from "../components/xDatePicker";
import XCheckbox from "../components/xCheckbox";

const BoxButtons = styled.div`
  display: flex;
  flex-direction: row-reverse;
  background-color: #4a4c52;
  & button {
    margin: 10px;
  }
`;

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  .input-label {
    font-size: 12px;
  }
`;


// Function to format date as "DD/MMM/YYYY"
const formatDate = (inputDate) => {
  const options = { day: "2-digit", month: "short", year: "numeric" };
  return new Date(inputDate).toLocaleDateString("en-GB", options);
};

const TheatreForm = ({ match }) => {
  const navigate = useNavigate();
  const { patientId } = useParams();

  const [referralId, setReferralId] = useState();
  const [formData, setFormData] = useState({
    sys: "out",
    date_to: null,
    descript: "GM General Theatre",
    clinic: "GMGEN",
    date_fr: null, //"2017-01-10T00:00:00Z",
    day_1: null,
    day_2: "Y",
    day_3: null,
    day_4: null,
    day_5: null,
    day_6: null,
    day_7: null,
    wk_freq: 1,
    week_1: null,
    week_2: null,
    week_3: null,
    week_4: null,
    week_5: null,
    week_6: null,
    month_1: "Y",
    month_2: "Y",
    month_3: "Y",
    month_4: "Y",
    month_5: "Y",
    month_6: "Y",
    month_7: "Y",
    month_8: "Y",
    month_9: "Y",
    month_10: "Y",
    month_11: "Y",
    month_12: "Y",
    cycle_w: null,
    cycle_1: null,
    cycle_2: null,
    cycle_3: null,
    cycle_4: null,
    cycle_5: null,
    cycle_6: null,
    cycle_7: null,
    cycle_8: null,
    cycle_9: null,
    cycle_10: null,
    cycle_11: null,
    cycle_12: null,
    cycle_13: null,
    stime: "12:00",
    etime: "18:00",
    room: null,
    staff: null,
    joint: null,
    lastdate: "2024-04-23T00:00:00Z",
    dia_auto: "W",
    dia_dura: 13,
    otype: "CO",
    ocode: "CLIP",
    spec: "GM",
    loc: "OPD",
    cli_type: "01",
    codreq: "N",
    dna_rule: "N",
    inc_stat: "N",
    weeks_1: null,
    weeks_2: null,
    weeks_3: null,
    weeks_4: null,
    weeks_5: null,
    weeksb_1: null,
    weeksb_2: null,
    weeksb_3: null,
    weeksb_4: null,
    weeksb_5: null,
    weeksf_1: null,
    weeksf_2: null,
    weeksf_3: null,
    weeksf_4: null,
    weeksf_5: null,
    fullwk_1: null,
    fullwk_2: null,
    fullwk_3: null,
    fullwk_4: null,
    fullwk_5: null,
    desc_pub: "GM General Theatre",
    notes: null,
    hddr_group: "RAND    t1  201701101242GARY    gmlt202401301008",
  });

  const [showInfoSnack, setShowInfoSnack] = useState();
  const [openWaitlistQuestion, setOpenWaitlistQuestion] = useState(false);
  const resturl =
    global.config.restAPI.url + global.config.restAPI.endpoints.referrals + "/";

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setFormData({
      ...formData,
      [name]: checked,
    });
  };

  const handleComboChange = (domain, val) => {
    setFormData({ ...formData, [domain]: val });
  };

  const handleSave = () => {
    // Make a POST request to save or update the data

    setTimeout(() => {
      setShowInfoSnack(`Theatre Created ()`);
      //setOpenWaitlistQuestion(true); // ask to create referral
    }, 1000);
  };

  return (
    <MainContainer>
      <NavAppBar title="Add Theatre Session"></NavAppBar>
      <Form>
        <Box spacing={2} style={{ margin: 20 }}>
          <SectionTitle>Theatre Information</SectionTitle>
          <Row>
            <Column>
              <ComboTheatre
                label="Theatre Code"
                value={formData.clinicCode}
                onChange={(newData) => {
                  handleComboChange("clinicCode", newData);
                }}
              />
            </Column>
            <Column></Column>
          </Row>

          <Row>
            <LocalizationProvider
              dateAdapter={AdapterDayjs}
              adapterLocale="en-gb"
            >
              <Column>
                <InputLabel className="input-label">Date From</InputLabel>
                <XDatePicker
                  value={formData.date_fr}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(newValue) =>
                    setFormData({ ...formData, date_fr: newValue })
                  }
                />
              </Column>
              <Column>
                <InputLabel className="input-label">Date To</InputLabel>
                <XDatePicker
                  value={formData.date_to}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(newValue) =>
                    setFormData({ ...formData, date_to: newValue })
                  }
                />
              </Column>
            </LocalizationProvider>
          </Row>
          <SectionTitle>Further Details</SectionTitle>
          <Row>
            <Column>
              <InputLabel className="input-label">Specialty</InputLabel>
              <ComboSpecialty
                name="referringDiscipline"
                value={formData.referringDiscipline}
                onChange={(code) => {
                  setFormData({ ...formData, referringDiscipline: code });
                }}
                slotProps={{
                  textField: {
                    size: "small",
                    style: { width: 300, marginTop: 0 },
                  },
                }}
              />
            </Column>
            <Column>
              <InputLabel className="input-label">Admin Theatre Name</InputLabel>
              <TextField
                value={formData.clinic}
                placeholder=""
                multiline
                defaultValue=""
                size="small"
                style={{ width: 300, marginTop: 0 }}
              />
            </Column>
          </Row>

          <Row>
            <Column>
              <Autocomplete
                options={["Outpatient Department", "Inpatient Department"]}
                style={{ width: 300 }}
                renderInput={(params) => (
                  <React.Fragment>
                    <InputLabel className="input-label">Location</InputLabel>
                    <TextField
                      {...params}
                      variant="outlined"
                      InputLabelProps={{ shrink: false }}
                      style={{ marginTop: 0 }}
                    />
                  </React.Fragment>
                )}
                size="small"
                onChange={(newData) => {
                  handleComboChange("loc", newData);
                }}
              />
            </Column>
            <Column>
              <InputLabel className="input-label">
                Published Theatre Name
              </InputLabel>
              <TextField
                value={formData.clinic}
                placeholder=""
                multiline
                defaultValue=""
                size="small"
                style={{ width: 300, marginTop: 0 }}
              />
            </Column>
          </Row>
          <Row>
            <Column>
              <ComboLookupCode
                label="Theatre Owner Type"
                name="referringSourceCode"
                value={formData.referringSourceCode}
                refDomain="referringSourceCode"
                onChange={(newData) => {
                  handleComboChange("referringSourceCode", newData);
                }}
              />
            </Column>
            <Column>
              <Autocomplete
                options={["None", "Allowed"]}
                style={{ width: 300 }}
                renderInput={(params) => (
                  <React.Fragment>
                    <InputLabel className="input-label">DNA Rules</InputLabel>
                    <TextField
                      {...params}
                      variant="outlined"
                      InputLabelProps={{ shrink: false }}
                      style={{ marginTop: 0 }}
                    />
                  </React.Fragment>
                )}
                size="small"
                onChange={(newData) => {
                  handleComboChange("loc", newData);
                }}
              />
            </Column>
          </Row>
          <Row>
            <Column>
              <ComboLookupCode
                label="Theatre Owner"
                name="referringSourceCode"
                value={formData.referringSourceCode}
                refDomain="referringSourceCode"
                onChange={(newData) => {
                  handleComboChange("referringSourceCode", newData);
                }}
              />
            </Column>
            <Column>
              <XCheckbox
                label="Coding Required"
                checked={formData.unknown}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    unknown: e.target.checked,
                  })
                }
              />
            </Column>
          </Row>
          <Row>
            <Column>
              <ComboLookupCode
                label="Auto Creation" // Weeks ahead
                name="referringSourceCode"
                value={formData.referringSourceCode}
                refDomain="referringSourceCode"
                onChange={(newData) => {
                  handleComboChange("referringSourceCode", newData);
                }}
              />
            </Column>
            <Column>
              <InputLabel className="input-label">
                Auto Creation Duration (minutes)
              </InputLabel>
              <TextField
                name="noticeReq"
                type="numeric"
                defaultValue="15"
                value={formData.noticeReq}
                onChange={handleInputChange}
                size="small"
                style={{ width: 300, marginTop: 0 }}
              />
            </Column>
          </Row>
          <Row>
            <LocalizationProvider
              dateAdapter={AdapterDayjs}
              adapterLocale="en-gb"
            >
              <Column>
                <InputLabel className="input-label">
                  Last Date Diary Created
                </InputLabel>
                <XDatePicker
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(newValue) =>
                    setFormData({ ...formData, date_fr: newValue })
                  }
                />
              </Column>
            </LocalizationProvider>
          </Row>
          <Row>
            <Column>
              <InputLabel className="input-label">Note</InputLabel>
              <TextField
                placeholder="note"
                multiline
                rows={3}
                defaultValue=""
                size="small"
                style={{ width: 300, marginTop: 0 }}
              />
            </Column>
            <Column></Column>
          </Row>
          <Row>
            <Column></Column>
            <Column></Column>
          </Row>

          <InfoSnackbar
            showMessage={showInfoSnack}
            onClose={() => setShowInfoSnack(false)}
          />
          <DialogQuestion
            title="Add to Waitlist?"
            desc="Would you like to add the referral to the outpatient waiting list?"
            open={openWaitlistQuestion}
            onClose={(isOK) => {
              setOpenWaitlistQuestion(false);
              console.log("question response:" + (isOK ? "OK" : "Cancel"));
              const patId = patientId || formData.internalPatientId;
              if (isOK)
                // create waitlist
                navigate(
                  `../patient/${patId}/referral/${referralId}/waitlist/create`,
                );
              else navigate(`../patient/${patientId}/referral/${referralId}`);
            }}
          />
        </Box>
      </Form>
      <Footer>
        <div>
          <BoxButtons>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSave}
              className="btn-primary"
            >
              Save
            </Button>
            <Button
              variant="outlined"
              onClick={() => {
                navigate(-1);
              }}
              className="btn-cancel"
            >
              Cancel
            </Button>
          </BoxButtons>
        </div>
      </Footer>
    </MainContainer>
  );
};

export default TheatreForm;
