import React, { useState } from "react";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import Button from "@mui/material/Button";
import TheatreSearch from "./theatreSearch";


function TheatreSearchDialogue(props) {
  const { onClose, open, sessionObj, specialtyCode } = props;

  const [currentVal, setCurrentVal] = useState(false);

  const handleClose = () => {
    onClose(currentVal);
  };

  const handleSelect = (newObj) => {
    setCurrentVal(newObj);
    onClose(newObj);
  };

  return (
    <Dialog
      fullWidth={true}
      onClose={handleClose}
      open={open}
      PaperProps={{
        sx: {
          maxWidth: "lg",
          minHeight: "90vh",
        },
      }}
    >
      <DialogTitle>
        Select Session {sessionObj? `- ${sessionObj.theatresessioncode}`:''}
        {specialtyCode}
      </DialogTitle>
      <TheatreSearch
        onSelect={handleSelect}
        sessionObj={sessionObj}
      />
    </Dialog>
  );
}
export default TheatreSearchDialogue;
