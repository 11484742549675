import React, { useState, useEffect } from "react";
import axios from "axios";
import styled from "styled-components";
import { useParams, useNavigate } from "react-router-dom";
import { Button, FormControl, MenuItem, Select } from "@mui/material";
import XButtonGroup from "../../components/xButtonGroup";
import AddIcon from "@mui/icons-material/Add";
import TheatreAvailabilityGrid from "./theatreAvailabilityGrid";
import SearchByTypesForm from "../../components/searchByTypesForm";
import PlaylistAddCheckIcon from "@mui/icons-material/PlaylistAddCheck";
import "../../config";
import { FormContainer } from "../../pages/theatreMgr";

const MainContainer = styled.div`
  height: 90vh;
  width: 87vw;
  display: flex;
  flex-direction: column;
  flex: 1;

  .title {
    background-color: #4a4c52;
    height: 40px;
    text-align: center;

    h2 {
      margin: 8px;
      color: #ffffff;
      font-size: 18px;
    }
  }

  .search-btn {
    margin-top: 4px;
    margin-left: 14px;
  }
`;

export const GridContainer = styled.div`
  .MuiDataGrid-columnHeaders {
    background-color: #ebf3fc;

    .MuiDataGrid-columnHeader {
      border-right: 1px solid #ededed;
    }
  }

  .MuiDataGrid-cell {
    border-right: 1px solid #ededed;
  }

  .MuiDataGrid-row {
    &:nth-child(even) {
      background-color: #fafafa;
    }
  }
  flex: 1;
  overflow: scroll;
`;

const TheatreTabAvailability = ({ tab, sessionFilter, onViewModeChange }) => {
  const [preops, setAppointments] = useState();
  const cfg = global.config.restAPI;
  const navigate = useNavigate();
  //const [viewMode, setViewMode] = useState("Bookings");
  const viewMode = "Availability";
  const sessionCode = sessionFilter.theatresessioncode; // Get the speciality from the tab code

  const query = {}; // upcoming: "true" };

  useEffect(() => {
    // Fetch everything to start with
    const query = {sessiondatesearchable: { $gt: { $date: new Date() } } };
    if (sessionFilter)
      for (const [key, value] of Object.entries(sessionFilter)) {
        query[key] = { $eq: value };
      }

    axios
      .get(
        `${cfg.url + cfg.endpoints.theatre.sessions}/?q=${JSON.stringify(query)}`,
      )
      .then((response) => {
        setAppointments(response.data.items);
      });
  }, [sessionFilter, cfg]);

  const handleSelect = (rowObj) => {
    const session = sessionFilter.theatresessioncode;
    const date = rowObj.row.sessiondate.replaceAll('/','')
    navigate(
      `/theatre/${session}/${date}/booking/create`,
    );
  };

  const handleFormSubmit = (formData) => {
    // Filtering out empty values from formData
    const nonEmptyFormData = Object.fromEntries(
      Object.entries(formData).filter(([key, value]) => {
        return value && value !== "";
      }),
    );

    // Get left-menu filters
    const query = {sessiondatesearchable: { $gt: { $date: new Date() } }}; 
    if (sessionFilter)
      Object.entries(sessionFilter).map(
        ([name, value]) => (query[name] = { $eq: value }),
      );

    // Get search and filter menu filters
    for (const [key, value] of Object.entries(nonEmptyFormData)) {
      if (key.includes("date")) query[key] = { $gt: { $date: value.utc() } };
      else if (key.includes("patientnameupper"))
        query[key] = { $instr: value.toUpperCase() };
      else query[key] = { $eq: value };
    }

    // Fetch from the API
    axios
      .get(
        `${cfg.url + cfg.endpoints.theatre.sessions}/?q=${JSON.stringify(query)}`,
      )
      .then((response) => {
        setAppointments(response.data.items);
      })
      .catch((error) => {
        console.log(error);
      });

  };

  return (
    <MainContainer>
      <FormContainer>
        <div className={["view-mode"]}>
          <XButtonGroup
            key="availability"
            value={viewMode}
            list={["Bookings", "Availability", "Calendar"]}
            onChange={onViewModeChange}
          />
        </div>
        <div className={["flex1"]}>
          <SearchByTypesForm
            title=""
            onSubmit={handleFormSubmit}
            defaultQuery={query}
            types={[
              "specialty",
              "sessiondatesearchable",
            ]}
          >
            <Button
              type="primary"
              className="search-btn"
              variant="outlined"
              startIcon={<PlaylistAddCheckIcon />}
              onClick={() => {
                window.open(global.config.miya.demoTheatre, "miyaFlow").focus();
              }}
            >
              Miya Theatre
            </Button>
            <Button
              className="search-btn"
              type="primary"
              variant="outlined"
              startIcon={<AddIcon />}
              onClick={() => navigate(`/theatre/${sessionCode}/booking/create`)}
            >
              Booking
            </Button>
          </SearchByTypesForm>
        </div>
      </FormContainer>
      {preops && (
        <GridContainer style={{}}>
          <TheatreAvailabilityGrid records={preops} onSelect={handleSelect} />
        </GridContainer>
      )}
      {!preops && <p style={{ marginLeft: 24 }}>Loading...</p>}
    </MainContainer>
  );
};

export default TheatreTabAvailability;
