import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, AppBar, Toolbar, Typography } from '@mui/material';
import styled from 'styled-components';

const Bar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px 10px 13px;
  height: 30px;
  border-bottom: #bfbebe solid 1px;
  line-height: 3rem;
  font-size: 1.1rem;
  background: #ebf3fc; #faf8f8;
  color: black;
  border-bottom: 1px solid rgb(226, 226, 226);
`

const LeftPanel= styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const Title = styled.span`
  font-size: normal;
  font-weight: bolder;
`

const ClinicNavBar= ({title, onBack, children}) => {
  return (
    <Bar className="title-bar">
{onBack &&(
      <Button variant="outlined" onClick={onBack}>
          Back
      </Button>
)}
      <Title className="title">{title}</Title>
      <LeftPanel>     
        {children}
      </LeftPanel>
    </Bar>
  
  )
};

export default ClinicNavBar;

