// ClinicTabBookings.js
import React, { useState, useEffect } from "react";
import axios from "axios";
import styled from "styled-components";
import { useParams, useNavigate } from "react-router-dom";
import { Button, FormControl, MenuItem, Select } from "@mui/material";
import XButtonGroup from "../../components/xButtonGroup";
import AddIcon from "@mui/icons-material/Add";
import AppointmentGrid from "../../components/appointmentGrid";
import SearchByTypesForm from "../../components/searchByTypesForm";
import PlaylistAddCheckIcon from "@mui/icons-material/PlaylistAddCheck";
import "../../config";

const FormContainer = styled.div`
  background-color: #f4f4f4; //gray
  display: flex;

  &.alt {
    background-color: #3b4e6b; //darkblue
    height: 58px;
    display: flex;
    padding-left: 20px;
    color: white;
    justify-content: flex-start;
    align-items: flex-end;
  }
  & h2 {
    padding-right: 25px;
    font-size: 18px;
  }

  align-items: center;

  & div.flex1 {
    flex: 1;
  }
  & div.view-mode {
    padding-left: 20px;
  }
`;

const MainContainer = styled.div`
  height: 90vh;
  width: 100vw;

  display: flex;
  flex-direction: column;
  flex: 1;

  .title {
    background-color: #4a4c52;
    height: 40px;
    text-align: center;

    h2 {
      margin: 8px;
      color: #ffffff;
      font-size: 18px;
    }
  }

  .search-btn {
    margin-top: 4px;
    margin-left: 14px;
  }
`;

export const GridContainer = styled.div`
  .MuiDataGrid-columnHeaders {
    background-color: #ebf3fc;

    .MuiDataGrid-columnHeader {
      border-right: 1px solid #ededed;
    }
  }

  .MuiDataGrid-cell {
    border-right: 1px solid #ededed;
  }

  .MuiDataGrid-row {
    &:nth-child(even) {
      background-color: #fafafa;
    }
  }
  flex: 1;
  overflow: scroll;
`;

const ClinicTabBookings = ({ tab, onViewModeChange }) => {
  const [appointments, setAppointments] = useState();
  const [specialty, setSpecialty] = useState(); //"ORTH");
  const cfg = global.config.restAPI;
  const navigate = useNavigate();
  //const [viewMode, setViewMode] = useState("Bookings");
  const viewMode = "Bookings";


  useEffect(() => {
    // Fetch everything to start with
    const query = { appointmentsearchabledate: { $gt: { $date: new Date() } } };
    if (tab !== "ALL") {
      query.firstspecialty = { $eq: tab };
    }

    axios
      .get(
        `${cfg.url + cfg.endpoints.appointments}/?q=${JSON.stringify(query)}`,
      )
      .then((response) => {
        setAppointments(response.data.items);
      });
  }, [tab, cfg]);

  const handleSelect = (id, rowData) => {
    navigate(
      `/clinic/patient/${rowData.internalpatientid}/referral/${rowData.referralnumber}/appointment/${rowData.uniqueepisodeappointmentnumber}`,
    );
  };

  const handleFormSubmit = (formData) => {
    // Filtering out empty values from formData
    const nonEmptyFormData = Object.fromEntries(
      Object.entries(formData).filter(([key, value]) => {
        return value && value !== "";
      }),
    );

    const query = {}; // firstspecialty: { $eq: tab } }; //, appointmentdate: { $gt: "01/01/2001" } };
    if (tab !== "ALL") query.firstspecialty = { $eq: tab };

    for (const [key, value] of Object.entries(nonEmptyFormData)) {
      if (key.includes("date")) query[key] = { $gt: { $date: value.utc() } };
      else if (key.includes("patientnameupper"))
        query[key] = { $instr: value.toUpperCase() };
      else query[key] = { $eq: value };
    }

    // Fetch from the API
    axios
      .get(
        `${cfg.url + cfg.endpoints.appointments}/?q=${JSON.stringify(query)}`,
      )
      .then((response) => {
        setAppointments(response.data.items);
      })
      .catch((error) => {
        console.log(error);
      });

    // Fetch from slot API
    axios
      .get(
        `${cfg.url + cfg.endpoints.appointments}/?q=${JSON.stringify(query)}`,
      )
      .then((response) => {
        setAppointments(response.data.items);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <MainContainer>
      <FormContainer>
        <div className={["view-mode"]}>
          <XButtonGroup
            key="book"
            value={viewMode}
            list={["Bookings", "Availability"]}
            onChange={onViewModeChange}
          />
        </div>
        <div className={["flex1"]}>
          <SearchByTypesForm
            title=""
            onSubmit={handleFormSubmit}
            defaultQuery={{}}
            types={[
              "firstclinic",
              "firstspecialty",
              "appointmentsearchabledate",
              "appointmentstatuscode",
              "attendancetypecode",
              "appointmentprioritycode",
              "bookingsystem",
            ]}
          >
            {tab !== "ALL" && (
              <Button
                type="primary"
                className="search-btn"
                variant="outlined"
                startIcon={<PlaylistAddCheckIcon />}
                onClick={() => {
                  window
                    .open(global.config.miya.demoClinic, "miyaFlow")
                    .focus();
                }}
              >
                Miya Clinic
              </Button>
            )}
            <Button
              className="search-btn"
              type="primary"
              variant="outlined"
              startIcon={<AddIcon />}
              onClick={() => navigate(`/clinic/${tab}/appointment/create`)}
            >
              Appointment
            </Button>
          </SearchByTypesForm>
        </div>
      </FormContainer>
      {appointments && (
        <GridContainer style={{}}>
          <AppointmentGrid records={appointments} onSelect={handleSelect} />
        </GridContainer>
      )}
      {!appointments && <p style={{ marginLeft: 24 }}>Loading...</p>}
    </MainContainer>
  );
};

export default ClinicTabBookings;
