import React, { useState } from "react";
import {
  IconButton,
  Button,
  InputLabel,
  TextField,
  InputAdornment,
} from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import ManageSearchIcon from "@mui/icons-material/ManageSearch";

import TheatreSearchDialogue from "./theatreSearchDialogue";
import styled from "styled-components";
import { Row, Column, SectionTitle, VSpace } from "../../components/formLayout";

const SessionSelector = ({
  selectedSessionObj,
  onChange,
  sessionCode,
  specialtyCode,
}) => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
    onChange(value);
  };

  const sessionDisplay = (obj) => {
    if (obj && obj.sessiondate) {
      return `${obj.sessiondate} ${obj.theatresessioncode}`;
    } else return "-";
  };

  return (
    <React.Fragment>
      <Column>
        <InputLabel className="input-label">Session</InputLabel>

        <TextField
          value={sessionDisplay(selectedSessionObj)}
          variant="outlined"
          InputLabelProps={{ shrink: false }}
          InputProps={{
            readOnly: true,
            endAdornment: (
              <InputAdornment style={{ marginTop: -5 }}>
                <IconButton>
                  <ManageSearchIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
          onClick={handleClickOpen}
          size="small"
          style={{ width: 300, marginTop: 0 }}
        />
      </Column>
      <TheatreSearchDialogue
        sessionObj={selectedSessionObj}
        open={open}
        onClose={handleClose}
      />
    </React.Fragment>
  );
};

export default SessionSelector;
