import React, { useState, useMemo } from "react";
import { Calendar, Views, dayjsLocalizer } from "react-big-calendar";
import dayjs from "dayjs";
import "react-big-calendar/lib/css/react-big-calendar.css";

const localizer = dayjsLocalizer(dayjs);

const ColoredDateCellWrapper = ({ children }) =>
  React.cloneElement(React.Children.only(children), {
    style: {
      backgroundColor: "#f7f7f7" //lightblue",
    },
  });

const generateEvents = (schedule, startDate, numberOfDays) => {
  const events = [];
  const startDateTime = dayjs(startDate);

  // Iterate through each day
  for (let i = 0; i < numberOfDays; i++) {
    const currentDate = startDateTime.add(i, "day");

    // Check if the clinic is scheduled for this month
    const monthIndex = currentDate.month() + 1;
    const monthKey = `month_${monthIndex}`;
    if (schedule[monthKey] !== "Y") {
      continue;
    }

    // Check if the clinic is scheduled for this day of the week
    const dayIndex = currentDate.day();
    const dayKey = `day_${dayIndex}`;
    if (schedule[dayKey] !== "Y") {
      continue;
    }

    // Construct event object
    const startTime = dayjs(
      `${currentDate.format("YYYY-MM-DD")}T${schedule.stime}`,
    );
    const endTime = dayjs(
      `${currentDate.format("YYYY-MM-DD")}T${schedule.etime}`,
    );
    events.push({
      id: schedule.ocode,
      title: schedule.clinic,
      start: startTime.toDate(),
      end: endTime.toDate(),
    });
  }

  return events;
};

const parseDateTime = (slotdate, slottime) => {
  const [day, month, year] = slotdate.split("/").map(Number);
  const [hour, minute] = slottime.split(":").map(Number);
  return new Date(year, month - 1, day, hour, minute);
};

function TheatreCalendar({ sessions, bookings, onSelect }) {
  const { components, defaultDate, min, max, views } = useMemo(
    () => ({
      components: {
        timeSlotWrapper: ColoredDateCellWrapper,
      },
      defaultDate: new Date(),
      min: new Date(1972, 0, 1, 7, 0, 0), //dates.add(dates.endOf(new Date(2015, 17, 1), 'day'), -1, 'hours'),
      max: new Date(1972, 0, 1, 19, 0, 0), //dates.add(dates.endOf(new Date(2015, 17, 1), 'day'), -1, 'hours'),
      views: ["week", "month", "day"], //Object.keys(Views).map((k) => Views[k]),
    }),
    [],
  );
/*
  const slotEvents = clinicSlots.slice(0, 100).map((slot) => {
    const startDateTime = parseDateTime(slot.slotdate, slot.slottime);
    const endDateTime = new Date(startDateTime.getTime() + 15 * 60000); // Adding 15 minutes in milliseconds
    return {
      id: slot.sequence,
      sequence: slot.sequence,
      elem_ref: slot.elem_ref,
      title: slot.clinic,
      start: startDateTime,
      end: endDateTime,
    };
  });
*/
  const sessionEvents = sessions.slice(0, 100).map((slot) => {
    const startDateTime = parseDateTime(slot.sessiondate, slot.actualstarttime);
    const endDateTime =  parseDateTime(slot.sessiondate, slot.actualendtime);
    return {
      id: slot.sequence,
      sequence: slot.sequence,
      elem_ref: slot.elem_ref,
      title: `${slot.durationremaining} mins Available ${slot.theatresessioncode}`,
      start: startDateTime,
      end: endDateTime,
    };
  });

  let bgEvents = []
  if(bookings){
  const startDate = dayjs();
  const numberOfDays = 90; // Number of days
  bgEvents = bookings.map((schedule) =>
    generateEvents(schedule, startDate, numberOfDays),
  );
  }

  const handleEventSelect = (slotEvent) => {
    // Find the selected event in slots...
    onSelect(
      sessions.find((element) => {
        return element.sequence === slotEvent.id;
      }),
    );
  };
  return (
      <div style={{ marginTop: 40}}>
      <Calendar
        backgroundEvents={bgEvents}
        localizer={localizer}
        events={sessionEvents}
        startAccessor="start"
        endAccessor="end"
        style={{ height: '80vh'}}
        components={components}
        defaultDate={defaultDate}
        views={views}
        onSelectEvent={handleEventSelect}
        min={min}
        max={max}
        step={30}
        slot={60}
        eventPropGetter={
    (event, start, end, isSelected) => {
      let newStyle = {
        backgroundColor: "#00dee5",
        color: 'black',
        borderRadius: "0px",
        border: "none"
      };

      if (event.isFull){
        newStyle.backgroundColor = "#fe8fa7l"
      }

      return {
        className: "",
        style: newStyle
      };
    }
  }
      />
    </div>
  );
}

export default TheatreCalendar;
