import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, AppBar, Toolbar, Typography } from '@mui/material';
import styled from 'styled-components';

const Bar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: #bfbebe solid 1px;
  line-height: 3rem;
  font-size: 1.1rem;
  color: black;
  border-bottom: 1px solid rgb(226, 226, 226);
  background-color: #4a4c52;
  height: 40px;
  text-align: center;
  padding: 0px 6px;
  position:fixed;
  width: 100%;  

  .title {
    margin: 8px;
    color: #ffffff;
    font-size: 18px;
    margin-right: 100px;
  }

  button {
    color: #ffffff;
    border-color: #ffffff;
    margin: 2px;
    margin-top: 5px;

    &:hover {
      color: #4a4c52;
      background-color: #ffffff;
    }
  }
`

const LeftPanel= styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const Title = styled.b`
  font-size: larger;
`

const NavAppBar = ({title, children}) => {
  const navigate = useNavigate();
  return (
    <Bar className="title-bar">
      <Button className="back-btn" variant="outlined" onClick={()=>navigate(-1)}>
          Back
      </Button>
      <Title className="title">{title}</Title>
      <LeftPanel>     
        {children}
      </LeftPanel>
    </Bar>
  
  )
};

export default NavAppBar;

