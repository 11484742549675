import React, { useState, useEffect } from "react";
import { Button, AppBar, Toolbar, Typography } from "@mui/material";
import axios from "axios";
import styled from "styled-components";
import "../../config";
import Avatar from "../../assets/avatar.svg";

const Summary = styled.div`
  background: white;
  -webkit-text-size-adjust: 100%;
  line-height: 1.428571429;
  font-size: 12px;
  -webkit-tap-highlight-color: transparent;
  font-family:
    Source Sans Pro,
    sans-serif;
  color: #000;
  box-sizing: border-box;
  padding: 1rem;
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;

  & .patient-overview-admission-history-container {
    -webkit-text-size-adjust: 100%;
    line-height: 1.428571429;
    font-size: 12px;
    -webkit-tap-highlight-color: transparent;
    font-family:
      Source Sans Pro,
      sans-serif;
    color: #000;
    box-sizing: border-box;
    align-self: stretch;
    flex: 1;
  }

  & h4 {
    font-size: 1.1rem;
    text-align: left;
    border-bottom: 1px solid rgb(225, 226, 234);
    padding: 0.5rem 0.5rem;
    margin: -0.5rem -0.5rem 1rem;
    flex: 0 0 1.2rem;
    flex-basis: 1.2rem;
    font-weight: 600;
  }

  & header {
    -webkit-text-size-adjust: 100%;
    line-height: 1.428571429;
    font-size: 12px;
    -webkit-tap-highlight-color: transparent;
    font-family:
      Source Sans Pro,
      sans-serif;
    box-sizing: border-box;
    flex: 0;
    display: flex;
    flex-flow: row nowrap;
    border-bottom: 1px solid #f2f3f9;
    color: #827d7d;
    height: 18px;
  }
  & .list-column.clinic {
    padding: 0 2px;
    flex: 1;
  }
  & .list-column.date {
    padding: 0 2px;
    flex: 1.5;
  }
  & .list-column.type{
    padding: 0 2px;
    flex: 0.5;
  }
  & .list-column.specialty{
    padding: 0 2px;
    flex: 0.5;
  }
  & .list-column.status {
    padding: 0 2px;
    flex: 1.5;
  }

  & .list-column {
    -webkit-text-size-adjust: 100%;
    font-size: 12px;
    -webkit-tap-highlight-color: transparent;
    font-family:
      Source Sans Pro,
      sans-serif;
    color: #827d7d;
    box-sizing: border-box;
    padding: 0 2px;
    line-height: 1rem;
    flex: 0 0 25px;
    display: flex;
  }
  & .admission-overview-row {
    -webkit-text-size-adjust: 100%;
    line-height: 1.428571429;
    font-size: 12px;
    -webkit-tap-highlight-color: transparent;
    font-family:
      Source Sans Pro,
      sans-serif;
    color: #000;
    box-sizing: border-box;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    height: 25px;
    justify-content: space-evenly;
  }
  & .list-column.flex-ellipsis {
  }
  & footer {
    text-align: center;
    padding-top: 15px;
  }
`;

function isNumeric(value) {
  return !isNaN(value) && typeof value !== "boolean";
}

const WaitlistSummary = ({ referralId, patientId, onShowAll, demoRecs }) => {
  const cfg = global.config.restAPI;
  const [waitlist, setWaitlist] = useState();

  useEffect(() => {
    // Override and display a specific waitlist
    if (demoRecs) {
      setWaitlist(demoRecs);
      return;
    }
    // Fetch patient data for the patient ID frbm the URL params
    const query = { internalpatientid: { $eq: patientId }, referralnumber: { $eq: referralId}  } ;
    const url = `${cfg.url + cfg.endpoints.waitlistEntries}/?q=${JSON.stringify(query)};`;
    const demo = `${cfg.url + cfg.endpoints.waitlistEntries}/`;

    axios.get(isNumeric(patientId) ? url : demo).then((response) => {
      setWaitlist(response.data.items);
    });
  }, [demoRecs, referralId, patientId, cfg]);

  return (
    <Summary className={"overview-module-container"}>
      <h4>Waitlist</h4>
      <div class="patient-overview-admission-history-container">
        <header>
          <div class="list-column clinic">Session</div>
          <div class="list-column date">Date on List</div>
          <div class="list-column type">Type</div>
          <div class="list-column specialty">Speciality</div>
          <div class="list-column status">Status</div>
        </header>
        {waitlist && ( waitlist.slice(0,6).map((item)=>
        <div class="admission-overview-row">
          <div class="list-column clinic">{item.clinic}</div>
          <div class="list-column date">{item.currentdateonlist}</div>
          <div class="list-column type">{item.appointmentslottypecode}</div>
          <div class="list-column specialty">{item.specialty}</div>
          <div class="list-column status">{item.entrystatusdescription}</div>
        </div>
))}
      </div>
      <footer>
        <Button class="btn btn-default" onClick={onShowAll}>
          Show All
        </Button>
      </footer>
    </Summary>
  );
};

export default WaitlistSummary;
