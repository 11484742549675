import React from "react";
import { DataGrid } from "@mui/x-data-grid";

const columns = [
  { field: "theatresessioncode", headerName: "Code", flex: 1 },
  { field: "theatrelocationdescription", headerName: "Name", flex: 1 },
  { field: "EMERGENCY", headerName: "Speciality", flex: 1 },
  { field: "sessiondate", headerName: "Date", flex: 1 },
  { field: "actualstarttime", headerName: "Start", flex: 1 },
  { field: "actualendtime", headerName: "End", flex: 1 },
  { field: "durationremaining", headerName: "Available (min)", flex: 1 },
];

const TheatreSessionGrid = ({ theatreSessions, onSelect }) => {
  const handleRowClick = (params) => {
    console.log("Selected Row ID:", params.id);
    console.log("Selected Row Data:", params);
    onSelect(params);
  };

  return (
    <DataGrid
      rows={theatreSessions}
      columns={columns}
      pageSize={10}
      rowsPerPageOptions={[10, 25, 50, 100]}
      pagination
      autoheight
      onRowClick={handleRowClick}
      getRowId={(row) => row.theatresessioncode + row.sessiondate}
      columnHeaderHeight={25}
    />
  );
};

export default TheatreSessionGrid;
