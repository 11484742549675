import React, { useState, useEffect } from "react";
import {
  Autocomplete,
  Divider,
  TextField,
  Button,
  Box,
  FormControlLabel,
  Checkbox,
  InputLabel,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import axios from "axios";
import "dayjs/locale/en-gb";
import { useNavigate, useParams } from "react-router-dom";

import NavAppBar from "../components/navAppBar";
import ComboLookupCode from "../components/comboLookup";
import ComboProfDets from "../components/comboProfDets";
import ComboSpecialty from "../components/comboSpecialty";
import ComboClinic from "../components/comboClinic";
import styled from "styled-components";
import "../config";
import InfoSnackbar from "../components/infoSnackbar";
import DialogQuestion from "../components/dialogQuestion";
import BannerPatient from "../components/bannerPatient";
import BannerReferral from "../components/bannerReferral";
import XDatePicker from "../components/xDatePicker";
import {
  Footer,
  Form,
  Row,
  Column,
  SectionTitle } from "../components/formLayout";
import PatientSelector from "../components/patientSelector";
import ReferralSelector from "../components/referralSelector";

const BoxButtons = styled.div`
  display: flex;
  flex-direction: row-reverse;
  background-color: #4a4c52;
  & button {
    margin: 10px;
  }
`;

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  .input-label {
    font-size: 12px;
  }
`;

// Function to format date as "DD/MMM/YYYY"
const formatDate = (inputDate) => {
  const options = { day: "2-digit", month: "short", year: "numeric" };
  return new Date(inputDate).toLocaleDateString("en-GB", options);
};

const WardForm = () => {
  const navigate = useNavigate();
  const { patientId, referralId } = useParams();
  const [formData, setFormData] = useState({
    internalPatientId: patientId,
    referralId: referralId, // "11/JAN/2024"
    dateOnList: null,
    patientCategory: null,
    attenderNum: null,
    uniqueAttendance: null,
    staffGrade: null,
    staffName: null,
    specialty: null,
    hospitalCode: null,
    patientType: null,
    outcome: null,
    referringSourceCode: null,
    departureDate: null,
  });

  const [showInfoSnack, setShowInfoSnack] = useState();
  const [openPrintQuestion, setOpenPrintQuestion] = useState(false);
  const [openPrintDialogue, setOpenPrintDialogue] = useState(false);
  const resturl =
    global.config.restAPI.url + global.config.restAPI.endpoints.waitlist + "/";

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setFormData({
      ...formData,
      [name]: checked,
    });
  };

  const handleComboChange = (domain, val) => {
    setFormData({ ...formData, [domain]: val });
  };

  const handleSave = () => {
    // Make a POST request to save or update the data

    const data = {
      internalpatientid: 0, // url parameter
    };

    setTimeout(() => {
      setShowInfoSnack(`Waitlist Created`);
      setOpenPrintQuestion(true); // ask to create referral
    }, 1000);
    /*
    axios
      .post(resturl, data)
      .then((response) => {
        setShowInfoSnack(
          `Waitlist Created (${JSON.stringify(response.data)}).`,
        );
      })
      .catch((error) => {
        console.error("Error saving data:", error);
        setShowInfoSnack(`Sorry, there was a problem.`);
      });
*/
  };

  return (
    <MainContainer style={{ flex: 1 }}>
      <NavAppBar title="Add Outpatient Attender"></NavAppBar>
      {(referralId || (formData.patientObj && formData.referralObj)) && (
        <BannerReferral
          patientId={patientId || formData.internalPatientId}
          patientObj={formData.patientObj}
          referralId={referralId}
          onSelect={() =>
            navigate(`../referral/patient/${patientId}/referral/${referralId}`)
          }
        />
      )}
      {((patientId && !referralId) ||
        (formData.patientObj && !formData.referralObj)) && (
        <BannerPatient
          patientId={patientId || formData.internalPatientId}
          patientObj={formData.patientObj}
          onSelect={() => navigate(`../patient/${patientId}`)}
        />
      )}
      <Form>
        <Box spacing={2}>

              <SectionTitle>Patient Selection </SectionTitle>

              <Row>
                <Column>
                  <PatientSelector
                    selectedObj={formData.patientObj}
                    onChange={(id, rowData) => {
                      setFormData({
                        ...formData,
                        patientObj: rowData,
                        internalPatientId: id,
                      });
                    }}
                  />
                </Column>
              </Row>
              <SectionTitle>Attendance Details </SectionTitle>
          <Row>
            <LocalizationProvider
              dateAdapter={AdapterDayjs}
              adapterLocale="en-gb"
            >
              <Column>
                <InputLabel className="input-label">Date On List</InputLabel>
                <XDatePicker
                  value={formData.dateOnList}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(newValue) =>
                    setFormData({ ...formData, dateOnList: newValue })
                  }
                />
              </Column>
              <Column>
                <ComboLookupCode
                    label="Attender Number"
                    value={formData.attenderNum}
                    refDomain="attenderNum"
                    onChange={(newData) => {
                      handleComboChange("attenderNum", newData);
                    }}
                />
              </Column>
            </LocalizationProvider>
          </Row>
          <Row>
            <Column>
              <ComboLookupCode
                label="Unique Attendance"
                value={formData.uniqueAttendance}
                refDomain="uniqueAttendance"
                onChange={(newData) => {
                  handleComboChange("uniqueAttendance", newData);
                }}
              />
            </Column>
            <Column>
              <LocalizationProvider
                dateAdapter={AdapterDayjs}
                adapterLocale="en-gb"
              >
                <InputLabel className="input-label">Departure Date</InputLabel>
                <XDatePicker
                  value={formData.departureDate}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(newValue) =>
                    setFormData({ ...formData, departureDate: newValue })
                  }
                />
              </LocalizationProvider>
            </Column>
          </Row>
          <Row>
            <Column>
              <ComboSpecialty
                label="Specialty" /// add auto populate from clinic
                value={formData.specialty}
                onChange={(code) => {
                  setFormData({ ...formData, specialty: code });
                }}
              />
            </Column>
            <Column>
              <ComboLookupCode
                  label="Referral Source" /// add auto populate from clinic
                  value={formData.referringSourceCode}
                  onChange={(code) => {
                    setFormData({ ...formData, referringSourceCode: code });
                  }}
              />
            </Column>
          </Row>
          <Row>
            <Column>
              <ComboLookupCode
                  label="Patient Type" /// add auto populate from clinic
                  value={formData.patientType}
                  onChange={(code) => {
                    setFormData({ ...formData, patientType: code });
                  }}
              />
            </Column>
            <Column>
              <ComboLookupCode
                  label="Patient Category" /// add auto populate from clinic
                  value={formData.patientCategory}
                  onChange={(code) => {
                    setFormData({ ...formData, patientCategory: code });
                  }}
              />
            </Column>
          </Row>
          <Row>
            <Column>
              <ComboLookupCode
                  label="Grade of Staff" /// add auto populate from clinic
                  value={formData.staffGrade}
                  onChange={(code) => {
                    setFormData({ ...formData, patientType: code });
                  }}
              />
            </Column>
            <Column>
              <InputLabel className="input-label">Outcome</InputLabel>
              <TextField
                  name="noticeReq"
                  type="numeric"
                  defaultValue="0"
                  value={formData.outcome}
                  onChange={handleInputChange}
                  size="small"
                  style={{ width: 300, marginTop: 0 }}
              />
            </Column>
          </Row>
          <Footer>
            <div>
              <BoxButtons>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSave}
                  className="btn-primary"
                >
                  Save
                </Button>
                <Button
                  variant="outlined"
                  onClick={() => {
                    navigate(
                        `/clinic/patient/${patientId}/referral/${referralId}/wardattender}`,
                    );
                  }}
                  className="btn-cancel"
                >
                  Cancel
                </Button>
              </BoxButtons>
            </div>
          </Footer>
        </Box>
      </Form>
    </MainContainer>
  );
};

export default WardForm;
