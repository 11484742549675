import React from "react";
import { DataGrid } from "@mui/x-data-grid";

const columns = [
  { field: "clinic", headerName: "Code", flex: 1 },
  { field: "descript", headerName: "Name", flex: 1 },
  { field: "spec", headerName: "Speciality", flex: 1 },
  { field: "stime", headerName: "Start", flex: 1 },
  { field: "etime", headerName: "End", flex: 1 },

];

const ClinicSessionGrid = ({ clinicSessions, onSelect }) => {
  const handleRowClick = (params) => {
    console.log("Selected Row ID:", params.id);
    console.log("Selected Row Data:", params);
    onSelect(params);
  };

  return (
    <DataGrid
      rows={clinicSessions}
      columns={columns}
      pageSize={10}
      rowsPerPageOptions={[10, 25, 50, 100]}
      pagination
      autoheight
      onRowClick={handleRowClick}
      getRowId={(row) => row.clinic}
      columnHeaderHeight={25}
    />
  );
};

export default ClinicSessionGrid;
