import React, { useState, useEffect } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import axios from "axios";
import "../config";
import InputLabel from "@mui/material/InputLabel";

const ComboProfDets = ({ label, type, specialty, value, error, onChange }) => {
  const [data, setData] = useState([]);
  const cfg = global.config.restAPI;

  useEffect(() => {
    let query = `${cfg.url + cfg.endpoints.profDets[type]}`;
    if (specialty) query = query + `"C_SPEC":{"$eq":"${encodeURIComponent(specialty)}"}}`;
    const fetchData = async () => {
      axios.get(query).then((response) => {
        setData(response.data.items);
      });
    };

    if (type === "consultant" && !specialty) return;
    if (cfg.endpoints.profDets[type]) fetchData();
       else

    throw(new Error(`Can't find ${type} in config`));
  }, [cfg, type, specialty]);

  return (
    <Autocomplete
      isOptionEqualToValue={(option, value) => option.obj_prof === value}
        
      onChange={(e, newData) => {
        const val = newData ? newData.obj_prof : null; 
        onChange(val)
      }}
      options={data}
      getOptionLabel={(o) => `${o.sn}, ${o.fn1}`}
      style={{ width: 300 }}
      renderInput={(params) => (
          <React.Fragment>
            <InputLabel className="input-label">{label}</InputLabel>
            <TextField {...params} variant="outlined"

          error={error ? true : false}
          helperText={error}
 InputLabelProps={{shrink: false}} style={{marginTop: 0}}/>
          </React.Fragment>
      )}
      size="small"
    />
  );
};

export default ComboProfDets
