import React, { useState, useEffect } from "react";
import axios from "axios";
import styled from "styled-components";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Grid, InputLabel, Select, MenuItem } from "@mui/material";
 import AddIcon from "@mui/icons-material/Add"

import ReferralGrid from "../components/referralGrid";
import BannerPatient from "../components/bannerPatient";
import NavAppBar from "../components/navAppBar";
import "../config";
import { PatientListContainer } from "./patientList";

const BannerBox = styled.div`
  background: rgb(234, 235, 242);
`

export const ReferralListContainer = styled.div`
  height: 90vh; 
  display: flex; 
  flex-direction: column; 
  flex:1;

  .title-bar {
    .title {
      margin-right: 0;
    }
  }
`

const ReferralList = () => {
  const cfg = global.config.restAPI; //"http://localhost:3000/";
  const [referrals, setReferrals] = useState([]);
  const [patient, setPatient] = useState([]);

  const navigate = useNavigate();
  const { patientId } = useParams();

  useEffect(() => {
    console.log(`Get patientId:${patientId}`)
    // Fetch patient data for the patient ID frbm the URL params
    axios.get(`${cfg.url + cfg.endpoints.patients}/?q={"internal_patient_id":{"$eq":"${patientId}"}}`).then((response) => {
      const firstPatient = response.data.items[0];
      setPatient(firstPatient);
    });
    // Fetch referrals for the patient ID frbm the URL params
    axios
      //.get(`${cfg.url +cfg.endpoints.referrals}/?patient_patientId=${patientId}`)
      .get(`${cfg.url +cfg.endpoints.referrals}/?q={"internalpatientid":{"$eq":"${patientId}"}}`)
      .then((response) => {
        const allItems = response.data.items;
        setReferrals(allItems);
      });
  }, [patientId, cfg]);


  return (
    <ReferralListContainer>
      <NavAppBar title="Referrals">
        <Button
          variant="outlined"
          onClick={() => navigate(`/referral/patient/${patientId}/create`)}
       startIcon={<AddIcon />} >
          Referral
        </Button>
      </NavAppBar>
      <BannerBox>
      <BannerPatient patientId={patientId} patientRec={patient} onSelect={() => navigate(`../patient/${patientId}`)}/>
      </BannerBox>
      <PatientListContainer style={{ flex: 1, overflow: "scroll" }}>
        <ReferralGrid referrals={referrals} onSelect={(referralId) => navigate(`../patient/${patientId}/referral/${referralId}`)}/>
      </PatientListContainer>
    </ReferralListContainer>
  );
};

export default ReferralList;
