import React, { useState } from "react";
import { DataGrid } from "@mui/x-data-grid";

const columns = [
  { field: "pasid", headerName: "PAS ID", width: 100 },
  { field: "patientname", headerName: "Patient", width: 230 },
  { field: "intentedoperationtime", headerName: "Time", width: 80 },
  {
    field: "sessiondate",
    headerName: "Date",
    width: 100,
    type: "date",
    valueGetter: (params) => {
      if (!params || !params.value) return;
      const p = params.value.split("/");
      const d = { day: 0, month: 1, year: 2 };
      const date = new Date(p[d.year], p[d.month] - 1, p[d.day]);
      return date;
    },
  },
  { field: "specialty", headerName: "Specialty", width: 150 },
  { field: "arrivallocationtypecode", headerName: "From", width: 120 },
  { field: "mainprocedure", headerName: "Procedure", width: 120 },
  { field: "intendedoperationduration", headerName: "Duration", width: 100 },
  { field: "subsidaryprocedure1", headerName: "Sub Proc", width: 120 },
];

const TheatreGrid = ({ records, onSelect }) => {
  const handleRowClick = (params) => {
    console.log("Selected Row ID:", params.id);
    console.log("Selected Row Data:", params);
    onSelect(params.id, params.row);
  };

  return (
    <DataGrid
      rows={records}
      columns={columns}
      pageSize={10}
      rowsPerPageOptions={[10, 25, 50, 100]}
      pagination
      autoHeight
      onRowClick={handleRowClick}
      getRowId={(row) =>{
return `${row.internalpatientid}-${row.theatresessioncode}-${row.sessiondate}`}}
      columnHeaderHeight={25}
    />
  );
};

export default TheatreGrid;
