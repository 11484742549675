// pages/WaitlistMgr.js
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useParams, useNavigate } from "react-router-dom";
import { Button } from "@mui/material";

import WaitlistTabOutpatient from "../components/waitlist/waitlistTabOutpatient";
import WaitlistTabTheatre from "../components/waitlist/waitlistTabTheatre";
import "../config";
import {MainContainer, FormContainer, Tab} from "../components/containers";

const WaitlistMgr = () => {
  const title = "Waitlist Management";
  const [tab, setTab] = useState("Outpatient");

  return (
    <MainContainer>
      <FormContainer className={["alt"]}>
        <h2>{title}</h2>
        <Tab
          className={tab === "Outpatient" ? ["selected"] : []}
          onClick={() => setTab("Outpatient")}
        >
          <div>Outpatient</div>
          <div>Waitlist</div>
        </Tab>
        <Tab
          className={tab === "Theatre" ? ["selected"] : []}
          onClick={() => setTab("Theatre")}
        >
          <div>Inpatient</div>
          <div>Waitlist</div>
        </Tab>
      </FormContainer>
      {tab === "Outpatient" && (
        <WaitlistTabOutpatient  />
      )}
      {tab === "Theatre" && (
        <WaitlistTabTheatre />
      )}
    </MainContainer>
  );
};

export default WaitlistMgr;
