// ClinicTabBookings.js
import React, { useState, useEffect } from "react";
import axios from "axios";
import styled from "styled-components";
import { useParams, useNavigate } from "react-router-dom";
import { Button, FormControl, MenuItem, Select } from "@mui/material";
import XButtonGroup from "../../components/xButtonGroup";
import AddIcon from "@mui/icons-material/Add";
import WaitlistGrid from "../../components/waitlist/waitlistGrid";
import SearchByTypesForm from "../../components/searchByTypesForm";
import PlaylistAddCheckIcon from "@mui/icons-material/PlaylistAddCheck";
import "../../config";
import {
  FormContainer,
  MainContainer,
  GridContainer,
} from "../../components/containers";

const WaitlistTabOutpatient = ({ tab, onViewModeChange }) => {
  const [waitlist, setWaitlist] = useState();
  const cfg = global.config.restAPI;
  const navigate = useNavigate();

  useEffect(() => {
    // Fetch everything to start with
    const query = { entrystatuscode: { $eq: "A" } }; // Filter out closed and removed
    axios
      .get(
        `${cfg.url + cfg.endpoints.waitlistEntries}/?q=${JSON.stringify(query)}`,
      )
      .then((response) => {
        setWaitlist(response.data.items);
      });
  }, [cfg]);

  const handleSelect = (id, rowData) => {
    navigate(
      `/waitlist/patient/${rowData.internalpatientid}/referral/${rowData.referralnumber}/${rowData.episodenumber}`,
    );
  };

  const handleFormSubmit = (formData) => {
    // Filtering out empty values from formData
    const nonEmptyFormData = Object.fromEntries(
      Object.entries(formData).filter(([key, value]) => {
        return value && value !== "";
      }),
    );

    const query = { entrystatuscode: { $eq: "A" } }; //new URLSearchParams(nonEmptyFormData).toString();
    for (const [key, value] of Object.entries(nonEmptyFormData)) {
      if (key.includes("date")) {
        query[key] = { $gt: { $date: value.utc() } };
      } else if (key.includes("patientnameupper"))
        query[key] = { $instr: value.toUpperCase() };
      else query[key] = { $eq: value };
    }

    // Fetch from the API
    axios
      .get(
        `${cfg.url + cfg.endpoints.waitlistEntries}/?q=${JSON.stringify(query)}`,
      )
      .then((response) => {
        setWaitlist(response.data.items);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <MainContainer>
      <FormContainer>
        <div className={["flex1"]}>
          <SearchByTypesForm
            title=""
            onSubmit={handleFormSubmit}
            types={[
              "clinic",
              "clinicGroup",
              "specialty",
              "clinicgroup",
              "appointmentprioritycode",
              "currentdateonlistsearchable",
              "expectedbookingsystemcode",
            ]}
          >
            <Button
              className="search-btn"
              type="primary"
              variant="outlined"
              startIcon={<AddIcon />}
              onClick={() => navigate(`/waitlist/create`)}
            >
              Waitlist Entry
            </Button>
          </SearchByTypesForm>
        </div>
      </FormContainer>
      {waitlist && (
        <GridContainer style={{}}>
          <WaitlistGrid records={waitlist} onSelect={handleSelect} />
        </GridContainer>
      )}
      {!waitlist && <p style={{ marginLeft: 24 }}>Loading...</p>}
    </MainContainer>
  );
};

export default WaitlistTabOutpatient;
