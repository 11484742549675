// pages/ReferralMgr.js
import React, { useState, useEffect } from "react";
import axios from "axios";
import styled from "styled-components";
import { useParams, useNavigate } from "react-router-dom";
import { Button } from "@mui/material";

import AddIcon from "@mui/icons-material/Add";
import ReferralGrid from "../components/referralGrid";
import SearchByTypesForm from "../components/searchByTypesForm";
import "../config";

const FormContainer = styled.div`
  background-color: #f4f4f4;
`;

const PatientSearchContainer = styled.div`
  height: 90vh;
  width: 100vw;

  display: flex;
  flex-direction: column;
  flex: 1;

  .title {
    background-color: #4a4c52;
    height: 40px;
    text-align: center;

    h2 {
      margin: 8px;
      color: #ffffff;
      font-size: 18px;
    }
  }

  .search-btn {
    margin-top: 4px;
  }
`;

export const ReferralMgrContainer = styled.div`
  .MuiDataGrid-columnHeaders {
    background-color: #ebf3fc;

    .MuiDataGrid-columnHeader {
      border-right: 1px solid #ededed;
    }
  }

  .MuiDataGrid-cell {
    border-right: 1px solid #ededed;
  }

  .MuiDataGrid-row {
    &:nth-child(even) {
      background-color: #fafafa;
    }
  }
  flex: 1;
  overflow: scroll;
`;

const fakeUBRN = (referrals) => {
  const newReferrals = referrals.map((obj) => {
    if (obj.referralmethodcode === "E") {
      //Electronic
      const randomNumber =
        Math.floor(Math.random() * (99999999 - 10000000 + 1)) + 10000000;
      const randomCode = randomNumber.toString();

      // Pad the first three digits with zeros
      const paddedRandomCode = "00009000" + randomCode;

      // Take only the last 8 digits
      return { ...obj, ubrn: paddedRandomCode.slice(0, 12) };
    }
    return obj;
  });
  return newReferrals;
};

const ReferralMgr = () => {
  const [referrals, setReferrals] = useState();
  const cfg = global.config.restAPI;
  const navigate = useNavigate();
  const { types } = useParams();

  useEffect(() => {
    // Fetch everything to start with
    const query = { status: { $eq: "Open" } }; //new URLSearchParams(nonEmptyFormData).toString();
    axios
      .get(`${cfg.url + cfg.endpoints.referrals}/?q=${JSON.stringify(query)}`)
      .then((response) => {
        setReferrals(fakeUBRN(response.data.items)); // Fake UBRN with method is electronic
        //setReferrals(response.data.items);
      });
  }, [cfg]);

  const handleSelect = (id, rowData) => {
    navigate(`../patient/${rowData.internalpatientid}/referral/${id}`);
  };

  const handleFormSubmit = (formData) => {
    const nonEmptyFormData = Object.fromEntries(
      Object.entries(formData).filter(([key, value]) => {
        return value && value !== "";
      }),
    );

    const query = { status: { $eq: "Open" } };
    for (const [key, value] of Object.entries(nonEmptyFormData)) {
      if (key.includes("date")) query[key] = { $gt: { $date: value.utc() } };
      else if (key.includes("patientnameupper"))
        query[key] = { $instr: value.toUpperCase() };
      else query[key] = { $eq: value };
    }

    // Fetch from the API
    axios
      .get(`${cfg.url + cfg.endpoints.referrals}/?q=${JSON.stringify(query)}`)
      .then((response) => {
        setReferrals(response.data.items);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <PatientSearchContainer>
      <FormContainer>
        <SearchByTypesForm
          title="Referral Management"
          onSubmit={handleFormSubmit}
          types={[
            "referredtospecialty",
            "referraldatesearchable",
            "referralpriority",
            "status",
            "suspectedCancer",
            "clinic",
            "clinicGroup",
          ]}
        >
          <Button
            className="search-btn"
            type="primary"
            variant="outlined"
            startIcon={<AddIcon />}
            onClick={() => navigate("../referral/create/")}
          >
            Referral
          </Button>
        </SearchByTypesForm>
      </FormContainer>
      {referrals && (
        <ReferralMgrContainer style={{}}>
          <ReferralGrid referrals={referrals} onSelect={handleSelect} />
        </ReferralMgrContainer>
      )}
      {!referrals && <p style={{ marginLeft: 24 }}>Loading...</p>}
    </PatientSearchContainer>
  );
};

export default ReferralMgr;
