// pages/TheatreMgr.js
import React, { useState, useEffect } from "react";
import axios from "axios";
import styled from "styled-components";
import { useParams, useNavigate } from "react-router-dom";
import {
  Drawer,
  Button,
  FormControl,
  MenuItem,
  Select,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Collapse,
} from "@mui/material";
import {
  ArrowDropDown,
  ArrowDropUp,
  ArrowRight,
  AirlineSeatFlat,
  ExpandMore,
  ExpandLess,
} from "@mui/icons-material";
import XButtonGroup from "../components/xButtonGroup";
import AddIcon from "@mui/icons-material/Add";
import AppointmentGrid from "../components/appointmentGrid";
import TheatreSlotGrid from "../components/theatre/theatreSlotGrid";
import TheatreCalendar from "../components/theatre/theatreCalendar";
import TheatreTabBookings from "../components/theatre/theatreTabBookings";
import TheatreTabAvailability from "../components/theatre/theatreTabAvailability";
import TheatreTabCalendar from "../components/theatre/theatreTabCalendar";
import PlaylistAddCheckIcon from "@mui/icons-material/PlaylistAddCheck";
import "../config";

const FormContainerXX = styled.div`
  background-color: #fcf9f9; //f4f4f4; //gray
  display: flex;

  &.alt {
    background-color: #3b4e6b; //darkblue
    height: 58px;
    display: flex;
    padding-left: 20px;
    color: white;
    justify-content: flex-start;
    align-items: flex-end;
  }
  & h2 {
    padding-right: 25px;
    font-size: 18px;
  }

  align-items: center;

  & div.flex1 {
    flex: 1;
  }
  & div.view-mode {
    padding-left: 20px;
  }
`;

export const FormContainer = styled.div`
  background-color: #fcf9f; //9f4f4f4; //gray
  display: flex;

  &.alt {
    background-color: #3b4e6b; //darkblue
    height: 58px;
    display: flex;
    padding-left: 20px;
    color: white;
    justify-content: flex-start;
    align-items: flex-end;
  }
  & h2 {
    padding-right: 25px;
    font-size: 18px;
  }

  align-items: center;

  & div.flex1 {
    flex: 1;
  }
  & div.view-mode {
    padding-left: 20px;
  }
`;

const Tab = styled.div`
  color: black;
  height: 49px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  color: rgb(213 213 213); // white tab text;
  &.selected {
    background: rgb(205, 205, 205); //gray
    background: linear-gradient(
      180deg,
      rgba(205, 205, 205, 1) 0%,
      rgba(224, 224, 224, 1) 17%,
      rgba(244, 244, 244, 1) 100%
    );
    color: #323232; //black text;
  }
  &:hover:not(.selected) {
    color: white; //#323232; //black text;
  }
  & div {
    padding-left: 15px;
    padding-right: 15px;
    font-size: 13px;
    font-weight: bold;
  }
  & div:last-child {
    padding-bottom: 5px;
  }
`;

const MainContainer = styled.div`
  height: 90vh;
  width: 100vw;

  display: flex;
  flex-direction: row;
  flex: 1;

  .title {
    background-color: #4a4c52;
    height: 40px;
    text-align: center;

    h2 {
      margin: 8px;
      color: #ffffff;
      font-size: 18px;
    }
  }

  .search-btn {
    margin-top: 4px;
    margin-left: 14px;
  }
`;

export const TheatreMgrContainer = styled.div`
  .MuiDataGrid-columnHeaders {
    background-color: #ebf3fc;

    .MuiDataGrid-columnHeader {
      border-right: 1px solid #ededed;
    }
  }

  .MuiDataGrid-cell {
    border-right: 1px solid #ededed;
  }

  .MuiDataGrid-row {
    &:nth-child(even) {
      background-color: #fafafa;
    }
  }
  overflow: scroll;
`;
const TheatreMenu = styled.div`
  background: #f2f2f2;
  border-right: 1px solid #d3d0d0;
  width: 13vw;
  padding-top: 10px;
  overflow-y: scroll;
`;
const Box = styled.div`
  width: 100%;
`;

const getSort = (code, desc) => {
  if (desc.startsWith("THEATRE")) {
    return 1;
  } else if (code.startsWith("DEMO")) {
    return 2;
  } else {
    return 3;
  }
};
// Function to simplify session data into a list of location codes and session codes
const makeTheatreList = (sessions) => {
  const newData = {};
  sessions.forEach(
    ({
      theatrelocationcode,
      theatrelocationdescription,
      theatresessioncode,
    }) => {
      if (!newData[theatrelocationcode]) {
        newData[theatrelocationcode] = {
          code: theatrelocationcode,
          desc: theatrelocationdescription,
          sessions: [],
          sort: getSort(theatrelocationcode, theatrelocationdescription),
        };
      } else if (
        !newData[theatrelocationcode].sessions.includes(theatresessioncode)
      )
        newData[theatrelocationcode].sessions.push(theatresessioncode);
    },
  );
  return newData;
};

const TheatreMgr = () => {
  const cfg = global.config.restAPI; //"http://localhost:3000/";
  const navigate = useNavigate();
  const { types, view } = useParams();
  const title = "Session Management";
  const [tab, setTab] = useState("MAINT");
  const [openBranch, setOpenBranch] = useState("T2");
  const [node, setNode] = useState();
  const [sessionFilter, setSessionFilter] = useState({
    theatrelocationcode: "T2",
    theatresessioncode: "NEUROEP",
  });
  const [viewMode, setViewMode] = useState(view ? view : "Availability");
  const [theatres, setTheatres] = useState();

  const handleViewModeChange = (e) => {
    setViewMode(e.target.value);
    navigate("/theatre/mgr/" + e.target.value);
  };

  useEffect(() => {
    // Fetch theatre list data
    axios
      .get(`${cfg.url + cfg.endpoints.theatre.locationsList}`)
      .then((response) => {
        const list = response.data.items;
        const tree = makeTheatreList(list);
        const sorted = Object.entries(tree).sort(
          (a, b) => a[1].sort - b[1].sort,
        ); // &&  a[1].desc> b[1].desc)

        setTheatres(sorted);
      });
  }, [cfg]);

  if (!theatres) {
    return <p style={{ marginLeft: 24 }}>Loading...</p>;
  }

  return (
    <MainContainer>
      <TheatreMenu>
        <Box>
          {theatres.map(([theatrelocationcode, location], index) => {
            return (
              <List>
                <ListItem
                  key={index}
                  disablePadding
                  selected={openBranch === theatrelocationcode}
                >
                  <ListItemButton
                    onClick={() => {
                      setSessionFilter({
                        theatrelocationcode: theatrelocationcode,
                      });
                      setOpenBranch(theatrelocationcode);
                    }}
                  >
                    <ListItemText primary={location.desc} />
                    {openBranch === theatrelocationcode ? (
                      <ExpandMore />
                    ) : (
                      <ExpandLess />
                    )}
                  </ListItemButton>
                </ListItem>
                <Collapse
                  in={openBranch === theatrelocationcode}
                  timeout="auto"
                  unmountOnExit
                >
                  {location.sessions.map((session, idx) => {
                    const selected =
                      session === sessionFilter.theatresessioncode;
                    return (
                      <ListItem key={idx} selected={selected}>
                        <ListItemButton
                          onClick={() =>
                            setSessionFilter({ theatresessioncode: session })
                          }
                        >
                          <ListItemText primary={session} />
                        </ListItemButton>
                        {selected ? <ArrowRight /> : null}
                      </ListItem>
                    );
                  })}
                </Collapse>
              </List>
            );
          })}
        </Box>
      </TheatreMenu>

      {viewMode === "Bookings" && (
        <TheatreTabBookings
          tab={tab}
          sessionFilter={sessionFilter}
          onViewModeChange={handleViewModeChange}
        />
      )}
      {viewMode === "Availability" && (
        <TheatreTabAvailability
          tab={tab}
          sessionFilter={sessionFilter}
          onViewModeChange={handleViewModeChange}
        />
      )}
      {viewMode === "Calendar" && (
        <TheatreTabCalendar
          tab={tab}
          sessionFilter={sessionFilter}
          onViewModeChange={handleViewModeChange}
        />
      )}
    </MainContainer>
  );
};

export default TheatreMgr;
