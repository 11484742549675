module.exports = global.config = {
  miya: {
    demoRecord: `https://jade.demo.miya.cloud/MiyaFlow/0884b5d2-3deb-4983-b695-780493b59dd9/Ward/22b674b7-79d5-4283-8468-d5a2e46425de/Patient/0116282a-564e-58e7-a957-f0be3659afaf/Overview`,
    demoClinic: `https://jade.demo.miya.cloud/MiyaFlow/0884b5d2-3deb-4983-b695-780493b59dd9/Clinic/43bae624-2d40-4082-ab85-a44f14d108bb`,
    demoTheatre: `https://jade.demo.miya.cloud/MiyaFlow/0884b5d2-3deb-4983-b695-780493b59dd9/Theatre/c48d0de1-5038-4eb1-986e-2b2ab4059524`,
  },
  restAPI: {
    url: "https://g692a18a514da6f-pasdemo.adb.uk-london-1.oraclecloudapps.com/ords/silver/api",
    endpoints: {
      patients: "/core/patients",
      referrals: "/outpatients/referral",
      waitlistEntries: "/outpatients/waitlistentries",
      clinicSessions: "/outpatients/clinicsessionlookup",
      clinicSessionSlots: "/outpatients/clinicsessionslots",
      rtt: "/outpatients/rttpathway",
      wardattender: "/outpatients/wardattender",
      appointments: "/outpatients/appointment",
      wardAttenders: "outpatients/wardattender/",
      theatre: {
        consumableslist: "/theatre/consumableslist",
        equipmentslist: "/theatre/equipmentslist",
        locationsList: "/theatre/locations",
        sessions: "/theatre/sessions",
        operations: "/theatre/operations",
        preopdetails: "/theatre/preopdetails",
        waitlistdefinition: "/theatre/waitinglistdefinition",
        waitlistentry: "/theatre/waitinglistentry",
        procedure: `/core/procedurecodes/`,
      },
      icdcodes: `/core/icdcodes/`,
      lookupCode: {
        referringSourceCode: `/core/lookupcode/?q={"domain":{"$eq":"lib003"},"sys":{"$eq":"out"},"ulnkey":{"$eq":"SREF"}}`,
        referralMethodCode: `/core/lookupcode/?q={"domain":{"$eq":"lib003"},"sys":{"$eq":"out"},"ulnkey":{"$eq":"MREF"}}`,
        referralReason: `/core/lookupcode/?q={"domain":{"$eq":"lib003"},"sys":{"$eq":"out"},"ulnkey":{"$eq":"RREF"}}`,
        referralPriority: `/core/lookupcode/?q={"domain":{"$eq":"lib003"},"sys":{"$eq":"out"},"ulnkey":{"$eq":"REFP"}}`,
        attendanceType: `/core/lookupcode/?q={"domain":{"$eq":"lib003"},"sys":{"$eq":"cli"},"ulnkey":{"$eq":"PTAT"}}`,
        appointmentType: `/core/lookupcode/?q={"domain":{"$eq":"lib003"},"sys":{"$eq":"out"},"ulnkey":{"$eq":"APTYP"}}`,
        patientCategory: `/core/lookupcode/?q={"domain":{"$eq":"lib003"},"sys":{"$eq":"apk"},"ulnkey":{"$eq":"CAT"}}`,
        searchType: `/core/lookupcode/?q={"domain":{"$eq":"lib003"},"sys":{"$eq":"out"},"ulnkey":{"$eq":"SRCT"}}`,
        appointmentSlotType: `/core/lookupcode/?q={"domain":{"$eq":"lib003"},"sys":{"$eq":"cli"},"ulnkey":{"$eq":"CLITY"}}`,
        appointmentPriority: `/core/lookupcode/?q={"domain":{"$eq":"lib003"},"sys":{"$eq":"cli"},"ulnkey":{"$eq":"PTUR"}}`,
        appointmentStatus: `/core/lookupcode/?q={"domain":{"$eq":"lib003"},"sys":{"$eq":"out"},"ulnkey":{"$eq":"APST"}}`,
        searchLevel: `/core/lookupcode/?q={"domain":{"$eq":"lib003"},"sys":{"$eq":"cli"},"ulnkey":{"$eq":"SRLEV"}}`,
        interpreterRequired: `/core/lookupcode/?q={"domain":{"$eq":"lib003"},"sys":{"$eq":"crs"},"ulnkey":{"$eq":"INTER"}}`,
        searchMethod: `/core/lookupcode/?q={"domain":{"$eq":"lib003"},"sys":{"$eq":"out"},"ulnkey":{"$eq":"SDTF"}}`,
        reasonForAppointment: `/core/lookupcode/?q={"domain":{"$eq":"lib003"},"sys":{"$eq":"out"},"ulnkey":{"$eq":"APPRS"}}`,
        bookingSystem: `/core/lookupcode/?q={"domain":{"$eq":"lib003"},"sys":{"$eq":"out"},"ulnkey":{"$eq":"EBS"}}`,
        staffGrade: `/core/lookupcode/?q={"domain":{"$eq":"lib003"},"sys":{"$eq":"out"},"ulnkey":{"$eq":"SG"}}`,
        patientType: `/core/lookupcode/?q={"domain":{"$eq":"lib003"},"sys":{"$eq":"out"},"ulnkey":{"$eq":"ATYPE"}}`,
        outcome: `/core/lookupcode/?q={"domain":{"$eq":"lib003"},"sys":{"$eq":"out"},"ulnkey":{"$eq":"WOC"}}`,
        bookedType: `/core/lookupcode/?q={"domain":{"$eq":"lib003"},"sys":{"$eq":"cli"},"ulnkey":{"$eq":"SLBKD"}}`,
        suspectedCancer: `/core/lookupcode/?q={"domain":{"$eq":"lib003"},"sys":{"$eq":"out"},"ulnkey":{"$eq":"CTYPE"}}`,
        sessionType: `/core/lookupcode/?q={"domain":{"$eq":"lib005"},"sys":{"$eq":"opt"},"ulnkey":{"$eq":"STYPE"}}`,
        arriveFrom: `/core/lookupcode/?q={"domain":{"$eq":"lib003"},"sys":{"$eq":"opt"},"ulnkey":{"$eq":"A-LOC"}}`,
        operationType: `/core/lookupcode/?q={"domain":{"$eq":"lib003"},"sys":{"$eq":"opt"},"ulnkey":{"$eq":"OT"}}`,
        severity: `/core/lookupcode/?q={"domain":{"$eq":"lib003"},"sys":{"$eq":"opt"},"ulnkey":{"$eq":"SEV"}}`,
        anaestheticType: `/core/lookupcode/?q={"domain":{"$eq":"lib005"},"sys":{"$eq":"wlm"},"ulnkey":{"$eq":"ANAES"}}`,
        anaestheticStatus: `/core/lookupcode/?q={"domain":{"$eq":"lib003"},"sys":{"$eq":"opt"},"ulnkey":{"$eq":"AS"}}`,
        procedureSide: `/core/lookupcode/?q={"domain":{"$eq":"lib005"},"sys":{"$eq":"wlm"},"ulnkey":{"$eq":"SIDE"}}`,
        theatrePriority: `/core/lookupcode/?q={"domain":{"$eq":"lib003"},"sys":{"$eq":"wlm"},"ulnkey":{"$eq":"REFP"}}`,
        inpatientPriority: `/core/lookupcode/?q={"domain":{"$eq":"lib003"},"sys":{"$eq":"wlm"},"ulnkey":{"$eq":"REFP"}}`,
        admissionMethod: `/core/lookupcode/?q={"domain":{"$eq":"lib003"},"sys":{"$eq":"apk"},"ulnkey":{"$eq":"MA"}}`,

      },
      profDets: {
        gp: `/core/profdets/?q={"obj_type":{"$eq":"GMP"}}`,
        consultant: `/core/profdets/?q={"obj_type":{"$eq":"CO"},`, //"C_SPEC":{"$eq":"CARD"}}`,
      },
      discipDets: {
        specialty: `/core/discipdets/?q={"obj_type":{"$eq":"SP"}}`,
        clinicGroups: `/core/discipdets/?q={"obj_type":{"$eq":"CCG"}}`,
      },
      orgDets: {
        referringSource: `/core/orgdets/`,
      },
    },
  },
};
