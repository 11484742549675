import React, { useState, useEffect } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { InputLabel } from "@mui/material";
import axios from "axios";
import "../config";

const ComboWaitlistRef = ({ label, value, error, onChange }) => {
  const [data, setData] = useState([]);
  const cfg = global.config.restAPI;

  useEffect(() => {
    const query = `${cfg.url + cfg.endpoints.theatre.waitlistdefinition}`;
    const fetchData = async () => {
      axios.get(query).then((response) => {
        setData(response.data.items);
      });
    };

    if (cfg.endpoints.theatre.waitlistdefinition) fetchData();
  }, [cfg]);

  return (
    <Autocomplete
      isOptionEqualToValue={(option, value) => option.list_id === value}
      onChange={(e, newData) => {
        const val = newData ? newData.list_id : null;
        onChange(val);
      }}
      options={data}
      getOptionLabel={(o) => {
        if (o === "ALL") return "All Reference Lists";
        else if (o.waitinglistdescription)
          return `${o.waitinglistdescription} - ${o.list_id} `;
        else return o;
      }}
      style={{ width: 300 }}
      autoHighlight={true}
      value={value}
      renderInput={(params) => (
        <React.Fragment>
          <InputLabel className="input-label">{label}</InputLabel>
          <TextField
            {...params}
            placehoder="All"
            variant="outlined"
            error={error ? true : false}
            helperText={error}
            InputLabelProps={{ shrink: false }}
            style={{ marginTop: 0 }}
          />
        </React.Fragment>
      )}
      size="small"
    />
  );
};

export default ComboWaitlistRef;
