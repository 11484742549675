import React, { useState, useEffect } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import axios from "axios";
import "../config";
import { InputLabel } from "@mui/material";

const ComboLookupCode = ({ label, refDomain, value, error, onChange }) => {
  const [data, setData] = useState([]);
  const cfg = global.config.restAPI;

  useEffect(() => {
    const fetchData = async () => {
      axios
        .get(`${cfg.url + cfg.endpoints.lookupCode[refDomain]}`)
        .then((response) => {
          setData(response.data.items);
        });
    };

    if (cfg.endpoints.lookupCode[refDomain]) fetchData();
    //    else

    //throw(new Error(`Can't find ${refDomain} in config.js`));
  }, [cfg, refDomain]);

  return (
    <Autocomplete
      isOptionEqualToValue={(option, value) => option.code === value}
      onChange={(e, newData) => {
        const val = newData ? newData.code : null;
        onChange(val);
      }}
      options={data}
      getOptionLabel={(option) =>
        //toTitleCase(option.ldesc ? option.ldesc : option.descript)
        option.ldesc ? option.ldesc : option.descript
      }
      style={{ width: 300 }}
      renderInput={(params) => (
        <React.Fragment>
          <InputLabel className="input-label">{label}</InputLabel>
          <TextField
            {...params}
            placehoder={label}
            variant="outlined"
            error={error ? true : false}
            helperText={error}
            InputLabelProps={{ shrink: false }}
            style={{ marginTop: 0 }}
          />
        </React.Fragment>
      )}
      size="small"
    />
  );
};

export default ComboLookupCode;
