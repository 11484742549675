import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import {
  Button,
  FormControl,
  Select,
  MenuItem,
  Box,
  FormControlLabel,
  Checkbox,
  InputLabel,
} from "@mui/material";

export default function DialogReasonQuestion({
  title,
  desc,
  reasons,
  open,
  onClose,
}) {
  const [ reason, setReason ] = useState();
  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {desc}
            <FormControl fullWidth>
              <InputLabel id="reason-select-label">Reason</InputLabel>
              <Select
                labelId="reason-select-label"
                id="reason-select"
                value={reason}
                label="Reason"
                onChange={(val) => setReason(val)}
              >
                {reasons.map((val, idx) => (
                  <MenuItem value={idx}>{val}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => onClose(false)}>Cancel</Button>
          <Button onClick={() => onClose(true)} autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
