import React, { useState } from "react";
import { Button } from "@mui/material";
import styled from "styled-components";

const ButtonGroup = styled.div`
  width: 700px;
  display: flex;
  & div {
    flex: 1;
    padding: 5px 10px;
  }
`;

function MonthPicker({ selectedMonth, handleMonthChange }) {
  const [formData, setFormData] = useState({});

  const months = [
    { value: 1, label: "January" },
    { value: 2, label: "February" },
    { value: 3, label: "March" },
    { value: 4, label: "April" },
    { value: 5, label: "May" },
    { value: 6, label: "June" },
    { value: 7, label: "July" },
    { value: 8, label: "August" },
    { value: 9, label: "September" },
    { value: 10, label: "October" },
    { value: 11, label: "November" },
    { value: 12, label: "December" },
  ];

  return (
    <div>
      <ButtonGroup>
        {months.slice(0, 4).map((month, idx) => (
          <div>
            <Button
              key={month.value}
              onClick={() =>
                setFormData({ ...formData, [idx + 0]: !formData[idx + 0] })
              }
              variant={formData[idx + 0] ? "outlined" : ""}
              fullWidth={true}
            >
              {month.label}
            </Button>
          </div>
        ))}
      </ButtonGroup>
      <ButtonGroup>
        {months.slice(4, 8).map((month, idx) => (
          <div>
            <Button
              key={month.value}
              onClick={() =>
                setFormData({ ...formData, [idx + 4]: !formData[idx + 4] })
              }
              variant={formData[idx + 4] ? "outlined" : ""}
              fullWidth={true}
            >
              {month.label}
            </Button>
          </div>
        ))}
      </ButtonGroup>
      <ButtonGroup>
        {months.slice(8, 12).map((month, idx) => (
          <div>
            <Button
              key={month.value}
              onClick={() =>
                setFormData({ ...formData, [idx + 8]: !formData[idx + 8] })
              }
              variant={formData[idx + 8] ? "outlined" : ""}
              fullWidth={true}
            >
              {month.label}
            </Button>
          </div>
        ))}
      </ButtonGroup>
    </div>
  );
}

function DayOfWeekPicker({ selectedDay, handleDayChange }) {
  const [formData, setFormData] = useState({});
  const daysOfWeek = [
    { value: "sunday", label: "Sunday" },
    { value: "monday", label: "Monday" },
    { value: "tuesday", label: "Tuesday" },
    { value: "wednesday", label: "Wednesday" },
    { value: "thursday", label: "Thursday" },
    { value: "friday", label: "Friday" },
    { value: "saturday", label: "Saturday" },
  ];

  return (
    <ButtonGroup >
      {daysOfWeek.map((day,idx) => (
  <div>
        <Button
          key={day.value}
              onClick={() =>
                setFormData({ ...formData, [idx + 0]: !formData[idx + 0] })
              }
              variant={formData[idx + 0] ? "outlined" : ""}
              fullWidth={true}
        >
          {day.label}
        </Button>
</div>
      ))}
    </ButtonGroup>
  );
}

export { MonthPicker, DayOfWeekPicker };
