import React, { useState } from "react";
import { DataGrid } from "@mui/x-data-grid";

const columns = [
  {
    field: "pasid",
    headerName: "PAS Id",
  },
  {
    field: "patientname",
    headerName: "Patient Name",
    width: 200,
  },
  {
    field: "referringsourcedescription",
    headerName: "Source of Referral",
    width: 150,
  },
  {
    field: "referraldate",
    headerName: "Referral Date",
    width: 120,
    type: 'date',
    valueFormatter: (params) => {return params.value.toLocaleDateString('en-GB')},   
    valueGetter: (params) => {
      if(!params || !params.value) return;
      const p= params.value.split('/');
      const d={day:0,month:1,year:2}
      const date = new Date(p[d.year],p[d.month]-1,p[d.day]);
      return date;
    },
  },
  {
    field: "usrcodec_1",
    headerName: "Clinic",
    width: 100,
  },
  {
    field: "ubrn",
    headerName: "UBRN",
    width: 120,
  },
  {
    field: "referredtospecialty",
    headerName: "Specialty",
    width: 100,
  },
  {
    field: "referrertype",
    headerName: "Referrer Type",
    width: 100,
  },
  {
    field: "referralpriority",
    headerName: "Priority",
    width: 80,
  },
  {
    field: "status",
    headerName: "Status",
    width: 80,
  },
  {
    field: "referralreason",
    headerName: "Reason",
    width: 80,
  },
  {
    field: "referralmethoddescription",
    headerName: "Referral Method",
    width: 200,
  },
];

const Referral = ({ referrals, onSelect }) => {
  const [selectedRowIds, setSelectedRowIds] = useState([]);

  const handleRowClick = (params) => {
    console.log("Selected Row ID:", params.id);
    console.log("Selected Row Data:", params);
    onSelect(params.id, params.row);
  };
  return (
    <DataGrid
      rows={referrals}
      columns={columns}
      pageSize={10}
      rowsPerPageOptions={[10, 25, 50, 100]}
      pagination
      autoHeight
      onRowClick={handleRowClick}
      getRowId={(row) => row.referralnumber}
      columnHeaderHeight={25}
    />
  );
};

export default Referral;
