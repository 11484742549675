/* bookingForm.js - Theatre Booking Form*/
import React, { useState } from "react";
import {
  Autocomplete,
  TextField,
  Button,
  Box,
  FormControlLabel,
  Checkbox,
  InputLabel,
  Collapse,
  Alert,
} from "@mui/material";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";

import NavAppBar from "../components/navAppBar";
import ComboLookupCode from "../components/comboLookup";
import ComboSpecialty from "../components/comboSpecialty";
import ComboStaticList from "../components/comboStaticList";
import ComboProfDets from "../components/comboProfDets";
import ComboProcedure from "../components/comboProcedure";
import styled from "styled-components";
import {
  Footer,
  Form,
  Row,
  Column,
  SectionTitle,
} from "../components/formLayout";
import "../config";
import InfoSnackbar from "../components/infoSnackbar";
import DialogQuestion from "../components/dialogQuestion";
import BannerReferral from "../components/bannerReferral";
import BannerPatient from "../components/bannerPatient";
import SessionSelector from "../components/theatre/sessionSelector";
import AppointmentPrint from "../components/appointmentPrint";
import PatientSelector from "../components/patientSelector";
import ConsumablesSelector from "../components/theatre/consumablesSelector";
import EquipmentSelector from "../components/theatre/equipmentSelector";
import XCheckbox from "../components/xCheckbox";

const BoxButtons = styled.div`
  display: flex;
  flex-direction: row-reverse;
  background-color: #4a4c52;
  & button {
    margin: 10px;
  }
`;

const BookingContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  .input-label {
    font-size: 12px;
  }
`;

const SearchSection = styled.div`
  border: 1px solid rgb(224, 224, 224);
  margin: 10px 0;
  padding: 10px 0;
`;

const BookingForm = () => {
  const navigate = useNavigate();
  const { patientId, sessionCode, sessionDate } = useParams("");

  const [formData, setFormData] = useState({
    internalPatientId: patientId,
    sessionCode: sessionCode,
    sessionObj: {
      theatresessioncode: sessionCode,
      sessiondate: sessionDate?.replace(/(\d{2})(\d{2})(\d{4})/, "$1/$2/$3"),
    },
  });
  const [formErrors, setFormErrors] = useState({});

  const [showInfoSnack, setShowInfoSnack] = useState();
  const [showOverBookAlert, setShowOverBookAlert] = useState(false);
  const [openBookingQuestion, setOpenBookingQuestion] = useState(false);
  const [openPrintDialogue, setOpenPrintDialogue] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setFormData({
      ...formData,
      [name]: checked,
    });
  };

  const handleComboChange = (domain, val) => {
    setFormData({ ...formData, [domain]: val });
  };

  const checkFormErrors = (postObj, options) => {
    const required = [
      "internalPatientId", // url parameter
    ];
    // Check duration
    if (
      !options.force &&
      postObj.duration > postObj.sessionObj.durationremaining
    ) {
      setOpenBookingQuestion(true);
      return true; //allow force=false to ignore
    }

    // Check to post obj has required fields containing data
    const errors = required.filter((key) => !postObj[key]);
    const newFormErrors = Object.fromEntries(
      errors.map((key) => [key, "Required"]),
    );
    console.log("Missing Fields: " + errors.toString());

    setFormErrors(newFormErrors);
    return errors.length;
  };

  const handleSave = (options) => {
    const resturl =
      global.config.restAPI.url +
      global.config.restAPI.endpoints.theatre.preopdetails +
      "/";
    // Make a POST request to save or update the data
    if (checkFormErrors(formData, options) > 0) return;

    const data = {
      internalpatientid: formData.internalPatientId,
      sessioncode: formData.sessionCode,
      sessiondate: formData.sessiondate,
      sessiontime: formData.sessiontime,
      actualoperationsequence: null,
      operationtype: formData.operationType,
      consultant: formData.casePerson,
      intendedoperationtime: formData.intendedOperationTime,
      intendedtimesentfor: null,
      intendedarrivaltime: null,
      intendedoperationduration: formData.duration,
      intendedanestheticduration: null,
      intendedrecoveryduration: null,
      intendedtimesentduration: null,
      mainprocedure: formData.procedureMain,
      mainside: formData.procedureSide,
      subsidaryprocedure1: formData.procedureAdditional,
      operationseverity: formData.severity,
      anaesthetictype: formData.anaestheticType,
      list_id: null, // formData.,
      episodenumber: null, // formData.,
      tcisequence: null, //formData.,
      tcihospitalnumber: null, //formData.,
      hddr_group: null, //formData.,
      specialty: formData.caseSpecialty,
      //"cepisodenumber": formData.,
      operationtext: formData.bookingNotes,
      //"bookedward": formData.,
      //"bookedhospital": formData.,
      bookeddate: new Date(),
      //"bookedtime": formData.,
      //"caseowner": formData.,
      //"asagrade": formData.,
      //"visitnumber": formData.,
      patientcamefrom: null, //formData.arriveFrom,
      arrivallocationtype: formData.arriveFrom,
      anestheticintheatreflag: formData.anaesthInTheatre,
      recoveryintheatreflag: formData.recoveryInTheatre,
      //"p_out_bookingsequence": formData.
    };

    axios
      .post(resturl, data)
      .then((response) => {
        setShowInfoSnack(`Booking Created (${JSON.stringify(response.data)}).`);
        navigate('/theatre/mgr/');
      })
      .catch((error) => {
        console.error("Error saving data:", error);
        setShowInfoSnack(`Sorry, there was a problem.`);
      });
  };

  return (
    <BookingContainer style={{ flex: 1 }}>
      <NavAppBar title="New Theatre Booking"></NavAppBar>
      {(patientId || formData.patientObj) && (
        <BannerPatient
          patientId={patientId || formData.internalPatientId}
          patientObj={formData.patientObj}
          onSelect={(id) => navigate(`/patient/${id}`)}
        />
      )}
      <Form>
        <Box spacing={2}>
          {!patientId && (
            <div>
              <SectionTitle>Patient Selection </SectionTitle>
              <Row>
                <Column>
                  <PatientSelector
                    selectedObj={formData.patientObj}
                    onChange={(id, rowData) => {
                      setFormData({
                        ...formData,
                        patientObj: rowData,
                        internalPatientId: id,
                      });
                    }}
                    error={formErrors.internalPatientId}
                  />
                </Column>
              </Row>
            </div>
          )}
          <SectionTitle>Booking Type</SectionTitle>
          <Row>
            <Column>
              <SessionSelector
                sessionCode={formData.theatresessioncode}
                selectedSessionObj={formData.sessionObj}
                onChange={(newCode) => {
                  setFormData({
                    ...formData,
                    sessionObj: newCode,
                    sessioncode: newCode.theatresessioncode,
                    sessiondate: newCode.sessiondatesearchable,
                    sessiontime: newCode.actualstarttime
                  });
                }}
              />
            </Column>
            <Column>
              <ComboLookupCode
                label="Session Type"
                value={formData.sessionType}
                refDomain="sessionType"
                onChange={(newData) => {
                  handleComboChange("sessionType", newData);
                }}
              />
            </Column>
          </Row>
          <Row>
            <Column>
              <ComboSpecialty
                label="Case Specialty"
                value={formData.caseSpecialty}
                onChange={(code) => {
                  setFormData({ ...formData, caseSpecialty: code });
                }}
              />
            </Column>
            <Column>
              <InputLabel className="input-label">Case Consultant</InputLabel>
              <ComboProfDets
                name="referringPerson"
                value={formData.casePerson}
                type="consultant"
                specialty={formData.caseSpecialty}
                onChange={(code) => {
                  setFormData({ ...formData, casePerson: code });
                }}
                slotProps={{
                  textField: {
                    size: "small",
                    style: { width: 300, marginTop: 0 },
                  },
                }}
              />
            </Column>
          </Row>
          <Row>
            <Column>
              <ComboLookupCode
                label="Arriving From"
                value={formData.arriveFrom}
                refDomain="arriveFrom"
                onChange={(newData) => {
                  handleComboChange("arriveFrom", newData);
                }}
              />
            </Column>
          </Row>
          <SectionTitle>Operation Details</SectionTitle>
          <Row>
            <Column>
              <ComboLookupCode
                label="Operation Type"
                value={formData.operationType}
                refDomain="operationType"
                onChange={(newData) => {
                  handleComboChange("operationType", newData);
                }}
              />
            </Column>
            <Column>
              <ComboLookupCode
                label="Severity"
                value={formData.severity}
                refDomain="severity"
                onChange={(newData) => {
                  handleComboChange("severity", newData);
                }}
              />
            </Column>
          </Row>
          <Row>
            <Column>
              <ComboLookupCode
                label="Anaesthetic Type"
                value={formData.anaestheticType}
                refDomain="anaestheticType"
                onChange={(newData) => {
                  handleComboChange("anaestheticType", newData);
                }}
              />
            </Column>
            <Column>
              <ComboLookupCode
                label="Anaesthetic Status"
                value={formData.anaestheticStatus}
                refDomain="anaestheticStatus"
                onChange={(newData) => {
                  handleComboChange("anaestheticStatus", newData);
                }}
              />
            </Column>
          </Row>
          <SectionTitle>Procedure Details</SectionTitle>
          <Row>
            <Column>
              <ComboProcedure
                label="Main Procedure"
                value={formData.procedureMain}
                onChange={(newData) => {
                  handleComboChange("procedureMain", newData);
                  handleInputChange({
                    target: { name: "duration", value: newData ? "60" : "" },
                  });
                }}
              />
            </Column>
            <Column>
              <ComboLookupCode
                label="Side"
                value={formData.procedureSide}
                refDomain="procedureSide"
                onChange={(newData) => {
                  handleComboChange("procedureSide", newData);
                }}
              />
            </Column>
          </Row>
          <Row>
            <Column>
              <InputLabel className="input-label">
                Duration (minutes)*
              </InputLabel>
              <TextField
                name="duration"
                value={formData.duration}
                inputmode="numeric"
                pattern="[0-9]*"
                onChange={(e) => {
                  handleInputChange(e);
                  if (formData.sessionObj) {
                    setShowOverBookAlert(
                      formData.sessionObj.durationremaining < e.target.value,
                    );
                  }
                }}
                size="small"
                style={{ width: 300, marginTop: 0 }}
              />
            </Column>
            <Column>
              <ComboProcedure
                label="Additional Procedure"
                value={formData.procedureAdditional}
                onChange={(newData) => {
                  handleComboChange("procedureAdditional", newData);
                }}
              />
            </Column>
          </Row>
          <Row>
            <Column className="alert">
              <Collapse in={showOverBookAlert}>
                <Alert severity="warning">
                  This duration exceeds the available time remaining in the
                  session.
                </Alert>
              </Collapse>
            </Column>
          </Row>
          <Row>
            <Column>
              <EquipmentSelector
                value={formData.equipmentArray}
                theatreCode={formData.theatreCode}
                onChange={(data) => {
                  setFormData({
                    ...formData,
                    equipmentArray: data,
                  });
                }}
              />
              <ConsumablesSelector
                value={formData.consumablesArray}
                theatreCode={formData.theatreCode}
                onChange={(data) => {
                  setFormData({
                    ...formData,
                    consumablesArray: data,
                  });
                }}
              />
            </Column>
            <Column>
              <InputLabel className="input-label">Notes</InputLabel>
              <TextField
                name="bookingNotes"
                placeholder="notes"
                value={formData.bookingNotes}
                onChange={handleInputChange}
                multiline
                rows={4}
                defaultValue=""
                size="small"
                style={{ width: 300, marginTop: 0 }}
              />
            </Column>
          </Row>
          <Row>
            <Column></Column>
          </Row>
          <Row>
            <Column>
              <XCheckbox
                label="Anaesthetic In Theatre"
                checked={formData.anaesthInTheatre}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    anaesthInTheatre: e.target.checked,
                  })
                }
              />
            </Column>
            <Column>
              <XCheckbox
                label="Recovery In Theatre"
                checked={formData.recoveryInTheatre}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    recoveryInTheatre: e.target.checked,
                  })
                }
              />
            </Column>
          </Row>
          {/*
          <SectionTitle>Durations (mins)</SectionTitle>
          <Row>
            <Column>
              <InputLabel className="input-label">Send for</InputLabel>
              <TextField
                name="durationSendFor"
                value={formData.durationSendFor}
                inputmode="numeric"
                pattern="[0-9]*"
                onChange={handleInputChange}
                size="small"
                style={{ width: 300, marginTop: 0 }}
              />
            </Column>
            <Column>
              <InputLabel className="input-label">Anaesthetic</InputLabel>
              <TextField
                name="durationAnaesthetic"
                value={formData.durationAnaesthetic}
                onChange={handleInputChange}
                size="small"
                style={{ width: 300, marginTop: 0 }}
              />
            </Column>
          </Row>
          <Row>
            <Column>
              <InputLabel className="input-label">In Theatre</InputLabel>
              <TextField
                name="durationInTheatre"
                value={formData.durationInTheatre}
                onChange={handleInputChange}
                size="small"
                style={{ width: 300, marginTop: 0 }}
              />
            </Column>
            <Column>
              <InputLabel className="input-label">Recovery</InputLabel>
              <TextField
                name="durationRecovery"
                value={formData.durationRecovery}
                onChange={handleInputChange}
                size="small"
                style={{ width: 300, marginTop: 0 }}
              />
            </Column>
          </Row>
 */}{" "}
          <Row>
            <Column></Column>
          </Row>
          <Footer>
            <div>
              <BoxButtons>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSave}
                  className="btn-primary"
                >
                  Save
                </Button>
                <Button
                  variant="outlined"
                  onClick={() => {
                    navigate(-1);
                  }}
                  className="btn-cancel"
                >
                  Cancel
                </Button>
              </BoxButtons>
            </div>
          </Footer>
          <InfoSnackbar
            showMessage={showInfoSnack}
            onClose={() => setShowInfoSnack(false)}
          />
          <DialogQuestion
            title="Session Overbooked"
            desc="The operation duration exceeds the session duration remain. Are you sure you want to proceed?"
            open={openBookingQuestion}
            onClose={(isOK) => {
              setOpenBookingQuestion(false);
              console.log("question response:" + (isOK ? "OK" : "Cancel"));
              if (isOK) {
                handleSave({ force: true }); //Force save
              }
            }}
          />
          <AppointmentPrint
            open={openPrintDialogue}
            onClose={() => {
              const patId = patientId || formData.internalPatientId;
              navigate(`../patient/${patId}/`);
            }}
          />
        </Box>
      </Form>
    </BookingContainer>
  );
};

export default BookingForm;
