import React, { useState } from "react";
import { DataGrid } from "@mui/x-data-grid";

const columns = [
  { field: "pasid", headerName: "PAS ID", width: 100 },
  { field: "patientname", headerName: "Patient", width: 200 },
  { field: "ward", headerName: "Ward", width: 150 },
  { field: "attendancedatetime",  headerName: "Attendance Date Time", width: 200, type:'string', valueGetter: (params) => {
        // if(!params || !params.value) return;
        // console.log(params.value);
        // const p= params.value.split('/');
        // const d={day:0,month:1,year:2}
        // const date = new Date(p[d.year],p[d.month],p[d.day]);
        return params.value;
      }},
  { field: "attendernumber", headerName: "Attender Number", width: 150 },
  { field: "uniqueattendancenumber", headerName: "Unique Attendance Number", width: 150 },
  { field: "staffgradedescription", headerName: "Staff Grade", width: 150 },
  { field: "staffseenname", headerName: "Staff Name", width: 150 },
  { field: "specialty", headerName: "Specialty", width: 150 },
  { field: "hospitalcode", headerName: "Hospital Code", width: 150 },
  { field: "patienttypedescription", headerName: "Patient Type", width: 200 },
  { field: "outcomedescription", headerName: "Outcome", width: 200 },
  { field: "sourceofreferraldescriptipon", headerName: "Source of Referral", width: 200 },
  { field: "departuredate", headerName: "Departure Date", width: 200, type:'string', valueGetter: (params) => {
      // if(!params || !params.value) return;
      // console.log(params.value);
      // const p= params.value.split('/');
      // const d={day:0,month:1,year:2}
      // const date = new Date(p[d.year],p[d.month],p[d.day]);
      return params.value;
    }},
];

const WardAttenderGrid = ({ records, onSelect }) => {
  const handleRowClick = (params) => {
    console.log("Selected Row ID:", params.id);
    console.log("Selected Row Data:", params);
    onSelect(params.id, params.row);
  };

  return (
    <DataGrid
      rows={records}
      columns={columns}
      pageSize={10}
      rowsPerPageOptions={[10, 25, 50, 100]}
      pagination
      autoHeight
      onRowClick={handleRowClick}
      getRowId={(row) => `${row.episodenumber}_${row.internalpatientid}`}
      columnHeaderHeight={25}
    />
  );
};

export default WardAttenderGrid;
