import React, { useState, useEffect } from "react";
import axios from "axios";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import Button from "@mui/material/Button";
import ReferralGrid from "../components/referralGrid";
import styled from "styled-components";
import { useNavigate, useParams } from "react-router-dom";
import "../config";

const FormContainer = styled.div`
  background-color: #f4f4f4;
  padding: 20px;
`;

const ReferralSearchContainer = styled.div`
  height: 90vh;
  display: flex;
  flex-direction: column;
  flex: 1;

  .title {
    background-color: #4a4c52;
    height: 40px;
    text-align: center;

    h2 {
      margin: 8px;
      color: #ffffff;
      font-size: 18px;
    }
  }
`;

export const GridContainer = styled.div`
  .MuiDataGrid-columnHeaders {
    background-color: #ebf3fc;

    .MuiDataGrid-columnHeader {
      border-right: 1px solid #ededed;
    }
  }

  .MuiDataGrid-cell {
    border-right: 1px solid #ededed;
  }

  .MuiDataGrid-row {
    &:nth-child(even) {
      background-color: #fafafa;
    }
  }
`;

function ReferralSearchDialogue(props) {
  const { onClose, value, open, patientId } = props;
  const [referrals, setReferrals] = useState([]);
  const cfg = global.config.restAPI;

  useEffect(() => {

    // Fetch referral details based on the ID from the URL params
    const query = { internalpatientid: { $eq: patientId }} ;
    const url = `${cfg.url + cfg.endpoints.referrals}/?q=${JSON.stringify(query)};`

    axios
      .get(url) // 
      .then((response) => {
        setReferrals(response.data.items);
      });
  }, [patientId, cfg]);

  const handleSelect = (id, newObj) => {
    onClose(id, newObj);
  };


  return (
    <Dialog
      fullWidth={true}
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          maxWidth: "lg",
          minHeight: "90vh",
        },
      }}
    >
      <DialogTitle>Select Referral</DialogTitle>
      <ReferralSearchContainer>
        {referrals && (
          <GridContainer style={{ flex: 1, overflow: "scroll" }}>
            <ReferralGrid referrals={referrals} onSelect={handleSelect} />
          </GridContainer>
        )}
      </ReferralSearchContainer>
    </Dialog>
  );
}
export default ReferralSearchDialogue;
