// pages/PatientList.js
import React, { useState, useEffect } from "react";
import axios from "axios";
import styled from "styled-components";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";

import ClinicSearchForm from "./clinicSearchForm";
import ClinicSessionGrid from "./clinicSessionGrid";
import ClinicSlotGrid from "./clinicSlotGrid";
import ClinicNavBar from "./clinicNavBar";
import ClinicCalendar from "./clinicCalendar";
import "../../config";

const FormContainer = styled.div`
  background-color: #f4f4f4;
  padding: 20px;
`;

const ClinicSearchContainer = styled.div`
  height: 90vh;
  display: flex;
  flex-direction: column;
  flex: 1;

  .title-bar {
    background-color: #4a4c52;
    height: 40px;
  }
  .title {
    text-align: center;
    color: #ffffff;
  }
`;

export const ClinicListContainer = styled.div`
  .MuiDataGrid-columnHeaders {
    background-color: #ebf3fc;

    .MuiDataGrid-columnHeader {
      border-right: 1px solid #ededed;
    }
  }

  .MuiDataGrid-cell {
    border-right: 1px solid #ededed;
  }

  .MuiDataGrid-row {
    &:nth-child(even) {
      background-color: #fafafa;
    }
  }

  .title-bar {
    button {
      color: #ffffff !important;
      border-color: #ffffff !important;

      &:hover {
        color: #4a4c52 !important;
        background-color: #ffffff;
      }
    }
  }
`;

const ClinicSearch = ({ clinicCode, specialtyCode, onSelect }) => {
  const [clinics, setClinics] = useState();
  const [clinicSelected, setClinicSelected] = useState();
  const [slots, setSlots] = useState();
  const [showCal, setShowCal] = useState(false);
  const cfg = global.config.restAPI;

  useEffect(() => {
    // Fetch referral detailData s based on the ID from the URL params
    if (specialtyCode) {
      axios
        .get(
          `${cfg.url + cfg.endpoints.clinicSessions}/?q=
{"spec":{"$eq":"${specialtyCode}"}}`,
        )
        .then((response) => {
          setClinics(response.data.items);
        })
        .catch((error) => {
          console.log(error);
        });
    } else if (clinicCode) {
      axios
        .get(
          `${cfg.url + cfg.endpoints.clinicSessions}/?q=
{"clinic":{"$eq":"${clinicCode}"}}`,
        )
        .then((response) => {
          const firstItem = response.data.items[0];
          setClinicSelected(firstItem);
        })
        .catch((error) => {
          console.log(error);
        });
      axios
        .get(
          `${cfg.url + cfg.endpoints.clinicSessionSlots}/?q=
{"clinic":{"$eq":"${clinicCode}"}}`,
        )
        .then((response) => {
          setSlots(response.data.items);
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      axios
        .get(`${cfg.url + cfg.endpoints.clinicSessions}`)
        .then((response) => {
          setClinics(response.data.items);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [clinicCode, specialtyCode, cfg]);

  const handleSlotSelect = (slotObj) => {
    onSelect(slotObj.row);
  };

  const handleDrillBack = () => {
    setClinicSelected(null);
    setSlots();
  };

  const handleDrillDown = (rowData) => {
    setClinicSelected(rowData.row);

    axios
      .get(
        `${cfg.url + cfg.endpoints.clinicSessionSlots}/?q={"clinic":{"$eq":"${rowData.id}"}}`,
      )
      .then((response) => {
        setSlots(response.data.items);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleFormSubmit = (formData) => {
    console.log("Form Data in Parent:", formData);

    // Filtering out empty values from formData
    const nonEmptyFormData = Object.fromEntries(
      Object.entries(formData).filter(([key, value]) => value !== ""),
    );

    const queryParams = new URLSearchParams(nonEmptyFormData).toString();

    // Fetch patients from the API
    axios
      .get(`${cfg.url + cfg.endpoints.clinicSessions}/?${queryParams}`)
      .then((response) => {
        setClinics(response.data.items);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  if (!clinics && !slots) {
    return <p style={{ marginLeft: 24 }}>Loading...</p>;
  }

  return (
    <ClinicSearchContainer>
      {!showCal && (
        <FormContainer>
          <ClinicSearchForm onSubmit={handleFormSubmit} />
        </FormContainer>
      )}
      {clinics && !clinicSelected && (
        <ClinicListContainer style={{ flex: 1, overflow: "scroll" }}>
          <ClinicNavBar title={` Clinics `} />

          <ClinicSessionGrid
            clinicSessions={clinics}
            onSelect={handleDrillDown}
          />
        </ClinicListContainer>
      )}
      {slots && !showCal && (
        <ClinicListContainer style={{ flex: 1, overflow: "scroll" }}>
          <ClinicNavBar
            title={`${clinicSelected ? clinicSelected.descript : ""} - Available Slots`}
            onBack={handleDrillBack}
          >
            <ButtonGroup variant="outlined" size="small">
              <Button
                variant={!showCal ? "outlined" : "primary"}
                onClick={() => setShowCal(false)}
              >
                List
              </Button>
              <Button
                variant={showCal ? "outlined" : "primary"}
                onClick={() => setShowCal(true)}
              >
                Calendar
              </Button>
            </ButtonGroup>
          </ClinicNavBar>

          <ClinicSlotGrid clinicSlots={slots} onSelect={handleSlotSelect} />
        </ClinicListContainer>
      )}

      {slots && showCal && (
        <ClinicListContainer style={{ flex: 1, overflow: "scroll" }}>
          <ClinicNavBar
            title={`${clinicSelected ? clinicSelected.descript : ""} - Calendar`}
            onBack={handleDrillBack}
          >
            <ButtonGroup variant="outlined" size="small">
              <Button
                variant={!showCal ? "outlined" : "primary"}
                onClick={() => setShowCal(false)}
              >
                List
              </Button>
              <Button
                variant={showCal ? "outlined" : "primary"}
                onClick={() => setShowCal(true)}
              >
                Calendar
              </Button>
            </ButtonGroup>
          </ClinicNavBar>
          <ClinicCalendar
            clinicSessions={clinics}
            clinicSlots={slots}
            onSelect={onSelect}
          />
        </ClinicListContainer>
      )}
    </ClinicSearchContainer>
  );
};

export default ClinicSearch;
