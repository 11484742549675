import React, { useState, useEffect } from "react";
import { Button } from "@mui/material";
import axios from "axios";
import styled from "styled-components";
import "../config";
import Avatar from "../assets/avatar.svg";

const Section = styled.div`
  line-height: 1.428571429;
  font-size: 12px;
  font-family:
    Source Sans Pro,
    sans-serif;
  color: #000;
  box-sizing: border-box;

  & .encounter-document-view {
    line-height: 1.428571429;
    font-size: 12px;
    font-family:
      Source Sans Pro,
      sans-serif;
    color: #000;
    box-sizing: border-box;
  }

  & .encounter-view {
    line-height: 1.428571429;
    font-family:
      Source Sans Pro,
      sans-serif;
    color: #000;
    box-sizing: border-box;
    font-size: 14px;
    display: flex;
    flex-flow: row;
    padding: 5px 10px;
    border: 1px solid rgb(224, 224, 224);
    background: white;
    margin-top: 40px; 
  }
  & .column {
    flex: 2 0 150px;
    border-right: 1px solid rgb(204, 204, 204);
    padding: 0 10px;

    h4.banner-style {
      color: rgb(136, 136, 136);
      font-size: 16px;
      font-weight: 700;
      margin-top: 0px;
      margin-bottom: 5px;
    }

    &.patient-detail {
      flex: 1 0 250px;
    }
  }
  & .column-heading {
    flex: 1;
    font-weight: bold;
    border-right: 1px solid rgb(224, 224, 224);

    display: flex;
    flex-direction: row-reverse;

    .avatar {
      width: 50px;
      height: 50px;
      margin-right: 12px;
      margin-top: 15px;
    }
  }
  & .column-heading span {
    text-align: right;
    padding-right: 12px;
  }

  & .encounter-info {
    flex: 1;
    line-height: 1.428571429;
    font-family:
      Source Sans Pro,
      sans-serif;
    color: #000;
    font-size: 14px;
    box-sizing: border-box;
    display: flex;
    flex-flow: wrap;

    .name {
      font-size: 22px;
      font-weight: 600;
      text-transform: capitalize;
    }
  }
  & .caption {
    color: rgb(115, 115, 115);
  }
  & .value {
    margin-top: 0px;
    margin-right: 20px;
    margin-bottom: 0px;
    margin-left: 5px;
    font-weight: 600;
    text-transform: capitalize;
  }

  .group {
    display: flex;
  }

  .edit-btn {
    padding: 0 10px;
  }
`;

function isNumeric(value) {
  return !isNaN(value) && typeof value !== 'boolean';
}

const BannerPatient = ({ patientId, patientRec, onSelect }) => {
  const cfg = global.config.restAPI; //"http://localhost:3000/";
  const [patient, setPatient] = useState(patientRec);

  useEffect(() => {
    // Check if data has been provided by the parent
    if (patientRec) {
      setPatient(patientRec);
      return;
    }

    console.log(`Get patientId:${patientId}`);
    // Fetch patient data for the patient ID frbm the URL params

    const query = { internal_patient_id: { $eq: patientId } };
    const url = `${cfg.url + cfg.endpoints.patients}/?q=${JSON.stringify(query)};`
    const demo = `${cfg.url + cfg.endpoints.patients}/`

    axios
      .get((isNumeric(patientId) ? url : demo))
      .then((response) => {
        const firstPatient = response.data.items[0];
        setPatient(firstPatient);
      });
  }, [patientRec, patientId, cfg]);

  if (!patient) return;

  return (
    <Section style={{ color: "#3d3d3d" }}>
      <div class="encounter-view">
        <div class="column-heading">
          <img src={Avatar} className="avatar" />
        </div>
        <div class="column patient-detail">
          <div>
            <div className="encounter-info">
              <div className="name">
                {patient.surname}, {patient.name1}
              </div>
            </div>
            <div className="encounter-info">
              <div className="caption">DOB:</div>
              <div className="value">{patient.date_of_birth}</div>
            </div>
          </div>
          <div className="group">
            <div className="encounter-info">
              <div className="caption">NHS:</div>
              <div className="value">{patient.pasid}</div>
            </div>
          </div>
          <div className="group">
            <div className="encounter-info">
              <div className="caption">Gender:</div>
              <div className="value encounter-type">{patient.gender}</div>
            </div>
          </div>
        </div>
        <div className="column">
          <h4 className="banner-style">Encounter Details</h4>
          <div className="encounter-info">
            <div className="caption">Registered:</div>
            <div className="value">{patient.date_registered}</div>
          </div>
        </div>
        <div className="column">
          <h4 className="banner-style">Address</h4>
          <div className="encounter-info">
            <div className="caption">Postcode:</div>
            <div className="value">{patient.postcode}</div>
          </div>
        </div>
        <div className="column" />
        <div className="column" />
        <div className="edit-btn">
          <Button
            variant="outlined"
            size="small"
            style={{ maxWidth: "100px" }}
            onClick={() => onSelect(patient.internal_patient_id)}
          >
            Edit
          </Button>
        </div>
      </div>
    </Section>
  );
};

export default BannerPatient;
