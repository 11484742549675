import React, { useState, useEffect } from "react";
import {
  Autocomplete,
  Divider,
  TextField,
  Button,
  Box,
  FormControlLabel,
  Checkbox,
  InputLabel,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import axios from "axios";
import "dayjs/locale/en-gb";
import { useNavigate, useParams } from "react-router-dom";

import NavAppBar from "../components/navAppBar";
import ComboLookupCode from "../components/comboLookup";
import ComboProcedure from "../components/comboProcedure";
import styled from "styled-components";
import {
  Footer,
  Form,
  Row,
  Column,
  SectionTitle,
  VSpace,
} from "../components/formLayout";
import "../config";
import InfoSnackbar from "../components/infoSnackbar";
import DialogQuestion from "../components/dialogQuestion";
import BannerPatient from "../components/bannerPatient";
import BannerReferral from "../components/bannerReferral";
import XDatePicker from "../components/xDatePicker";
import XCheckbox from "../components/xCheckbox";
import PrintDialog from "../components/waitlist/waitlistPrint";
import PatientSelector from "../components/patientSelector";
import ReferralSelector from "../components/referralSelector";
import ComboWaitlistRef from "../components/comboWaitlistRef";

const BoxButtons = styled.div`
  display: flex;
  flex-direction: row-reverse;
  background-color: #4a4c52;
  & button {
    margin: 10px;
  }
`;

const WaitListContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  .input-label {
    font-size: 12px;
  }
`;

// Function to format date as "DD/MMM/YYYY"
const formatDate = (inputDate) => {
  const options = { day: "2-digit", month: "short", year: "numeric" };
  return new Date(inputDate).toLocaleDateString("en-GB", options);
};

const WaitlistInpatientForm = () => {
  const navigate = useNavigate();
  const { patientId, referralId, listRef } = useParams();
  const [formErrors, setFormErrors] = useState({});
  const [newEpisodeId, setNewEpisodeId] = useState({});
  const [formData, setFormData] = useState({
    internalPatientId: patientId,
    referralId: referralId, // "11/JAN/2024"
    listRef: listRef === "ALL" ? null : listRef,
    dateOnList: null,
    patientCategory: null,
  });

  const [showInfoSnack, setShowInfoSnack] = useState();
  const [openPrintQuestion, setOpenPrintQuestion] = useState(false);
  const [openPrintDialogue, setOpenPrintDialogue] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setFormData({
      ...formData,
      [name]: checked,
    });
  };

  const handleComboChange = (domain, val) => {
    setFormData({ ...formData, [domain]: val });
  };

  const checkFormErrors = (postObj) => {
    const required = [
      "internalPatientId", // url parameter
      "referralId", // url parameter
      "dateOnList",
      "priority",
      "listRef",
    ];

    // Check to post obj has required fields containing data
    const errors = required.filter((key) => !postObj[key]);
    const newFormErrors = Object.fromEntries(
      errors.map((key) => [key, "Required"]),
    );
    console.log("Missing Fields: " + errors.toString());

    setFormErrors(newFormErrors);
    return errors.length;
  };

  const handleSave = () => {
    const resturl =
      global.config.restAPI.url +
      global.config.restAPI.endpoints.theatre.waitlistentry +
      "/";
    // Make a POST request to save or update the data
    if (checkFormErrors(formData) > 0) return;

    const data = {
      internalpatientid: formData.internalPatientId,
      list_id: formData.listRef,
      originaldateonlist: formData.dateOnListOriginal,
      patientcategorycode: formData.patientCategory,
      referralnumber: formData.referralId,
      admissionmethod: formData.admissionMethod,
      prioritycode: formData.priority,
      expectedbookingsystemcode: formData.bookingSystem,
      freetextcomments: formData.freeText,
      mainprocedure: formData.procedureMain,
      side: formData.procedureSide,
      subsidaryprocedure1: formData.procedureAdditional,
      anaestheticrequiredcode: formData.anaestheticType,
      guaranteedadmissiondate: formData.dateOnGuaranted,
    };

    /*
    let data = formData;
    delete data.patientObj; //remove from payload
    delete data.referralObj; //remove from payload
    delete data.expectedBookingSystem; //remove from payload
    */

    axios
      .post(resturl, data)
      .then((response) => {
        setShowInfoSnack(
          `Waitlist Created (${JSON.stringify(response.data)}).`,
        );
        setNewEpisodeId(response.data.WaitlistEpisodeNumber);
        setOpenPrintQuestion(true); // ask to create referral
      })
      .catch((error) => {
        console.error("Error saving data:", error);
        setShowInfoSnack(`Sorry, there was a problem.`);
      });
  };

  return (
    <WaitListContainer style={{ flex: 1 }}>
      <NavAppBar title="Add Inpatient Waitlist Entry"></NavAppBar>
      {(referralId || (formData.patientObj && formData.referralObj)) && (
        <BannerReferral
          patientId={patientId || formData.internalPatientId}
          patientObj={formData.patientObj}
          referralId={referralId}
          onSelect={() =>
            navigate(`/referral/patient/${patientId}/referral/${referralId}`)
          }
        />
      )}
      {((patientId && !referralId) ||
        (formData.patientObj && !formData.referralObj)) && (
          <BannerPatient
            patientId={patientId || formData.internalPatientId}
            patientObj={formData.patientObj}
            onSelect={(id) => navigate(`/patient/${id}`)}
          />
        )}
      <Form>
        <Box spacing={2}>
          {!patientId && (
            <div>
              <SectionTitle>Patient Selection </SectionTitle>
              <Row>
                <Column>
                  <PatientSelector
                    selectedObj={formData.patientObj}
                    onChange={(id, rowData) => {
                      setFormData({
                        ...formData,
                        patientObj: rowData,
                        internalPatientId: id,
                      });
                    }}
                    error={formErrors.internalPatientId}
                  />
                </Column>
                <Column></Column>
              </Row>
            </div>
          )}
          {formData.patientObj && !referralId && (
            <Row>
              <Column>
                <ReferralSelector
                  selectedObj={formData.referralObj}
                  patientId={formData.internalPatientId}
                  onChange={(id, rowData) => {
                    setFormData({
                      ...formData,
                      referralObj: rowData,
                      referralId: id,
                    });
                  }}
                  error={formErrors.referralId}
                />
              </Column>
              <Column></Column>
              <Divider />
            </Row>
          )}
          <SectionTitle>Waitlist Details</SectionTitle>
          <LocalizationProvider
            dateAdapter={AdapterDayjs}
            adapterLocale="en-gb"
          >
            <Row>
              <Column>
                <ComboWaitlistRef
                  label="List Reference*"
                  value={formData.listRef}
                  onChange={(id) => setFormData({ ...formData, listRef: id })}
                  error={formErrors.listRef}
                />
              </Column>
              <Column>
                <InputLabel className="input-label">Date On List*</InputLabel>
                <XDatePicker
                  value={formData.dateOnList}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(newValue) =>
                    setFormData({ ...formData, dateOnList: newValue })
                  }
                  error={formErrors.dateOnList}
                />
              </Column>
              <Divider />
            </Row>

            <Row>
              <Column></Column>
              <Column>
                <InputLabel className="input-label">
                  Original Date On List
                </InputLabel>
                <XDatePicker
                  value={formData.dateOnListOriginal}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(newValue) =>
                    setFormData({ ...formData, dateOnListOriginal: newValue })
                  }
                  error={formErrors.dateOnListOriginal}
                />
              </Column>
            </Row>
          </LocalizationProvider>
          <Row></Row>
          <SectionTitle>Booking Details</SectionTitle>
          <Row>
            <Column>
              <ComboLookupCode
                label="Patient Category"
                value={formData.patientCategory}
                refDomain="patientCategory"
                onChange={(newData) => {
                  handleComboChange("patientCategory", newData);
                }}
              />
            </Column>
            <Column>
              <ComboLookupCode
                label="Priority*"
                value={formData.priority}
                refDomain="inpatientPriority"
                onChange={(newData) => {
                  handleComboChange("priority", newData);
                }}
                error={formErrors.priority}
              />
            </Column>
          </Row>
          <Row>
            <Column>
              <ComboLookupCode
                label="Adminision Method"
                value={formData.admissionMethod}
                refDomain="admissionMethod"
                onChange={(newData) => {
                  handleComboChange("admissionMethod", newData);
                }}
              />
            </Column>
            <Column>
              <ComboLookupCode
                label="Booking System"
                value={formData.bookingSystem}
                refDomain="bookingSystem"
                onChange={(newData) => {
                  handleComboChange("bookingSystem", newData);
                }}
              />
            </Column>
          </Row>
          <Row>
            <Column>
              <ComboLookupCode
                label="Planned Details_"
                value={formData.expectedBookingSystem}
                refDomain="bookingSystem"
                onChange={(newData) => {
                  handleComboChange("expectedBookingSystem", newData);
                }}
                readonly={true}
              />
            </Column>
            <Column>
              <InputLabel className="input-label">Addition Text</InputLabel>
              <TextField
                placeholder="text"
                name="freeText"
                multiline
                rows={4}
                defaultValue=""
                value={formData.freeText}
                onChange={handleInputChange}
                size="small"
                style={{ width: 300, marginTop: 0 }}
              />
            </Column>
          </Row>
          <SectionTitle>Procedure Details</SectionTitle>
          <Row>
            <Column>
              <ComboProcedure
                label="Main Procedure"
                value={formData.procedureMain}
                onChange={(newData) => {
                  handleComboChange("procedureMain", newData);
                }}
              />
            </Column>
            <Column>
              <ComboLookupCode
                label="Side"
                value={formData.procedureSide}
                refDomain="procedureSide"
                onChange={(newData) => {
                  handleComboChange("procedureSide", newData);
                }}
              />
            </Column>
          </Row>
          <Row>
            <Column>
              <ComboLookupCode
                label="Anaesthetic Type"
                value={formData.anaestheticType}
                refDomain="anaestheticType"
                onChange={(newData) => {
                  handleComboChange("anaestheticType", newData);
                }}
              />
            </Column>
            <Column>
              <ComboProcedure
                label="Additional Procedure"
                value={formData.procedureAdditional}
                onChange={(newData) => {
                  handleComboChange("procedureAdditional", newData);
                }}
              />
            </Column>
          </Row>
          <Row>
            <Column>
              <ComboLookupCode
                label="Preadmission Details_"
                value={formData.expectedBookingSystem}
                refDomain="bookingSystem"
                onChange={(newData) => {
                  handleComboChange("expectedBookingSystem", newData);
                }}
                readonly={true}
              />
            </Column>
            <Column>
              <LocalizationProvider
                dateAdapter={AdapterDayjs}
                adapterLocale="en-gb"
              >
                <InputLabel className="input-label">Guaranted Date</InputLabel>
                <XDatePicker
                  value={formData.dateOnGuaranted}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(newValue) =>
                    setFormData({ ...formData, dateOnGuaranted: newValue })
                  }
                  error={formErrors.dateOnGuaranted}
                />
              </LocalizationProvider>
            </Column>
          </Row>
          <Footer>
            <div>
              <BoxButtons>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSave}
                  className="btn-primary"
                >
                  Save
                </Button>
                <Button
                  variant="outlined"
                  onClick={() => {
                    navigate(-1);
                  }}
                  className="btn-cancel"
                >
                  Cancel
                </Button>
              </BoxButtons>
            </div>
          </Footer>
          <InfoSnackbar
            showMessage={showInfoSnack}
            onClose={() => setShowInfoSnack(false)}
          />
          <DialogQuestion
            title="Print?"
            desc="Would you like to print a waitlist letter?"
            open={openPrintQuestion}
            onClose={(isOK) => {
              const patId = patientId || formData.internalPatientId;
              const refId = referralId || formData.referralId;
              setOpenPrintQuestion(false);
              console.log("question response:" + (isOK ? "OK" : "Cancel"));
              if (isOK) {
                setOpenPrintDialogue(true);
              } else {
                navigate(
                  "waitlist/mgr",
                  //   `../patient/${patId}/referral/${refId}/`, // return to referral details
                );
              }
            }}
          />
          <PrintDialog
            open={openPrintDialogue}
            onClose={() => {
              const patId = patientId || formData.internalPatientId;
              const refId = referralId || formData.referralId;
              if (refId !== "undefined")
                navigate(`../inpatient/patient/${patId}/${newEpisodeId}/`);
              else navigate(`/waitlist/mgr`);
            }}
          />
        </Box>
      </Form>
    </WaitListContainer>
  );
};

export default WaitlistInpatientForm;
