/* appointmentForm.js - Outpatient Clinic Appointment */
import React, { useState, useEffect } from "react";
import {
  Autocomplete,
  TextField,
  Button,
  Box,
  FormControlLabel,
  Checkbox,
  InputLabel,
  Collapse,
  Alert,
} from "@mui/material";
import axios from "axios";
import dayjs from "dayjs";
import { useNavigate, useParams } from "react-router-dom";

import NavAppBar from "../components/navAppBar";
import ComboLookupCode from "../components/comboLookup";
import ComboSpecialty from "../components/comboSpecialty";
import ComboClinic from "../components/comboClinic";
import styled from "styled-components";
import {
  Footer,
  Form,
  Row,
  Column,
  SectionTitle,
} from "../components/formLayout";
import "../config";
import InfoSnackbar from "../components/infoSnackbar";
import DialogQuestion from "../components/dialogQuestion";
import BannerReferral from "../components/bannerReferral";
import BannerPatient from "../components/bannerPatient";
import ClinicSlotSelector from "../components/clinic/clinicSlotSelector";
import AppointmentPrint from "../components/appointmentPrint";
import PatientSelector from "../components/patientSelector";
import ReferralSelector from "../components/referralSelector";
import XCheckbox from "../components/xCheckbox";

const BoxButtons = styled.div`
  display: flex;
  flex-direction: row-reverse;
  background-color: #4a4c52;
  & button {
    margin: 10px;
  }
`;

const AppointmentContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  .input-label {
    font-size: 12px;
  }
`;

const SearchSection = styled.div`
  border: 1px solid rgb(224, 224, 224);
  margin: 10px 0;
  padding: 10px 0;
`;

const isLongWait = (sDate, eDate) => {
  const e = dayjs(eDate, "DD/MM/YYYY");
  const s = dayjs(sDate, "DD/MM/YYYY");

  return e.diff(s, "day") > 14;
};

const AppointmentForm = () => {
  const navigate = useNavigate();
  const { patientId, referralId, clinicCode, slotUniqueKey } = useParams("");
  const [formErrors, setFormErrors] = useState({});
  const [formData, setFormData] = useState({
    internalPatientId: patientId,
    referralId: referralId, // "11/JAN/2024"
    searchTypeCode: clinicCode !== "undefined" ? "S" : null,
    clinicSpecialtyCode: clinicCode !== "undefined" ? clinicCode : null,
  });
  const [openWarning, setOpenWarning] = useState(false);
  const [openWarning2, setOpenWarning2] = useState(false);

  const [showInfoSnack, setShowInfoSnack] = useState();
  const [openAppointmentQuestion, setOpenAppointmentQuestion] = useState(false);
  const [openPrintDialogue, setOpenPrintDialogue] = useState(false);
  const cfg = global.config.restAPI; //"http://localhost:3000/";

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setFormData({
      ...formData,
      [name]: checked,
    });
  };

  useEffect(() => {
    const query = { uniquekey: { $eq: slotUniqueKey } };
    const url = `${cfg.url + cfg.endpoints.clinicSessionSlots}/?q=${JSON.stringify(query)}`;

    if (slotUniqueKey) {
      // Fetch slotdetails based on the ID from the URL params
      axios.get(url).then((response) => {
        const selSlot = response.data.items[0];
        setFormData({
          ...formData,
          clinicSlotObj: selSlot,
          clinicCode: selSlot.clinic,
          clinicSlotDate: selSlot.slotdate,
          clinicSlotTime: selSlot.slottime,
          uniqueKey: selSlot.uniquekey,
        });
      });
    }
  }, [slotUniqueKey, cfg]);

  const handleComboChange = (domain, val) => {
    setFormData({ ...formData, [domain]: val });
  };

  const checkFormErrors = (postObj) => {
    const required = [
      "internalPatientId", // url parameter
      "referralId",
      "clinicCode",
    ];

    // Check to post obj has required fields containing data
    const errors = required.filter((key) => !postObj[key]);
    const newFormErrors = Object.fromEntries(
      errors.map((key) => [key, "Required"]),
    );
    console.log("Missing Fields: " + errors.toString());

    setFormErrors(newFormErrors);
    return errors.length;
  };

  const handleSave = () => {
    const resturl =
      global.config.restAPI.url +
      global.config.restAPI.endpoints.appointments +
      "/";

    // Make a POST request to save or update the data
    if (checkFormErrors(formData) > 0) return;

    const data = {
      internalpatientid: formData.internalPatientId,
      attendancetypecode: formData.attendanceTypeCode,
      referralnumber: formData.referralId,
      searchtypecode: formData.searchTypeCode,
      //"searchcode": formData.,
      multiplebookingflag: formData.multiplebookingflag,
      /*"appointmentprocedure": formData.,
  "appointmentprioritycode": formData.,
  "appointmentduration": formData.,
  "searchdateflag": formData.,
  "searchdate": formData.,
  "searchtimeflag": formData.,
  "searchtime": formData., */
      transportrequired: formData.transportrequired ? "Y" : "N",
      /*"xrayrequired": formData.,
  "pathologyrequired": formData.,
  "numberofchangebypatient": formData.,
  "numberofchangesbylcinic": formData.,*/
      numberofreschedules: formData.reScheduleFlag ? 1 : 0,
      /*"numberofcancelbypatient": formData.,
  "numberofcancelbyclinic": formData.,
  "numberofdnatotal": formData.,
  "numberofdnaconsecutive": formData.,
  "nextappointmentidentifier": formData.,
  "previousappointmentidentifier": formData.,
  "linkednextappointmentidentifer": formData.,
  "linkedpreviousappointmentidentifier": formData.,*/
      appointmentcreateddate: dayjs(
        formData.clinicSlotDate,
        "DD/MM/YYYY",
      ).toJSON(),
      appointmenttime: formData.clinicSlotTime,
      /*"changewhocode": formData.,
  "lastchangedate": formData.,
  "lastchangereason": formData.,*/
      bookingsystem: formData.bookingSystem,
      cliniccode: formData.clinicCode,
      /*"clinicmanagerprofessiontype": formData.,
  "clinicmanager": formData.,*/
      clinicspecialty: formData.clinicSpecialtyCode,
      /*"cliniclocation": formData.,
  "clinicappointmentsystem": formData.,
  "includeinstats": formData.,*/
      appointmentdate: dayjs(formData.clinicSlotDate, "DD/MM/YYYY").toJSON(),
      /*"elementreference": formData.,
  "sessiontime": formData.,
  "sequencenumber": formData.,*/
      uniqueepisodeappointmentnumber: formData.uniquekey,
      /*"dnaflag": formData.,

  "attendeddate": formData.,

  "attendedtime": formData.,

  "appointmentslotused": formData.,

  "personseenprofessiontypecode": formData.,

  "personseen": formData.,

  "timearrived": formData.,

  "timeseen": formData.,

  "timedepart": formData.,

  "cancelledbycode": formData.,

  "cancelleddate": formData.,

  "cancelledreason": formData.,

  "suspendeddate": formData.,

  "suspendedreason": formData.,

  "codingflagcode": formData.,

  "hrgcode": formData.,

  "contractstatuscode": formData.,
  "provider": formData.,
  "purchaser": formData.,
  "contractid": formData.,
  "linkedtosysdomicil": formData.,
  "slstatuscode": formData.,
  "copstatus": formData.,
  "specialflag": formData.,
  "overbookedflag": formData.,
  "reasonforslotoverbookedcode": formData.,*/
      //"interpreterrequired": formData.interpreterrequired,
      /*"hddrgroup": formData.,
  "appointmentstatuscode": formData.,
  "waitexc": formData.,
  "patientbreachindicator": formData.,
  "reasonforbreachcode": formData.,
  "hrgversion": formData.,
  "asccode": formData.,
  "ubrn": formData.,
  "earliestreasonableofferdate": formData.,
  "linkedappointmentsystem": formData.,
  "linkedtablenumber": formData.,
  "linkedkeyalpha": formData.,
  "linkedkeynumeric1": formData.,
  "linkedkeynumeric2": formData.,
  "linkedkeynumeric3": formData.,*/
      /*"clinictypecode": formData.,
  "cdssubmissionstatuscode": formData.,
  "cdssubmissionnumber": formData.,
  "reasonforappointmentspec": formData.,
  "reasonforappointmentcode": formData.,
  "elementspecialty": formData.,*/
      casenoterequired: formData.casenoteRequired,
      /*"p_out_appointmentnumber": formData.,
  "p_out_episodenumber": formData.*/
    };
    /*
    let data = formData;
    delete data.patientObj; //remove from payload
    delete data.referralObj; //remove from payload
    delete data.clinicSlotObj; //remove from payload
*/
    axios
      .post(resturl, data)
      .then((response) => {
        setShowInfoSnack(`Booking Created (${JSON.stringify(response.data)}).`);
        setOpenAppointmentQuestion(true);
      })
      .catch((error) => {
        console.error("Error saving data:", error);
        setShowInfoSnack(`Sorry, there was a problem.`);
      });
  };

  return (
    <AppointmentContainer style={{ flex: 1 }}>
      <NavAppBar title="New Appointment"></NavAppBar>
      {(referralId || (formData.patientObj && formData.referralObj)) && (
        <BannerReferral
          patientId={patientId || formData.internalPatientId}
          patientObj={formData.patientObj}
          referralId={referralId}
          onSelect={() =>
            navigate(`/referral/patient/${patientId}/referral/${referralId}`)
          }
          error={formErrors.internalPatientId}
        />
      )}
      {((patientId && !referralId) ||
        (formData.patientObj && !formData.referralObj)) && (
        <BannerPatient
          patientId={patientId || formData.internalPatientId}
          patientObj={formData.patientObj}
          onSelect={() => navigate(`/referral/patient/${patientId}`)}
        />
      )}
      <Form>
        <Box spacing={2}>
          {!patientId && (
            <div>
              <SectionTitle>Patient Selection </SectionTitle>
              <Row>
                <Column>
                  <PatientSelector
                    selectedObj={formData.patientObj}
                    onChange={(id, rowData) => {
                      setFormData({
                        ...formData,
                        patientObj: rowData,
                        internalPatientId: id,
                      });
                    }}
                  />
                </Column>
              </Row>
            </div>
          )}
          {formData.patientObj && !referralId && (
            <Row>
              <Column>
                <ReferralSelector
                  selectedObj={formData.referralObj}
                  patientId={formData.internalPatientId}
                  onChange={(id, rowData) => {
                    setFormData({
                      ...formData,
                      referralObj: rowData,
                      referralId: id,
                      clinicSpecialtyCode: rowData.referredtospecialty,
                    });
                    if (formData.clinicSlotDate)
                      setOpenWarning2(
                        isLongWait(
                          rowData.referraldate,
                          formData.clinicSlotDate,
                        ),
                      );
                    setOpenWarning(rowData.suspectedcancer);
                  }}
                />
              </Column>
              <Column>
                <Collapse in={openWarning}>
                  <Alert severity="info">
                    Booking Priority: scheduled within two week of the referral
                    date.
                  </Alert>
                </Collapse>
              </Column>
            </Row>
          )}

          <SectionTitle>Appointment Type</SectionTitle>
          <Row>
            <Column>
              <ComboLookupCode
                label="Attendance Type"
                value={formData.attendanceTypeCode}
                refDomain="attendanceType"
                onChange={(newData) => {
                  handleComboChange("attendanceTypeCode", newData);
                }}
              />
            </Column>
            <Column>
              <ComboLookupCode
                label="Patient Category"
                value={formData.patientCategoryCode}
                refDomain="patientCategory"
                onChange={(newData) => {
                  handleComboChange("patientCategoryCode", newData);
                }}
              />
            </Column>
          </Row>
          <Row>
            <Column>
              <ComboLookupCode
                label="Appointment Type"
                value={formData.appointmentTypeCode}
                refDomain="appointmentType"
                onChange={(newData) => {
                  handleComboChange("appointmentTypeCode", newData);
                }}
              />
            </Column>
          </Row>
          <SectionTitle>Search Availability</SectionTitle>
          <Row>
            <Column>
              <ComboLookupCode
                label="Search Type"
                value={formData.searchTypeCode}
                refDomain="searchType"
                onChange={(newData) => {
                  //Clear sub-fields
                  setFormData({
                    ...formData,
                    clinicCode: null,
                    clinicSpecialtyCode: null,
                    searchTypeCode: newData,
                  });
                }}
              />
            </Column>
            <Column>
              {formData.searchTypeCode === "S" && ( // Show specialty combo
                <ComboSpecialty
                  label="Specialty"
                  value={formData.clinicSpecialtyCode}
                  onChange={(code) => {
                    setFormData({ ...formData, clinicSpecialtyCode: code });
                  }}
                />
              )}
              {formData.searchTypeCode === "M" && ( // Show clinic Manager
                <ComboLookupCode
                  label="Clinic Manager"
                  value={formData.unknown}
                  refDomain="referralMethod"
                  onChange={(newData) => {
                    handleComboChange("unknown", newData);
                  }}
                />
              )}
              {formData.searchTypeCode === "C" && ( // Show clinic combo
                <ComboClinic
                  label="Clinic"
                  value={formData.clinicCode}
                  onChange={(newData) => {
                    handleComboChange("clinicCode", newData);
                  }}
                />
              )}
            </Column>
          </Row>
          <Row>
            <Column>
              <ClinicSlotSelector
                label="Session Slot*"
                clinicCode={formData.clinicCode}
                specialtyCode={formData.clinicSpecialtyCode}
                selectedSlotObj={formData.clinicSlotObj}
                onChange={(newCode) => {
                  setFormData({
                    ...formData,
                    clinicSlotObj: newCode,
                    clinicCode: newCode.clinic,
                    clinicSlotDate: newCode.slotdate,
                    clinicSlotTime: newCode.slottime,
                    uniqueKey: newCode.uniquekey,
                  });
                  if (formData.referralObj)
                    setOpenWarning2(
                      isLongWait(
                        formData.referralObj.referraldate,
                        newCode.slotdate,
                      ),
                    );
                }}
              />
            </Column>
            <Column>
              <Collapse in={openWarning2}>
                <Alert severity="warning">
                  This appointment is more than two week after referral date.
                </Alert>
              </Collapse>
            </Column>
          </Row>
          {/* Search Fields 
          <Row>
            <Column>
              <ComboLookupCode
                label="Appointment Slot Type"
                value={formData.appointmentSlotTypeCode}
                refDomain="appointmentSlotType"
                onChange={(newData) => {
                  handleComboChange("appointmentSlotTypeCode", newData);
                }}
              />
            </Column>
          </Row>
          <Row>
            <Column>
              <ComboLookupCode
                label="Apppointment Priority"
                value={formData.appointmentPriorityCode}
                refDomain="appointmentPriority"
                onChange={(newData) => {
                  handleComboChange("appointmentPriorityCode", newData);
                }}
              />
            </Column>
            <Column>
              <ComboLookupCode
                label="Search Level"
                value={formData.unknown} //search query only
                refDomain="searchLevel"
                onChange={(newData) => {
                  handleComboChange("searchLevel", newData);
                }}
              />
            </Column>
          </Row>
          <Row>
            <Column>
              <ComboLookupCode
                label="Search Method"
                value={formData.searchMethodCode} //search query only
                refDomain="searchMethod"
                onChange={(newData) => {
                  handleComboChange("searchMethodCode", newData);
                }}
              />
            </Column>
          </Row>
          <Row>
            <LocalizationProvider
              dateAdapter={AdapterDayjs}
              adapterLocale="en-gb"
            >
              <Column>
                <XDatePicker
                  label="Search Date"
                  value={formData.searchdate}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(newValue) =>
                    setFormData({ ...formData, searchdate: newValue })
                  }
                />
              </Column>
              <Column>
                <TimePicker
                  style={{ width: 300 }}
                  label="Time"
                  value={formData.searchTime}
                  onChange={(newValue) =>
                    setFormData({ ...formData, searchTime: newValue })
                  }
                  slotProps={{ textField: { size: "small" } }}
                />
              </Column>
            </LocalizationProvider>
          </Row>
 */}
          <Row>
            <Column>
              <XCheckbox
                label="Re-Schedule"
                checked={formData.reScheduleFlag}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    reScheduleFlag: e.target.checked,
                  })
                }
              />
            </Column>
            <Column>
              <XCheckbox
                label="Multiple Booking"
                checked={formData.multipleBookingFlag}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    multiplebookingflag: e.target.checked,
                  })
                }
              />
            </Column>
          </Row>
          <Row>
            <Column>
              <XCheckbox
                label="Casenotes Required"
                checked={formData.casenoteRequired}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    casenoteRequired: e.target.checked,
                  })
                }
              />
            </Column>
            <Column>
              <XCheckbox
                label="Transport Required"
                checked={formData.transportrequired}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    transportrequired: e.target.checked,
                  })
                }
              />
            </Column>
          </Row>

          <Row>
            <Column>
              <XCheckbox
                label="Additional Details"
                checked={formData.unknown}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    additionDetails: e.target.checked,
                  })
                }
              />
            </Column>
            <Column></Column>
          </Row>
          <SectionTitle>Booking Details</SectionTitle>
          <Row>
            <Column>
              <ComboLookupCode
                label="Reason for Appointment"
                value={formData.reasonForAppointmentCode} // Search only No lookup
                refDomain="reasonForAppointment"
                onChange={(newData) => {
                  handleComboChange("reasonForAppointmentCode", newData);
                }}
              />
            </Column>
            <Column>
              <ComboLookupCode
                label="Interpreter Required"
                value={formData.interpreterrequired}
                refDomain="interpreterRequired"
                onChange={(newData) => {
                  handleComboChange("interpreterrequired", newData);
                }}
              />
            </Column>
          </Row>
          <Row>
            <Column>
              <ComboLookupCode
                label="Booking System Used"
                value={formData.bookingSystem}
                refDomain="bookingSystem"
                onChange={(newData) => {
                  handleComboChange("bookingSystem", newData);
                }}
              />
            </Column>
            <Column>
              <Autocomplete
                options={["Check Up", "General Appointment"]}
                style={{ width: 300 }}
                renderInput={(params) => (
                  <React.Fragment>
                    <InputLabel className="input-label">
                      Slot Purpose
                    </InputLabel>
                    <TextField
                      {...params}
                      variant="outlined"
                      InputLabelProps={{ shrink: false }}
                      style={{ marginTop: 0 }}
                    />
                  </React.Fragment>
                )}
                size="small"
                onChange={(e, newData) => {
                  handleComboChange("slotPurpose", newData);
                }}
              />
            </Column>
          </Row>
          <Row>
            <Column>
              <XCheckbox
                label="Search Slot Purpose"
                checked={formData.unknown}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    searchSlotPurpose: e.target.checked,
                  })
                }
              />
            </Column>
            <Column></Column>
          </Row>
          <Footer>
            <div>
              <BoxButtons>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSave}
                  className="btn-primary"
                >
                  Save
                </Button>
                <Button
                  variant="outlined"
                  onClick={() => {
                    navigate(-1);
                  }}
                  className="btn-cancel"
                >
                  Cancel
                </Button>
              </BoxButtons>
            </div>
          </Footer>
          <InfoSnackbar
            showMessage={showInfoSnack}
            onClose={() => setShowInfoSnack(false)}
          />
          <DialogQuestion
            title="Print?"
            desc="Would you like to print an appointment letter?"
            open={openAppointmentQuestion}
            onClose={(isOK) => {
              setOpenAppointmentQuestion(false);
              console.log("question response:" + (isOK ? "OK" : "Cancel"));
              if (isOK) {
                setOpenPrintDialogue(true);
              } else {
                navigate(-1); //`/patient/${patientId}/referral/${referralId}`);
              }
            }}
          />
          <AppointmentPrint
            open={openPrintDialogue}
            onClose={() => {
              const patId = patientId || formData.internalPatientId;
              const refId = referralId || formData.referralId;
              if (refId !== "undefined")
                navigate(`/referral/patient/${patId}/referral/${refId}/`);
              else navigate(`/referral/patient/${patId}/referrals/`);
              //navigate(`patient/${patientId}/referral/${referralId}`);
            }}
          />
        </Box>
      </Form>
    </AppointmentContainer>
  );
};

export default AppointmentForm;
