import React from "react";

import { Box, Button, Drawer } from "@mui/material";
import MiyaLogo from "../appMenu/logos/logoMiya.svg";
import MiyaPas from "../appMenu/logos/logoPas.svg";
import MiyaTrack from "../appMenu/logos/logoTrack.svg";
import MiyaSmartpage from "../appMenu/logos/logoSmartpage.svg";
import { useNavigate } from "react-router-dom";

import styled from "@emotion/styled";

const StyledAppItem = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-start;
  margin: 5px 0;
  display: flex;
  flex-flow: row;
  align-items: center;
  border: 1px solid transparent;
  border-radius: 4px;
  padding: 4px;
  cursor: pointer;
  height: 28px;
  width: 300px;

  .custom-logo {
    flex: 0 0 30px;

    img {
      width: 26px;
    }
  }

  h4 {
    color: #000;
    font-weight: 700;
    font-size: 12px;
    letter-spacing: 1px;
    padding: 0;
    margin-top: -1px;
    margin-bottom: 0;
  }
`;

const AppContainer = styled.div`
  border-top: 1px solid lightgrey;
  margin: 0 6px;
`;

export const APP_NAME_LOGO_MAP = {
  flow: MiyaLogo,
  access: MiyaLogo,
  command: MiyaLogo,
  track: MiyaTrack, //PatientrackLogo,
  smart: MiyaSmartpage,
  configurator: MiyaLogo,
  miyasettings: MiyaLogo,
  operations: MiyaLogo,
  central: MiyaLogo,
  mrt: MiyaLogo,
  outpatient: MiyaPas,
  theatre: MiyaPas,
};

export const APP_LIST = [
  {
    name: "flow",
    label: "MIYA FLOW",
    path: "https://jade.demo.miya.cloud/MiyaFlow",
  },
  {
    name: "access",
    label: "MIYA ACCESS",
    path: "https://jade.demo.miya.cloud/MiyaAccess",
  },
  {
    name: "command",
    label: "MIYA COMMAND",
    path: "https://jade.demo.miya.cloud/MiyaCommand",
  },
  {
    name: "central",
    label: "MIYA CENTRAL",
  },
  {
    name: "mrt",
    label: "MIYA RESULTS TRACKING",
  },
  {
    name: "track",
    label: "PATIENTRACK",
  },
  {
    name: "smart",
    label: "SMARTPAGE",
  },
  {
    name: "configurator",
    label: "CONFIGURATOR",
  },
  {
    name: "miyasettings",
    label: "MIYA SETTINGS",
  },
  {
    name: "operations",
    label: "MIYA OPERATIONS",
  },
  {
    name: "outpatient",
    label: "MIYA PATIENT ADMIN",
    path: "/referral",
  },
];

const MiyaDotsIcon = (props) => {
  return (
    <svg
      stroke="currentColor"
      fill="none"
      strokeWidth="0"
      viewBox="0 0 24 24"
      color="#ffffff"
      height="30"
      width="30"
      xmlns="http://www.w3.org/2000/svg"
      style={{ color: "rgb(255, 255, 255)" }}
      {...props}
    >
      <path
        d="M8 6C8 7.10457 7.10457 8 6 8C4.89543 8 4 7.10457 4 6C4 4.89543 4.89543 4 6 4C7.10457 4 8 4.89543 8 6Z"
        fill="currentColor"
      ></path>
      <path
        d="M8 12C8 13.1046 7.10457 14 6 14C4.89543 14 4 13.1046 4 12C4 10.8954 4.89543 10 6 10C7.10457 10 8 10.8954 8 12Z"
        fill="currentColor"
      ></path>
      <path
        d="M6 20C7.10457 20 8 19.1046 8 18C8 16.8954 7.10457 16 6 16C4.89543 16 4 16.8954 4 18C4 19.1046 4.89543 20 6 20Z"
        fill="currentColor"
      ></path>
      <path
        d="M14 6C14 7.10457 13.1046 8 12 8C10.8954 8 10 7.10457 10 6C10 4.89543 10.8954 4 12 4C13.1046 4 14 4.89543 14 6Z"
        fill="currentColor"
      ></path>
      <path
        d="M12 14C13.1046 14 14 13.1046 14 12C14 10.8954 13.1046 10 12 10C10.8954 10 10 10.8954 10 12C10 13.1046 10.8954 14 12 14Z"
        fill="currentColor"
      ></path>
      <path
        d="M14 18C14 19.1046 13.1046 20 12 20C10.8954 20 10 19.1046 10 18C10 16.8954 10.8954 16 12 16C13.1046 16 14 16.8954 14 18Z"
        fill="currentColor"
      ></path>
      <path
        d="M18 8C19.1046 8 20 7.10457 20 6C20 4.89543 19.1046 4 18 4C16.8954 4 16 4.89543 16 6C16 7.10457 16.8954 8 18 8Z"
        fill="currentColor"
      ></path>
      <path
        d="M20 12C20 13.1046 19.1046 14 18 14C16.8954 14 16 13.1046 16 12C16 10.8954 16.8954 10 18 10C19.1046 10 20 10.8954 20 12Z"
        fill="currentColor"
      ></path>
      <path
        d="M18 20C19.1046 20 20 19.1046 20 18C20 16.8954 19.1046 16 18 16C16.8954 16 16 16.8954 16 18C16 19.1046 16.8954 20 18 20Z"
        fill="currentColor"
      ></path>
    </svg>
  );
};

const MiyaAppMenu = () => {
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();

  const handleClick = (path) => {
    if (!path) return; // do nothing
    if (path.startsWith("/")) {
      // If the path is relative, navigate
      navigate(path);
    } else {
      // If the path is a full URL, open in a new tab
      window.open(path, "_blank");
    }
  };

  return (
    <div>
      <Button onClick={() => setOpen(true)}>
        <MiyaDotsIcon />
      </Button>
      <Drawer
        anchor="left" //from which side the drawer slides in
        variant="temporary" //if and how easily the drawer can be closed
        open={open}
        onClose={() => setOpen(false)}
      >
        <h2
          className="heading"
          style={{
            fontSize: 16,
            fontWeight: 600,
            padding: 6,
            margin: 0,
            marginTop: 10,
          }}
        >
          Apps
        </h2>
        <Box>
          <AppContainer>
            {APP_LIST.map((a, index) => {
              return (
                // <StyledAppItem>
                //     <img src={svg} alt={a.label} />
                // </StyledAppItem>
                <StyledAppItem
                  key={`${a.name + index}`}
                  onClick={() => handleClick(a.path)}
                >
                  <div className="custom-logo">
                    <img src={APP_NAME_LOGO_MAP[a.name]} alt={a.label} />
                  </div>
                  <h4>{a.label}</h4>
                </StyledAppItem>
              );
            })}
          </AppContainer>
        </Box>
      </Drawer>
    </div>
  );
};

export default MiyaAppMenu;
