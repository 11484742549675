import React, { useState } from "react";
import {
  IconButton,
  Button,
  InputLabel,
  Datagrid,
  TextField,
  InputAdornment,
} from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import ManageSearchIcon from "@mui/icons-material/ManageSearch";
import  EquipmentDialogue from "./equipmentDialogue";

import styled from "styled-components";

const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;
const Column = styled.div`
  flex: 1;
  margin: 0% 5%;
  button {
    margin-top: 15px;
  }
  .btn-primary {
    background-color: #337ab7;
    border-color: #204d74;
    font-size: 14px;
    text-transform: none;
    color: #ffffff;
    &:hover {
      color: #fff;
      background-color: #286090;
      border-color: #204d74;
    }
  }
`;

function isNumeric(value) {
  return !isNaN(value) && typeof value !== 'boolean';
}

const EquipmentSelector = ({
  theatreCode,
  value,
  onChange,
  error
}) => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (equipmentArray) => {
    setOpen(false);
      onChange(equipmentArray);
  };

  const display = (obj) => {
    if(!obj || !obj.length) return '-'

    const text = `${obj.length} items`;
      return text; //`${}`;
  };

  return (
    <React.Fragment>
      <InputLabel className="input-label">Equipment</InputLabel>

      <TextField
        style={{ width: 300, marginTop: 0 }}
        value={display(value)}
        variant="outlined"
        InputLabelProps={{ shrink: false }}
        InputProps={{
          readOnly: true,
          endAdornment: (
            <InputAdornment>
              <IconButton>
                <ManageSearchIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
        onClick={handleClickOpen}
      error={error ? true:false }
      helperText={error}
        size="small"
      />
      <EquipmentDialogue
        value={value}
        theatreCode={theatreCode}
        open={open}
        onClose={handleClose}
      />
    </React.Fragment>
  );
};

export default EquipmentSelector;
