import React, { useState, useEffect } from "react";
import {
  TextField,
  Divider,
  Button,
  Box,
  FormControlLabel,
  Checkbox,
  InputLabel,
} from "@mui/material";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "dayjs/locale/en-gb";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";

import NavAppBar from "../components/navAppBar";
import ComboLookupCode from "../components/comboLookup";
import ComboProfDets from "../components/comboProfDets";
import ComboSpecialty from "../components/comboSpecialty";
import ComboOrgDets from "../components/comboOrgDets";
import ComboClinic from "../components/comboClinic";
import styled from "styled-components";
import "../config";
import InfoSnackbar from "../components/infoSnackbar";
import XDatePicker from "../components/xDatePicker";
import PatientSelector from "../components/patientSelector";
import BannerPatient from "../components/bannerPatient";
import { MonthPicker, DayOfWeekPicker} from '../components/frequencyPicker'
import {
  Form,
  Footer,
  Row,
  Column,
  SectionTitle,
  VSpace,
} from "../components/formLayout";


const BoxButtons = styled.div`
  display: flex;
  flex-direction: row-reverse;
  background-color: #4a4c52;
  & button {
    margin: 10px;
  }
`;

const ReferralContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  .input-label {
    font-size: 12px;
  }

`;

// Function to format date as "DD/MMM/YYYY"
const formatDate = (inputDate) => {
  const options = { day: "2-digit", month: "short", year: "numeric" };
  return new Date(inputDate).toLocaleDateString("en-GB", options);
};

const ReferralForm = ({ match }) => {
  const navigate = useNavigate();
  const { patientId } = useParams();

  const [referralId, setReferralId] = useState();
  const [formData, setFormData] = useState({
    internalPatientId: patientId,
    patientObj: null,
    referralDate: null, // "11/JAN/2024"
    referralTime: null, // null
    referralMethodCode: null, // "E"
    dateRecorded: null, // "11/JAN/2024"
    receivingLocation: null, // "OPD"
    referringSourceCode: null, // "03"
    referringType: null, // "SURG"
    referralSource: null, // "A86018"
    referringPersonType: null, // "GMP"
    referringPerson: null, // "G9803099"
    referringDiscipline: null, // null
    referralReason: null, // "A"
    referralPriority: null, // "U"
    suspectedCancer: null, // "Y"
    suspectedCancerTypeCode: null, // "13"
    referralReference: null, // null
    referredToPerson: null, // "HAPA"
    referredToSpecialty: null, // "GS"
    referralText: null, // null
    currentLocation: null, // "CONS"
    diagnosticType: null, // null
    diagnosticCode: null, // null
    diagnosticText: null, // null
    closureDate: null, // null
    closureTime: null, // null
    personTypeClosing: null, // "NC"
    personClosing: null, // "RAND"
    closingReason: null, // "D"
    usedCount: null, // null
    userCodeType: null, // null
    userCode1: null, // null
    userCode2: null, // null
    userCode3: null, // null
    userCode4: null, // null
    userCode5: null, // null
    userCode6: null, // null
    userCode7: null, // null
    userCode8: null, // null
    userCode9: null, // null
    userCode10: null, // null
    refOrg: null, // null
    rtt: null, // null
    refCtxt: null, // null
  });
  const [showInfoSnack, setShowInfoSnack] = useState();
  const [openWaitlistQuestion, setOpenWaitlistQuestion] = useState(false);
  const resturl =
    global.config.restAPI.url + global.config.restAPI.endpoints.referrals + "/";

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setFormData({
      ...formData,
      [name]: checked,
    });
  };

  const handleComboChange = (domain, val) => {
    setFormData({ ...formData, [domain]: val });
  };

  const handleSave = () => {
    // Fake a POST request to save or update the data
    setTimeout(() => {
      setShowInfoSnack(`Element Created ()`);
    }, 500);

    setTimeout(() => {
      navigate(-1)
    }, 700);

  };

  return (
    <ReferralContainer>
      <NavAppBar title="Add Clinic Element"></NavAppBar>
      {(patientId || formData.patientObj) && (
        <BannerPatient patientRec={formData.patientObj} patientId={patientId} />
      )}
      <Form>
        <Box spacing={2} style={{ margin: 20 }}>
          <SectionTitle>Clinic Details</SectionTitle>
          <Row>
            <Column>
              <ComboClinic
                label="Owning Clinic"
                value={formData.clinicCode}
                onChange={(code) => {
                  setFormData({ ...formData, clinicCode: code });
                  // auto populate specialty and consultant
                }}
              />
            </Column>
            <Column>
              <LocalizationProvider
                dateAdapter={AdapterDayjs}
                adapterLocale="en-gb"
              >
                <InputLabel className="input-label">Date From</InputLabel>
                <XDatePicker
                  value={formData.dateOnList}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(newValue) =>
                    setFormData({ ...formData, dateOnList: newValue })
                  }
                />
              </LocalizationProvider>
</Column>
            </Row>
          <SectionTitle>Session Details</SectionTitle>
<Row>
           <Column> 
              <InputLabel className="input-label">Element Description</InputLabel>
              <TextField
                placeholder="General Appts"
                name=""
                defaultValue=""
                value={formData.referralText}
                onChange={handleInputChange}
                size="small"
                style={{ width: 690, marginTop: 0 }}
              />
            </Column>
            </Row>
  <VSpace/>
<Row>
              <Column>
                <InputLabel className="input-label">
                  Specialty
                </InputLabel>
                <ComboSpecialty
                  name="referringDiscipline"
                  value={formData.referringDiscipline}
                  onChange={(code) => {
                    setFormData({ ...formData, referringDiscipline: code });
                  }}
                  slotProps={{
                    textField: {
                      size: "small",
                      style: { width: 300, marginTop: 0 },
                    },
                  }}
                />
              </Column>
            <Column>
              <ComboProfDets
                label="Element Run By" /// add auto populated from clinic rest
                value={formData.consultant}
                type="consultant"
                specialty={formData.specialty}
                onChange={(code) => {
                  setFormData({ ...formData, consultant: code });
                }}
              />
            </Column>
            </Row>
<Row>
            <Column>
              <InputLabel className="input-label">
               Profession 
              </InputLabel>
              <ComboProfDets
                name="referredToPerson"
                value={formData.referredToPerson}
                type="consultant"
                specialty={formData.referredToSpecialty}
                onChange={(code) => {
                  setFormData({ ...formData, referredToPerson: code });
                }}
              />
            </Column>
            <Column>
                    <InputLabel className="input-label">
                      Slot Type
                    </InputLabel>
                    <ComboLookupCode
                      name="appointmenttypecode"
                      value={formData.appointmenttypecode}
                      refDomain="appointmentType"
                      onChange={(newData) => {
                        handleComboChange("appointmenttypecode", newData);
                      }}
                    />
            </Column>
  </Row>
          <SectionTitle>Schedule Details</SectionTitle>
          <Row>
            <Column>
              <InputLabel className="input-label">Months of the Year</InputLabel>
    <MonthPicker />
            </Column>
</Row>
<VSpace/>
<Row>
            <Column>
              <InputLabel className="input-label">Day of the Week</InputLabel>
    <DayOfWeekPicker />
            </Column>
</Row>
<Row>
            <Column>

              <InputLabel className="input-label">Weekly Frequency</InputLabel>
              <TextField
                name="noticeReq"
                type="numeric"
                defaultValue="1"
                value={formData.noticeReq}
                onChange={handleInputChange}
                size="small"
                style={{ width: 300, marginTop: 0 }}
              />
              <InputLabel className="input-label">Weekly Cycle</InputLabel>
              <TextField
                name="noticeReq"
                type="numeric"
                defaultValue="1"
                value={formData.noticeReq}
                onChange={handleInputChange}
                size="small"
                style={{ width: 300, marginTop: 0 }}
              />
              <InputLabel className="input-label">Weeks of Month</InputLabel>
              <TextField
                type="numeric"
                defaultValue="1"
                size="small"
                style={{ width: 300, marginTop: 0 }}
              />
            </Column>
          </Row>


          <InfoSnackbar
            showMessage={showInfoSnack}
            onClose={() => setShowInfoSnack(false)}
          />
        </Box>
      </Form>
      <Footer>
        <div>
          <BoxButtons>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSave}
              className="btn-primary"
            >
              Save
            </Button>
            <Button
              variant="outlined"
              onClick={() => {
                setShowInfoSnack("Element Created");
                navigate(-1);
              }}
              className="btn-cancel"
            >
              Cancel
            </Button>
          </BoxButtons>
        </div>
      </Footer>
    </ReferralContainer>
  );
};

export default ReferralForm;
