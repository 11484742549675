// pages/ClinicMgr.js
import React, { useState, useEffect } from "react";
import axios from "axios";
import styled from "styled-components";
import { useParams, useNavigate } from "react-router-dom";
import { Button, FormControl, MenuItem, Select } from "@mui/material";
import XButtonGroup from "../../components/xButtonGroup";
import AddIcon from "@mui/icons-material/Add";
import ClinicCalendar from "./clinicCalendar";
import SearchByTypesForm from "../../components/searchByTypesForm";
import PlaylistAddCheckIcon from "@mui/icons-material/PlaylistAddCheck";
import "../../config";

const FormContainer = styled.div`
  background-color: #f4f4f4; //gray
  display: flex;

  &.alt {
    background-color: #3b4e6b; //darkblue
    height: 58px;
    display: flex;
    padding-left: 20px;
    color: white;
    justify-content: flex-start;
    align-items: flex-end;
  }
  & h2 {
    padding-right: 25px;
    font-size: 18px;
  }

  align-items: center;

  & div.flex1 {
    flex: 1;
  }
  & div.view-mode {
    padding-left: 20px;
  }
`;

const MainContainer = styled.div`
  height: 90vh;
  width: 100vw;

  display: flex;
  flex-direction: column;
  flex: 1;

  .title {
    background-color: #4a4c52;
    height: 40px;
    text-align: center;

    h2 {
      margin: 8px;
      color: #ffffff;
      font-size: 18px;
    }
  }

  .search-btn {
    margin-top: 4px;
    margin-left: 14px;
  }
`;

export const GridContainer = styled.div`
  .MuiDataGrid-columnHeaders {
    background-color: #ebf3fc;

    .MuiDataGrid-columnHeader {
      border-right: 1px solid #ededed;
    }
  }

  .MuiDataGrid-cell {
    border-right: 1px solid #ededed;
  }

  .MuiDataGrid-row {
    &:nth-child(even) {
      background-color: #fafafa;
    }
  }
  flex: 1;
  overflow: scroll;
`;

const ClinicTabBookings = ({ tab, onViewModeChange }) => {
  const [clinics, setClinics] = useState();
  const [slots, setSlots] = useState();
  const cfg = global.config.restAPI;
  const navigate = useNavigate();
  //const [viewMode, setViewMode] = useState("Bookings");
  const viewMode = "Calendar";
  const specialtyCode = tab; // Get the speciality from the tab code

  const query = { upcoming: "true" };

  const getSlotQuery = (clinicCode, startDate) => {
    const query = {
      clinic: { $eq: clinicCode },
      slotdatesearchable: { $gt: { $date: startDate } },
      //slotdatesearchable:{$lt: endDate}
    };

    return JSON.stringify(query);
  };

  useEffect(() => {
    // Fetch referral detailData s based on the ID from the URL params
    if (specialtyCode === "ALL") {
      const query = { slotdatesearchable: { $gt: { $date: new Date() } } };
      axios
        .get(
          `${cfg.url + cfg.endpoints.clinicSessionSlots}/?q=${JSON.stringify(query)}&limit=10`,
        )
        .then((response) => {
          setSlots(response.data.items);
        });
    } else {
      axios
        .get(
          `${cfg.url + cfg.endpoints.clinicSessions}/?q={"spec":{"$eq":"${specialtyCode}"}}`,
        )
        .then((response) => {
          const items = response.data.items;
          setClinics(items);

          let promises = items.map((item) => {
            const clinicCode = item.clinic;
            return axios.get(
              `${cfg.url + cfg.endpoints.clinicSessionSlots}/?q=${getSlotQuery(clinicCode, new Date())}`,
            );
          });

          Promise.all(promises)
            .then((responses) => {
              const s = [];
              responses.forEach((r) => s.push(...r.data.items));
              setSlots(s);
            })
            .catch((error) => {
              console.log(error);
            });
        });
    }
  }, [specialtyCode, cfg]);

  const handleSelect = (id, rowData) => {
    navigate(
      `/clinic/patient/${rowData.internalpatientid}/referral/${rowData.referralnumber}/appointment/${rowData.uniqueepisodeappointmentnumber}`,
    );
  };

  const handleFormSubmit = (formData) => {
    // Filtering out empty values from formData
    const nonEmptyFormData = Object.fromEntries(
      Object.entries(formData).filter(([key, value]) => {
        return value && value !== "";
      }),
    );

    const query = {}; // firstspecialty: { $eq: tab } }; //, appointmentdate: { $gt: "01/01/2001" } };
    if (tab !== "ALL") query.firstspecialty = { $eq: tab };

    for (const [key, value] of Object.entries(nonEmptyFormData)) {
      if (key.includes("date")) query[key] = { $gt: { $date: value.utc() } };
      else if (key.includes("patientnameupper"))
        query[key] = { $instr: value.toUpperCase() };
      else query[key] = { $eq: value };
    }
  };

  return (
    <MainContainer>
      <FormContainer>
        <div className={["view-mode"]}>
          <XButtonGroup
            key="avail"
            value={viewMode}
            list={["Bookings", "Calendar", "Availability"]}
            onChange={onViewModeChange}
          />
        </div>
        <div className={["flex1"]}>
          <SearchByTypesForm
            title=""
            onSubmit={handleFormSubmit}
            defaultQuery={query}
            types={[
              "firstclinic",
              "firstspecialty",
              "appointmentsearchabledate",
              "appointmentstatuscode",
              "attendancetypecode",
              "appointmentprioritycode",
              "bookingsystem",
            ]}
          >
            {tab !== "ALL" && (
              <Button
                type="primary"
                className="search-btn"
                variant="outlined"
                startIcon={<PlaylistAddCheckIcon />}
                onClick={() => {
                  window
                    .open(global.config.miya.demoClinic, "miyaFlow")
                    .focus();
                }}
              >
                Miya Clinic
              </Button>
            )}
            <Button
              className="search-btn"
              type="primary"
              variant="outlined"
              startIcon={<AddIcon />}
              onClick={() => navigate(`/clinic/${tab}/appointment/create`)}
            >
              Appointment
            </Button>
          </SearchByTypesForm>
        </div>
      </FormContainer>
      {slots && (
        <GridContainer style={{}}>
          <ClinicCalendar
            clinicSlots={slots}
            onSelect={() => console.log("clicked row")}
          />
        </GridContainer>
      )}
     
      {!slots && <p style={{ marginLeft: 24 }}>Loading...</p>}
    </MainContainer>
  );
};

export default ClinicTabBookings;
