import React, { useState } from "react";
import axios from "axios";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import Button from "@mui/material/Button";
import PrintGrid from "../components/printGrid";
import styled from "styled-components";
import { useNavigate, useParams } from "react-router-dom";
import "../config";
import { Footer } from "../components/formLayout";
import ComboLookupCode from "../components/comboLookup";

const BoxButtons = styled.div`
  display: flex;
  flex-direction: row-reverse;
  background-color: #4a4c52;
  & button {
    margin: 10px;
  }
`;
const FormContainer = styled.div`
  background-color: #f4f4f4;
  padding: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 50px;
  & div{
    margin-right: 10px;
  }
`;

const PatientSearchContainer = styled.div`
  height: 90vh;
  display: flex;
  flex-direction: column;
  flex: 1;

  .title {
    background-color: #4a4c52;
    height: 40px;
    text-align: center;

    h2 {
      margin: 8px;
      color: #ffffff;
      font-size: 18px;
    }
  }
`;

export const PatientListContainer = styled.div`
  .MuiDataGrid-columnHeaders {
    background-color: #ebf3fc;

    .MuiDataGrid-columnHeader {
      border-right: 1px solid #ededed;
    }
  }

  .MuiDataGrid-cell {
    border-right: 1px solid #ededed;
  }

  .MuiDataGrid-row {
    &:nth-child(even) {
      background-color: #fafafa;
    }
  }
`;

function AppointmentConfirm(props) {
  const { onClose, value, open, onCreate} = props;

  return (
    <Dialog
      fullWidth={true}
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          maxWidth: "lg",
          minHeight: "70vh",
        },
      }}
    >
      <DialogTitle>Followup Appointment?</DialogTitle>
      <PatientSearchContainer>
        Do you want to book a new appointment
      </PatientSearchContainer>
      <Footer>
        <div>
          <BoxButtons>
            <Button
              variant="contained"
              color="primary"
              onClick={onCreate}
              className="btn-primary"
            >
              Ok
            </Button>
            <Button variant="outlined" onClick={onClose} className="btn-cancel">
              Cancel
            </Button>
          </BoxButtons>
        </div>
      </Footer>
    </Dialog>
  );
}
export default AppointmentConfirm;
