import React, { useState } from "react";
import { DataGrid } from "@mui/x-data-grid";

const columns = [
  { field: 'pathwayid', headerName: 'Pathway ID', width: 180 },
  { field: 'sequence', headerName: 'Sequence', width: 120 },
{ field: 'referraldaterecd', headerName: 'Date Received', width: 200 },
 
  { field: 'referringsourcecode', headerName: 'Source', width: 100 },
  { field: 'referredtospecialty', headerName: 'Specialty', width: 100 },
  { field: 'referredtoperson', headerName: 'Referred to ', width: 180 },
  { field: 'currentreferredspec', headerName: 'Current Referred Specialty', width: 220 },
  { field: 'status', headerName: 'Status', width: 100 },
  
  { field: 'breachdate', headerName: 'Breach Date', width: 150 },
];

const RttPatientGrid = ({ rttList, onSelect }) => {
  const [selectedRowIds, setSelectedRowIds] = useState([]);

  const handleRowClick = (params) => {
    console.log("Selected Row ID:", params.id);
    console.log("Selected Row Data:", params);
    onSelect(params.id);
  };
  return (
    <DataGrid
      rows={rttList}
      columns={columns}
      pageSize={10}
      rowsPerPageOptions={[10, 25, 50, 100]}
      pagination
      autoHeight
      onRowClick={handleRowClick}
      getRowId={(row) => row.pathwayid}
      columnHeaderHeight={25}
    />
  );
};

export default RttPatientGrid;
