import styled from "styled-components";

export const MainContainer = styled.div`
  height: 90vh;
  width: 100vw;

  display: flex;
  flex-direction: column;
  flex: 1;

  .title {
    background-color: #4a4c52;
    height: 40px;
    text-align: center;

    h2 {
      margin: 8px;
      color: #ffffff;
      font-size: 18px;
    }
  }

  .search-btn {
    margin-top: 4px;
    margin-left: 14px;
  }
`;

export const GridContainer = styled.div`
  .MuiDataGrid-columnHeaders {
    background-color: #ebf3fc;

    .MuiDataGrid-columnHeader {
      border-right: 1px solid #ededed;
    }
  }

  .MuiDataGrid-cell {
    border-right: 1px solid #ededed;
  }

  .MuiDataGrid-row {
    &:nth-child(even) {
      background-color: #fafafa;
    }
  }
  flex: 1;
  overflow: scroll;
`;

export const FormContainer = styled.div`
  background-color: #f4f4f4; //gray
  display: flex;

  &.alt {
    background-color: #3b4e6b; //darkblue
    height: 58px;
    display: flex;
    padding-left: 20px;
    color: white;
    justify-content: flex-start;
    align-items: flex-end;
  }
  & h2 {
    padding-right: 25px;
    font-size: 18px;
  }

  align-items: center;

  & div.flex1 {
    flex: 1;
  }
  & div.view-mode {
    padding-left: 20px;
  }
`;

export const Tab = styled.div`
  color: black;
  height: 49px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  color: rgb(213 213 213); // white tab text;
  &.selected {
    background: rgb(205, 205, 205); //gray
    background: linear-gradient(
      180deg,
      rgba(205, 205, 205, 1) 0%,
      rgba(224, 224, 224, 1) 17%,
      rgba(244, 244, 244, 1) 100%
    );
    color: #323232; //black text;
  }
  &:hover:not(.selected) {
    color: white; //#323232; //black text;
  }
  & div {
    padding-left: 15px;
    padding-right: 15px;
    font-size: 13px;
    font-weight: bold;
  }
  & div:last-child {
    padding-bottom: 5px;
  }
`;
