// pages/PatientList.js
import React, { useState } from "react";
import axios from "axios";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

import PatientGrid from "../components/patientGrid";
import SearchForm from "../components/searchForm";
import "../config";

const FormContainer = styled.div`
  background-color: #f4f4f4;
  padding: 20px;
`;

const PatientSearchContainer = styled.div`
  height: 90vh;
  display: flex;
  flex-direction: column;
  flex: 1;

  .title {
    background-color: #4a4c52;
    height: 40px;
    text-align: center;

    h2 {
      margin: 8px;
      color: #ffffff;
      font-size: 18px;
    }
  }
`;

export const PatientListContainer = styled.div`
  .MuiDataGrid-columnHeaders {
    background-color: #ebf3fc;

    .MuiDataGrid-columnHeader {
      border-right: 1px solid #ededed;
    }
  }

  .MuiDataGrid-cell {
    border-right: 1px solid #ededed;
  }

  .MuiDataGrid-row {
    &:nth-child(even) {
      background-color: #fafafa;
    }
  }
`;

const PatientList = () => {
  const [patients, setPatients] = useState([]);
  const cfg = global.config.restAPI;
  const navigate = useNavigate();

  const handleSelect = (id,row) => {
    navigate(`/referral/patient/${id}`);
  };

  const handleFormSubmit = (formData) => {
    const nonEmptyFormData = Object.fromEntries(
        Object.entries(formData).filter(([key, value]) => {
          return value && value !== "";
        }),
    );

    const query = {};
    for (const [key, value] of Object.entries(nonEmptyFormData)) {
      if (key.includes("date")) query[key] = { $gt: value };
      else if (key === "patientnameupper")
        query[key] = { $instr: value.toUpperCase() };
      else query[key] = { $eq: value };
    }
    // Fetch from the API
    axios
        .get(`${cfg.url + cfg.endpoints.patients}/?q=${JSON.stringify(query)}`)
        .then((response) => {
          setPatients(response.data.items);
        })
        .catch((error) => {
          console.log(error);
        });
  };

  return (
    <PatientSearchContainer>
      <div className="title">
        <h2>Patient Search</h2>
      </div>
      <FormContainer>
        <SearchForm onSubmit={handleFormSubmit} />
      </FormContainer>
      {patients && (
        <PatientListContainer style={{ flex: 1, overflow: "scroll" }}>
          <PatientGrid patients={patients} onSelect={handleSelect} />
        </PatientListContainer>
      )}
      {!patients && <p style={{ marginLeft: 24 }}>Loading...</p>}
    </PatientSearchContainer>
  );
};

export default PatientList;
