import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import NavAppBar from "../components/navAppBar";
import {Box, Menu, MenuItem} from "@mui/material";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import "../config";
import styled from "styled-components";
import DisplaySection from "../components/displaySection";
import AddIcon from "@mui/icons-material/Add";
import BannerWard from "../components/bannerWard";

const BannerBox = styled.div`
  background: rgb(234, 235, 242);
`

export const WaitListDetailsContainer = styled.div`
  .title-bar {
    .back-btn {
      margin-right: 150px;
    }
    .title {
      margin-right: 0;
    }
  }
`;

const labelMapping = {
  "patient_information": {
    "internalpatientid": "Internal Patient ID",
    "episodenumber": "Episode Number",
    "pasid": "PAS ID",
    "nhs_id": "NHS ID",
    "patientsurname": "Patient Surname",
    "patientfirstname": "Patient First Name",
    "patientmiddlename": "Patient Middle Name",
    "title": "Title",
    "patientname": "Patient Name",
    "patientnameupper": "Patient Name (Upper Case)",
    "originaldateonlist": "Original Date on List",
    "currentdateonlist": "Current Date on List",
    "entrystatuscode": "Entry Status Code",
    "entrystatusdescription": "Entry Status Description",
  },
  "appointment_information": {
    "appointmentprioritycode": "Appointment Priority Code",
    "appointmentprioritydescription": "Appointment Priority Description",
    "consultant": "Consultant",
    "specialty": "Specialty",
    "clinic": "Clinic",
    "clinicgroup": "Clinic Group",
    "locationtype": "Location Type",
    "location": "Location",
    "owninghospital": "Owning Hospital",
    "expectedbookingsystemcode": "Expected Booking System Code",
    "ebsdescription": "EBS Description",
    "estimatedcontactdate": "Estimated Contact Date",
    "appointmentprocedure": "Appointment Procedure"
  },
  "referral_information": {
    "referralnumber": "Referral Number",
    "opepisodenumber": "OP Episode Number",
    "opappointmentnumber": "OP Appointment Number",
    "completeddate": "Completed Date",
    "removaldate": "Removal Date",
    "removalreasoncode": "Removal Reason Code",
    "removalreasondescription": "Removal Reason Description",
    "removaltextcomment": "Removal Text Comment"
  },
  "provider_and_commissioner": {
    "providercode": "Provider Code",
    "commissionercode": "Commissioner Code",
    "slacodeid": "SLA Code ID",
    "contractreference": "Contract Reference"
  },
  "review_and_status": {
    "reviewindicator": "Review Indicator",
    "lastreviewsequence": "Last Review Sequence",
    "reviewdate": "Review Date",
    "shortcallavailabilityindicator": "Short Call Availability Indicator",
    "noticerequired": "Notice Required",
    "transportrequired": "Transport Required",
    "trasnferfromclinic": "Transfer From Clinic",
    "transferfromconsultant": "Transfer From Consultant",
    "transferfromspecialty": "Transfer From Specialty",
    "transferfromdate": "Transfer From Date",
    "pendingstatus": "Pending Status",
    "currentsequence": "Current Sequence"
  },
  "hddr_and_comments": {
    "hddr_group": "HDDR Group",
    "freetextcomments": "Free Text Comments"
  },
  "patient_category_and_appointment_flags": {
    "patientcategorycode": "Patient Category Code",
    "patientcategorydescription": "Patient Category Description",
    "appointmentslottypecode": "Appointment Slot Type Code",
    "appointmentslottypedescription": "Appointment Slot Type Description",
    "coronavirusprioritycode": "Coronavirus Priority Code",
    "coronaviruscategorycode": "Coronavirus Category Code",
    "coronavirussubcategorycode": "Coronavirus Subcategory Code",
    "cornonavirusdate": "Coronavirus Date",
    "plannedappointmentflagcode": "Planned Appointment Flag Code",
    "plannedappointmentflagdescription": "Planned Appointment Flag Description",
    "plannedappointmentdate": "Planned Appointment Date",
    "plannedappointmentnotes": "Planned Appointment Notes"
  },
  "ubrn": {
    "ubrn": "UBRN"
  }
};

const WardDetails = () => {
  const [wardAttenderItem, setWardAttenderItem] = useState(null);
  const [menuAnchorEl, setMenuAnchorEl] = React.useState(null);
  const navigate = useNavigate();
  const { patientId, referralId, wardCode } = useParams();
  const cfg = global.config.restAPI; //"http://localhost:3000/";

  useEffect(() => {
    // Fetch referral details based on the ID from the URL params
    const query = {
      internalpatientid: { $eq: patientId },
      referralnumber: { $eq: referralId },
      wardcode: { $eq: wardCode },
    };
    axios
      .get(
        `${cfg.url + cfg.endpoints.wardattender}/?q=${JSON.stringify(query)}`,
      )
      .then((response) => {
        const firstItem = response.data.items[0];
        setWardAttenderItem(firstItem);
      });
  }, [patientId, referralId, wardCode, cfg]);

  if (!wardAttenderItem) {
    return <p style={{ marginLeft: 24 }}>Loading...</p>;
  }

  const menuOpen = Boolean(menuAnchorEl);

  const handleMenuClick = (event) => {
    setMenuAnchorEl(event.currentTarget);
  };
  const handleMenuClose = (e) => {
    setMenuAnchorEl(null);
    switch(e.currentTarget.innerText){
      case "Appointment List":
        navigate(`../patient/${patientId}/referral/${referralId}/appointment/`)
        break;
     default:
      return
    } 
  };

  return (
    <WaitListDetailsContainer style={{ background: "#eaebf2", flex: 1 }}>
      <NavAppBar title="Ward Attender Item Details">
        <Button
          type="primary"
          variant="outlined"
          startIcon={<AddIcon />}
          onClick={() =>
            navigate(
              `../patient/${patientId}/referral/${referralId}/wardAttender/create`,
            )
          }
        >
          Attender
        </Button>
        <Button variant="outlined" style={{ marginLeft: 10 }}
          onClick={handleMenuClick}
>
          More
        </Button>
        <Menu anchorEl={menuAnchorEl} open={menuOpen} onClose={handleMenuClose}>
          <MenuItem onClick={handleMenuClose}>Amend Ward Attendance</MenuItem>
          <MenuItem onClick={handleMenuClose}>Convert to Inpatient</MenuItem>
          <MenuItem onClick={handleMenuClose}>Create Ward Attendance</MenuItem>
          <MenuItem onClick={handleMenuClose}>Manage Clinical Details</MenuItem>
          <MenuItem onClick={handleMenuClose}>Outpatient Appointment (Follow Up)</MenuItem>
          <MenuItem onClick={handleMenuClose}>Update Ward Coding</MenuItem>
        </Menu>
      </NavAppBar>

      <BannerBox>
      <BannerWard referralId={referralId} patientId={patientId} onSelect={() => navigate(`/referral/patient/${patientId}/referral/${referralId}`)}/>
      </BannerBox>

      <DisplaySection
        title="Patient Information"
        labelMap={labelMapping.patient_information}
        data={wardAttenderItem}
      />
      <DisplaySection
          title="Appointment Details"
          labelMap={labelMapping.appointment_information}
          data={wardAttenderItem}
      />
      <DisplaySection
          title="Referral Details"
          labelMap={labelMapping.referral_information}
          data={wardAttenderItem}
      />
      <DisplaySection
          title="Provider and Commissioner Details"
          labelMap={labelMapping.provider_and_commissioner}
          data={wardAttenderItem}
      />
      <DisplaySection
          title="Review Details"
          labelMap={labelMapping.review_and_status}
          data={wardAttenderItem}
      />
      <DisplaySection
          title="Appointment Details"
          labelMap={labelMapping.hddr_and_comments}
          data={wardAttenderItem}
      />
      <DisplaySection
        title="Flags and Other Details"
        labelMap={labelMapping.patient_category_and_appointment_flags}
        data={wardAttenderItem}
      />
    </WaitListDetailsContainer>
  );
};

export default WardDetails;
