import React from "react";
import styled from "styled-components";
import { Button, FormControl, MenuItem, Select } from "@mui/material";

const XButtonGroup = ({value, list, onChange}) => {
  return (
    <Select
          value={value}
          onChange={onChange}
style={{ height: 30, width: 180}}
        >
      {list.map((buttonText,idx) => (
          <MenuItem key={idx} value={buttonText}>{buttonText} View</MenuItem>
      ))}
      </Select>
  );
};

export default XButtonGroup;
