import React, { useState, useEffect } from "react";
import { Dialog, DialogActions, Button } from "@mui/material";
import axios from "axios";
import DialogTitle from "@mui/material/DialogTitle";
import { useNavigate, useParams } from "react-router-dom";
import Sequencer from "./sequencer";
import "../../config";

function SequencingDialogue(props) {
  const { onClose, open, sessionCode, bookings } = props;
  const [sessions, setSessions] = useState();
  const [demoBookings, setDemoBookings] = useState();
  const cfg = global.config.restAPI;

  useEffect(() => {
    if (!sessionCode) return;

    // Fetch session details based on the ID from the URL params
    const query = {
      sessiondatesearchable: { $gt: { $date: new Date() } },
      theatresessioncode: { $eq: sessionCode },
      //searchablesessiondate: { $gt: sessionDate?.replace(/(\d{2})(\d{2})(\d{4})/, "$1/$2/$3")},
    };
    axios
      .get(
        `${cfg.url + cfg.endpoints.theatre.sessions}/?q=${JSON.stringify(query)}`,
      )
      .then((response) => {
        setSessions(response.data.items);
      });

    if (!bookings || bookings.length === 0) {
      const query2 = `${cfg.url + cfg.endpoints.theatre.procedure}`;
      const p1 = axios.get("demoBookings.json");
      const p2 = axios.get(query2);
      Promise.all([p1, p2]).then((responses) => {
        const bookings = responses[0].data;
        const procs = responses[1].data.items;

        const bookings_merged = bookings.map((b) => {
          const mainproc_obj = procs.find(
            (p) => b.mainprocedure === p.procedurecode,
          );
          if (mainproc_obj )
            return { ...b, mainsidedescription: mainproc_obj.description };
          else
            return b;
        });
        setDemoBookings(bookings_merged);
      });
    }
  }, [sessionCode, bookings, cfg]);

  const handleSubmit = () => {
    onClose();
  };

  return (
    <Dialog
      fullWidth={true}
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          maxWidth: "lg",
          minHeight: "80vh",
        },
      }}
    >
      <DialogTitle>Session Sequencing - {sessionCode}</DialogTitle>
      <div>
        {sessions && demoBookings && (
          <Sequencer bookings={demoBookings} sessions={sessions} />
        )}
        <DialogActions>
          <form onSubmit={handleSubmit}>
            <Button
              type="submit"
              variant="outlined"
              color="primary"
              style={{ marginRight: 10 }}
            >
              Cancel
            </Button>

            <Button type="submit" variant="contained" color="primary">
              Save
            </Button>
          </form>
        </DialogActions>
      </div>
    </Dialog>
  );
}
export default SequencingDialogue;
