import React from "react";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import 'dayjs/locale/en-gb';

const XDatePicker = ({ label, value, onChange, error }) => {
  return (
    <DatePicker
      label={label}
      value={value}
      onChange={onChange}
      slotProps={{ 
        textField: { size: "small", InputLabelProps: { shrink: false }, style: {width: 300, marginTop: 0},
      error:(error ? true:false ),
      helperText:error} }}
    />
  );
};

export default XDatePicker;
