import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  Menu,
  MenuItem,
  InputAdornment
} from "@mui/material";
import styled from "styled-components";
import SearchIcon from "@mui/icons-material/Search";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "dayjs/locale/en-gb";
import XDatePicker from "../components/xDatePicker";

import ComboSpecialty from "../components/comboSpecialty";
import ComboClinic from "../components/comboClinic";
import ComboOrgDets from "../components/comboOrgDets";
import ComboLookupCode from "../components/comboLookup";
import ComboClinicGroup from "../components/comboClinicGroup";
import ComboProcedure from "../components/comboProcedure";

const Filter = styled.div`
  background: white;
`;

const MenuLayout = styled.div`
  margin: 10px;
  .input-label {
    color: #000;
    font-size: 12px;
    font-weight: 600;
  }
`;

const TopParent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;
const ActionPanel = styled.div`
  padding-right: 20px;
`;

const GridParent = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  flex: 1;
`;

const GridItem = styled.div`
  display: block;
  padding: 0px 4px;
  align-items: center;

  & > button {
    margin-top: 4px;
  }

  &.btn-group {
    margin-top: 15px;
    text-align: right;
  }

  .btn-primary {
    background-color: #337ab7;
    border-color: #204d74;
    font-size: 14px;
    text-transform: none;
    &:hover {
      color: #fff;
      background-color: #286090;
      border-color: #204d74;
    }
  }

  &.small-cell {
    max-width: 120px;
  }

  & > div {
    background-color: #fff;
  }

  .input-label {
    color: #333;
    font-size: 12px;
    font-weight: 700;
  }

  .filter-results {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    background-color: rgb(255, 249, 219);
    padding: 6px 30px 6px 12px;
    border-radius: 4px;
    font-size: 14px;
    margin-top: 4px;

    & > div {
      margin-right: 5px;
      padding-left: 5px;
      border: 1px solid rgb(229, 230, 235);
      border-radius: 2px;
      height: 21px;
      display: inline-flex;
    }

    .caption {
      font-size: 14px;
      font-weight: 700;
      text-transform: capitalize;
    }

    .tag-name {
      font-size: 14px;
      font-weight: 600;
    }

    button {
      padding: 0;
      min-width: 30px;
    }
  }
`;

function isNumeric(value) {
  return !isNaN(value) && typeof value !== "boolean";
}

const SearchByTypeForm = ({
  title,
  onSubmit,
  types,
  defaultQuery,
  children,
}) => {
  const [menuAnchorEl, setMenuAnchorEl] = React.useState(null);
  const [searchText, setSearchText] = React.useState(null);

  const nullQuery = {
    pasid: null,
    patientnameupper: null,
    specialty: null,
    clinic: null,
    gp: null,
    currentdateonlistsearchable: null, //'2024-01-01',
  };
  const nnn = { ...nullQuery, ...defaultQuery };
  const [formData, setFormData] = useState(nnn);
  const handleTextChange = (e) => {
    setSearchText(e.target.value);
  };

  const handleKeyDown = (event) => {
    if (event.keyCode === 13) {
      let d = {};
      if (isNumeric(searchText)) {
        d = { ...formData, pasid: searchText, patientnameupper: null };
      } else if (types.includes("searchClinic")) {
        d = { ...formData, pasid: null, clinicSearch: searchText };
      } else {
        d = { ...formData, pasid: null, patientnameupper: searchText };
      }
      onSubmit(d);
      setFormData(d);
    }
  };

  const handleComboChange = (name, newValue) => {
    const d = { ...formData, [name]: newValue };
    onSubmit(d);
    setFormData(d);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const fd = {};
    if (isNumeric(formData.pasid)) {
      fd.pasid = formData.pasid;
    } else {
      fd.patientnameupper = formData.pasid;
    }

    //onSubmit(fd);
  };

  const menuOpen = Boolean(menuAnchorEl);

  const handleMenuClick = (event) => {
    setMenuAnchorEl(event.currentTarget);
  };
  const handleMenuClose = (e) => {
    setMenuAnchorEl(null);
  };

  const onClearFilter = (e) => {
    const d = { ...formData, [e.target.name]: null };
    onSubmit(d);
    setFormData(d);
  };

  const currentFilters = () => {
    const keyMap = {
      pasid: "PAS ID",
      patientnameupper: "Patient Name",
      firstclinic: "Clinic",
      clinic: "Clinic",
      clinicGroup: "Clinic Group",
      specialty: "Specialty",
      referredtospecialty: "Specialty",
      firstspecialty: "Specialty",
      referralpriority: "Priority",
      appointmenttypecode: "Appointment Type",
      attendancetypecode: "Attendance Type",
      appointmentprioritycode: "Priority",
      referralSource: "Source",
      status: "Status",
      currentdateonlistsearchable: "From",
      referraldatesearchable: "From",
      appointmentsearchabledate: "Date",
      appointmentstatuscode: "Status",
      expectedbookingsystemcode: "Booking System",
      bookingsystem: "Booking System",
      upcoming: "Upcoming Bookings",
      clinicSearch: "Clinic Title",
      suspectedCancer: "Suspected Cancer",
      sessiondatesearchable:"From",
    };
    const elements = [];
    Object.keys(formData).forEach((key) => {
      if (formData[key] && formData[key] !== undefined && key.includes("date"))
        elements.push(
          <Filter key={key}>
            <span className="caption">{keyMap[key]}:</span>
            <span className="tag-name">
              {formData[key].format("DD/MM/YYYY")}
            </span>
            <Button name={key} onClick={onClearFilter}>
              X
            </Button>
          </Filter>,
        );
      else if (formData[key] && formData[key] !== undefined)
        elements.push(
          <Filter key={key}>
            <span className="caption">{keyMap[key]}:</span>
            <span className="tag-name">{formData[key]}</span>
          {key !== "upcoming" && (
            <Button name={key} onClick={onClearFilter}>
              X
            </Button>)}
          </Filter>,
        );
    });
    return elements.length > 0 ? (
      <div className="filter-results">{elements}</div>
    ) : null;
  };

  return (
    <TopParent>
      <form onSubmit={handleSubmit} style={{ padding: 10 }}>
        <GridParent>
          <GridItem>
            <h2>{title}</h2>
          </GridItem>

          <GridItem xs={12} sm={6}>
            <TextField
              fullWidth
              placeholder={
                types.includes("searchClinic")
                  ? "Search Clinic"
                  : "Search Patient"
              }
              name="pasid"
              value={searchText}
              onChange={handleTextChange}
              onKeyDown={handleKeyDown}
              size="small"
              InputLabelProps={{ shrink: false }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </GridItem>
          <GridItem xs={12} sm={6}>
            <Button variant="outlined" onClick={handleMenuClick}>
              Filters
            </Button>

            <Menu
              anchorEl={menuAnchorEl}
              open={menuOpen}
              onClose={handleMenuClose}
            >
              <MenuLayout>
                {types.includes("clinic") && (
                  <ComboClinic
                    label="Clinic"
                    value={formData.clinic}
                    onChange={(code) => {
                      handleComboChange("clinic", code);
                    }}
                  />
                )}
                {types.includes("firstclinic") && (
                  <ComboClinic
                    label="Clinic"
                    value={formData.firstclinic}
                    onChange={(code) => {
                      handleComboChange("firstclinic", code);
                    }}
                  />
                )}
                {types.includes("clinicGroup") && (
                  <ComboClinicGroup
                    label="Clinic Group"
                    value={formData.clinicGroup}
                    onChange={(code) => {
                      handleComboChange("clinicGroup", code);
                    }}
                  />
                )}
                {types.includes("specialty") && (
                  <ComboSpecialty
                    label="Specialty"
                    value={formData.specialty}
                    onChange={(code) => {
                      handleComboChange("specialty", code);
                    }}
                  />
                )}
                {types.includes("referredtospecialty") && (
                  <ComboSpecialty
                    label="Specialty"
                    value={formData.referredtospecialty}
                    onChange={(code) => {
                      handleComboChange("referredtospecialty", code);
                    }}
                  />
                )}
                {types.includes("firstspecialty") && (
                  <ComboSpecialty
                    label="Specialty"
                    value={formData.firstspecialty}
                    onChange={(code) => {
                      handleComboChange("firstspecialty", code);
                    }}
                  />
                )}
                {types.includes("referralpriority") && (
                  <div>
                    <InputLabel className="input-label">
                      Referral Priority
                    </InputLabel>
                    <ComboLookupCode
                      name="referralPriority"
                      value={formData.referralPriority}
                      refDomain="referralPriority"
                      onChange={(newData) => {
                        handleComboChange("referralpriority", newData);
                      }}
                    />
                  </div>
                )}
                {types.includes("appointmenttypecode") && (
                  <div>
                    <InputLabel className="input-label">
                      Appointment Type
                    </InputLabel>
                    <ComboLookupCode
                      name="appointmenttypecode"
                      value={formData.appointmenttypecode}
                      refDomain="appointmentType"
                      onChange={(newData) => {
                        handleComboChange("appointmenttypecode", newData);
                      }}
                    />
                  </div>
                )}

                {types.includes("attendancetypecode") && (
                  <div>
                    <InputLabel className="input-label">
                      Attendance Type
                    </InputLabel>
                    <ComboLookupCode
                      name="attendancetypecode"
                      value={formData.attendancetypecode}
                      refDomain="attendanceType"
                      onChange={(newData) => {
                        handleComboChange("attendancetypecode", newData);
                      }}
                    />
                  </div>
                )}
                {types.includes("appointmentprioritycode") && (
                  <div>
                    <InputLabel className="input-label">Priority</InputLabel>
                    <ComboLookupCode
                      name="appointmentprioritycode"
                      value={formData.appointmentprioritycode}
                      refDomain="referralPriority"
                      onChange={(newData) => {
                        handleComboChange("appointmentprioritycode", newData);
                      }}
                    />
                  </div>
                )}
                {types.includes("referralSource") && (
                  <div>
                    <InputLabel className="input-label">GP</InputLabel>
                    <ComboOrgDets
                      name="GP"
                      value={formData.referralSource}
                      onChange={(code) => {
                        setFormData({ ...formData, gp: code });
                      }}
                    />
                  </div>
                )}
                {types.includes("status") && (
                  <div>
                    <Autocomplete
                      id="combo-box-status"
                      value={formData.status}
                      options={["Closed", "Open"]}
                      sx={{ width: 300 }}
                      size="small"
                      renderInput={(params) => (
                        <React.Fragment>
                          <InputLabel className="input-label">
                            Status
                          </InputLabel>
                          <TextField
                            {...params}
                            placehoder="Status"
                            variant="outlined"
                            InputLabelProps={{ shrink: false }}
                            style={{ marginTop: 0 }}
                          />
                        </React.Fragment>
                      )}
                      onChange={(e, newData) => {
                        const val = newData ? newData : null;
                        const f = { ...formData, status: val };
                        setFormData(f);
                        onSubmit(f);
                      }}
                    />
                  </div>
                )}
                {types.includes("currentdateonlistsearchable") && (
                  <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                    adapterLocale="en-gb"
                  >
                    <InputLabel className="input-label">
                      From Date Received
                    </InputLabel>
                    <XDatePicker
                      value={formData.currentdateonlist}
                      name="currentdateonlistsearchable"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={(newValue) => {
                        const f = {
                          ...formData,
                          currentdateonlistsearchable: newValue,
                        };
                        setFormData(f);
                        onSubmit(f);
                      }}
                      slotProps={{
                        textField: {
                          size: "small",
                          style: { width: 300, marginTop: 0 },
                        },
                      }}
                    />
                  </LocalizationProvider>
                )}
                {types.includes("referraldatesearchable") && (
                  <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                    adapterLocale="en-gb"
                  >
                    <InputLabel className="input-label">
                      Date Received
                    </InputLabel>
                    <XDatePicker
                      value={formData.referraldate}
                      name="referraldatesearchable"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={(newValue) => {
                        const f = {
                          ...formData,
                          referraldatesearchable: newValue,
                        };
                        setFormData(f);
                        onSubmit(f);
                      }}
                      slotProps={{
                        textField: {
                          size: "small",
                          style: { width: 300, marginTop: 0 },
                        },
                      }}
                    />
                  </LocalizationProvider>
                )}
                {types.includes("appointmentsearchabledate") && (
                  <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                    adapterLocale="en-gb"
                  >
                    <InputLabel className="input-label">
                      Booking Date
                    </InputLabel>
                    <XDatePicker
                      value={formData.appointmentsearchabledate}
                      name="appointmentsearchabledate"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={(newValue) => {
                        const f = {
                          ...formData,
                          appointmentsearchabledate: newValue,
                        };
                        setFormData(f);
                        onSubmit(f);
                      }}
                      slotProps={{
                        textField: {
                          size: "small",
                          style: { width: 300, marginTop: 0 },
                        },
                      }}
                    />
                  </LocalizationProvider>
                )}
                {types.includes("sessiondatesearchable") && (
                  <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                    adapterLocale="en-gb"
                  >
                    <InputLabel className="input-label">
                      From
                    </InputLabel>
                    <XDatePicker
                      value={formData.sessiondatesearchable}
                      name="sessiondatesearchable"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={(newValue) => {
                        const f = {
                          ...formData,
                          sessiondatesearchable: newValue,
                        };
                        setFormData(f);
                        onSubmit(f);
                      }}
                      slotProps={{
                        textField: {
                          size: "small",
                          style: { width: 300, marginTop: 0 },
                        },
                      }}
                    />
                  </LocalizationProvider>
                )}
                {types.includes("appointmentstatuscode") && (
                  <div>
                    <InputLabel className="input-label">
                      Appointment Status
                    </InputLabel>
                    <ComboLookupCode
                      name="appointmentstatuscode"
                      value={formData.appointmentstatuscode}
                      refDomain="appointmentStatus"
                      onChange={(newData) => {
                        handleComboChange("appointmentstatuscode", newData);
                      }}
                    />
                  </div>
                )}
                {types.includes("expectedbookingsystemcode") && (
                  <div>
                    <InputLabel className="input-label">
                      Booking System
                    </InputLabel>
                    <ComboLookupCode
                      name="expectedbookingsystemcode"
                      value={formData.expectedbookingsystemcode}
                      refDomain="bookingSystem"
                      onChange={(newData) => {
                        handleComboChange("expectedbookingsystemcode", newData);
                      }}
                    />
                  </div>
                )}
                {types.includes("bookingsystem") && (
                  <div>
                    <InputLabel className="input-label">
                      Booking System
                    </InputLabel>
                    <ComboLookupCode
                      name="bookingsystem"
                      value={formData.expectedbookingsystemcode}
                      refDomain="bookingSystem"
                      onChange={(newData) => {
                        handleComboChange("bookingsystem", newData);
                      }}
                    />
                  </div>
                )}
                {types.includes("slottypecode") && (
                  <div>
                    <InputLabel className="input-label">
                     Slot Type 
                    </InputLabel>
                    <ComboLookupCode
                      name="SlotType"
                      value={formData.slottypecode}
                      refDomain="attendanceType"
                      onChange={(newData) => {
                        handleComboChange("slottypecode", newData);
                      }}
                    />
                  </div>
                )}
                {types.includes("slotprioritycode") && (
                  <div>
                    <InputLabel className="input-label">
                     Slot Priority 
                    </InputLabel>
                    <ComboLookupCode
                      name="Priority"
                      value={formData.slotprioritycode}
                      refDomain="appointmentPriority"
                      onChange={(newData) => {
                        handleComboChange("slotprioritycode", newData);
                      }}
                    />
                  </div>
                )}
                {types.includes("bookedtypeindicatorcode") && (
                  <div>
                    <InputLabel className="input-label">
                    Booked Indicator 
                    </InputLabel>
                    <ComboLookupCode
                      name="bookedType"
                      value={formData.bookedtypeindicatorcode}
                      refDomain="bookedType"
                      onChange={(newData) => {
                        handleComboChange("bookedtypeindicatorcode", newData);
                      }}
                    />
                  </div>
                )}
                {types.includes("appointmentslottypecode") && (
                  <div>
                    <InputLabel className="input-label">
                    Appointment Type 
                    </InputLabel>
                    <ComboLookupCode
                      name="slotType"
                      value={formData.appointmentslottypecode}
                      refDomain="appointmentSlotType"
                      onChange={(newData) => {
                        handleComboChange("appointmentslottypecode", newData);
                      }}
                    />
                  </div>
                )}
                {types.includes("suspectedCancer") && (
                  <div>
                    <InputLabel className="input-label">
                    Suspected Cancer
                    </InputLabel>
                    <ComboLookupCode
                      name="slotType"
                      value={formData.suspectedCancer}
                      refDomain="suspectedCancer"
                      onChange={(newData) => {
                        handleComboChange("suspectedCancer", newData);
                      }}
                    />
                  </div>
                )}
                {types.includes("mainprocedure") && (
                  <ComboProcedure
                    label="Main Procedure"
                    value={formData.mainprocedure}
                    onChange={(code) => {
                      handleComboChange("mainprocedure", code);
                    }}
                  />
                )}
              </MenuLayout>
            </Menu>
          </GridItem>
          <GridItem>{currentFilters()}</GridItem>
          <GridItem></GridItem>
        </GridParent>
      </form>
      <ActionPanel>{children}</ActionPanel>
    </TopParent>
  );
};

export default SearchByTypeForm;
