import React, { useState, useEffect } from "react";
import { Button, AppBar, Toolbar, Typography } from "@mui/material";
import axios from "axios";
import styled from "styled-components";
import "../config";
import Avatar from "../assets/avatar.svg";

const Section = styled.div`
  line-height: 1.428571429;
  font-size: 12px;
  font-family:
    Source Sans Pro,
    sans-serif;
  color: #000;
  box-sizing: border-box;

  & .encounter-document-view {
    line-height: 1.428571429;
    font-size: 12px;
    font-family:
      Source Sans Pro,
      sans-serif;
    color: #000;
    box-sizing: border-box;
  }

  & .encounter-view {
    line-height: 1.428571429;
    font-family:
      Source Sans Pro,
      sans-serif;
    color: #000;
    box-sizing: border-box;
    font-size: 14px;
    display: flex;
    flex-flow: row;
    padding: 5px 10px;
    border: 1px solid rgb(224, 224, 224);
    background: white;
    margin-top: 40px; 
  }
  & .column {
    flex: 2 0 150px;
    border-right: 1px solid rgb(204, 204, 204);
    padding: 0 10px;

    h4.banner-style {
      color: rgb(136, 136, 136);
      font-size: 16px;
      font-weight: 700;
      margin-top: 0px;
      margin-bottom: 5px;
    }

    &.patient-detail {
      flex: 1 0 250px;
    }
  }
  & .column-heading {
    flex: 1;
    font-weight: bold;
    border-right: 1px solid rgb(224, 224, 224);

    display: flex;
    flex-direction: row-reverse;

    .avatar {
      width: 50px;
      height: 50px;
      margin-right: 12px;
      margin-top: 15px;
    }
  }
  & .column-heading span {
    text-align: right;
    padding-right: 12px;
}

  & .encounter-info {
    flex: 1;
    line-height: 1.428571429;
    font-family:
      Source Sans Pro,
      sans-serif;
    color: #000;
    font-size: 14px;
    box-sizing: border-box;
    display: flex;
    flex-flow: wrap;

    .name {
      font-size: 22px;
      font-weight: 600;
      text-transform: capitalize;
    }
  }
  & .caption {
    color: rgb(115, 115, 115);
  }
  & .value {
    margin-top: 0px;
    margin-right: 20px;
    margin-bottom: 0px;
    margin-left: 5px;
    font-weight: 600;
    text-transform: capitalize;
  }

  .group {
    display: flex;
  }

  .edit-btn {
    padding: 0 10px;
  }
`;

function isNumeric(value) {
  return !isNaN(value) && typeof value !== 'boolean';
}

const BannerWard = ({
  referralId,
  patientId,
  patientRec,
  referralRec,
  onSelect,
}) => {
  const cfg = global.config.restAPI; 

  const [patient, setPatient] = useState(patientRec || []);
  const [referral, setReferral] = useState(referralRec || []);

  useEffect(() => {
    // Fetch patient data for the patient ID frbm the URL params

    const query = { internal_patient_id: { $eq: patientId } } ;
    const url = `${cfg.url + cfg.endpoints.patients}/?q=${JSON.stringify(query)};`
    const demo = `${cfg.url + cfg.endpoints.patients}/`

    axios
      .get((isNumeric(patientId)?url:demo)) 
      .then((response) => {
        const firstPatient = response.data.items[0];
        setPatient(firstPatient);
      });
    
    const query2 = { internalpatientid: { $eq: patientId }, referralnumber: { $eq: referralId}  } ;
    const url2 = `${cfg.url + cfg.endpoints.referrals}/?q=${JSON.stringify(query2)};`
    const demo2 = `${cfg.url + cfg.endpoints.referrals}/`
    axios
      .get(isNumeric(referralId)?url2:demo2)
      .then((response) => {
        const firstItem = response.data.items[0]
        setReferral(firstItem);
      });
  }, [referralId, patientId, cfg]);

  return (
    <Section style={{ paddingBottom: 10, color: "#3d3d3d", flex:1 }}>
      <div class="encounter-view">
        <div className="column-heading">
          <img
              src={Avatar}
              className="avatar"
          />
        </div>
        <div class="column patient-detail">
          <div class="encounter-info">
            <div className="name">{patient.surname}, {patient.name1}</div>
          </div>
          <div class="encounter-info">
            <div class="caption">NHS:</div>
            <div class="value">{referral != null ? referral.nhs_id : ''}</div>
          </div>
          <div class="encounter-info">
            <div class="caption">DOB:</div>
            <div class="value">{patient.date_of_birth}</div>
          </div>
        </div>
        <div class="column">
          <h4 className="banner-style">Referral Details</h4>
          <div class="encounter-info">
            <div class="caption">Encounter:</div>
            <div class="value encounter-type">Outpatient</div>
          </div>
          <div class="encounter-info">
            <div class="caption">Referral No:</div>
            <div class="value">{referral != null ? referral.referralnumber : ''}</div>
          </div>
          <div class="encounter-info">
            <div class="caption">Status:</div>
            <div class="value">Open</div>
          </div>
        </div>
        <div class="column">
          <div class="encounter-info">
            <div class="caption">Reason:</div>
            <div class="value encounter-type">{referral != null ? referral.referralreason : ''}</div>
          </div>
          <div class="encounter-info">
            <div class="caption">Specialy:</div>
            <div class="value">{referral != null ? referral.referredtospecialty : ''}</div>
          </div>
          <div class="encounter-info">
            <div class="caption">Consultant:</div>
            <div class="value">{referral != null ? referral.referredtoperson : ''}</div>
          </div>
        </div>
        <div class="column">
          <div class="encounter-info">
            <div class="caption admitted">Received:</div>
            <div class="value">{referral != null ? referral.referraldate : ''}</div>
          </div>
          <div class="encounter-info">
            <div class="caption discharged">Recorded:</div>
            <div class="value">{referral != null ? referral.daterecorded : ''}</div>
          </div>
          <div class="encounter-info">
            <div class="caption discharged">RRT:</div>
            <div class="value">{(referral != null ? referral.rtt?"Y":"N" : '')}</div>
          </div>
        </div>
        <div className="edit-btn">
          <Button
            variant="outlined"
            size="small"
            style={{ maxWidth: "100px" }}
            onClick={() => onSelect(patient.id)}
          >
            Edit
          </Button>
        </div>
      </div>
    </Section>
  );
};

export default BannerWard;
