// pages/PatientDetails.js
import { useNavigate, useParams } from "react-router-dom";
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../config';
import styled from "styled-components";

const SectionContainer = styled.div`
  background: #eaebf2;
  width: 100%;
  height: 100vh;
  .section {
    padding: 10px;
    margin: 10px;
    flex: 1 1 48%;
    border-radius: 4px;
    min-height: 50px;
    background-color: #fff;
    
    h4 {
      margin: 0 0 10px;
      padding: 0;
      font-weight: 600;
      font-size: 16px;
    }
  }
  
  dl {
    margin: 0;
    padding: 0;
    font-weight: 400;
    line-height: normal;
  }
  
  dt {
    color: #828282;
    font-size: 14px;
  }
  
  dd {
    margin: 0;
    padding: 0;
    font-weight: 400;
    line-height: normal;
  }

  .info {
    flex: 0 1 200px;
    margin-bottom: 10px;
  }
  
  .no-data {
    flex: 0 1 200px;
    margin-bottom: 10px;
  }
  
  .section-detail {
    display: flex;
  }
`

function isNumeric(value) {
  return !isNaN(value) && typeof value !== 'boolean';
}

const PatientDetails = () => {
  const [patient, setPatient] = useState(null);
  const cfg = global.config.restAPI; //"http://localhost:3000/";
  const navigate = useNavigate();
  const { patientId } = useParams();
  //const baseUrl = `https://g692a18a514da6f-pcsdemo.adb.uk-london-1.oraclecloudapps.com/ords/silver/api/core`

  useEffect(() => {
    // Fetch patient data for the patient ID frbm the URL params
    const query = { internal_patient_id: { $eq: patientId } } ;
    const url = `${cfg.url + cfg.endpoints.patients}/?q=${JSON.stringify(query)};`
    const demo = `${cfg.url + cfg.endpoints.patients}/`

    axios
      .get((isNumeric(patientId)?url:demo)) 
      .then((response) => {
        const firstPatient = response.data.items[0];
        setPatient(firstPatient);
      });
  }, [patientId, cfg]);

  if (!patient) {
    return <p style={{marginLeft: 24}}>Loading...</p>;
  }

  return (
      <SectionContainer>
          <div className="section">
              <h4>Patient Details</h4>
              <div className="section-detail">
                  <div className="info">
                      <dl>
                          <dt>SurName</dt>
                          <dd>{patient.surname}</dd>
                      </dl>
                  </div>
                  <div className="info">
                      <dl>
                          <dt>First Name</dt>
                          <dd>{patient.name1}</dd>
                      </dl>
                  </div>
              </div>
          </div>
          <div className="section">
              <h4>Address Details</h4>
              <div className="section-detail">
                  <div className="no-data">
                      No data available
                  </div>
              </div>
          </div>
          <div className="section">
              <h4>Contact Information</h4>
              <div className="section-detail">
                  <div className="no-data">
                      No data available
                  </div>
              </div>
          </div>
          <div className="section">
              <h4>Emergency Contact</h4>
              <div className="section-detail">
                  <div className="no-data">
                      No data available
                  </div>
              </div>
          </div>
      </SectionContainer>
  );
};

export default PatientDetails;

