import React, { useState, useEffect } from "react";
import axios from "axios";
import styled from "styled-components";
import { useNavigate, useParams } from "react-router-dom";
import {
  Button,
  Grid,
  InputLabel,
  Select,
  MenuItem,
  Menu,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

import RttPatientGrid from "../components/rttPatientGrid";
import BannerReferral from "../components/bannerReferral";
import NavAppBar from "../components/navAppBar";
import "../config";
import { PatientListContainer } from "./patientList";
import AppointmentPatientGrid from "../components/appointmentPatientGrid";

const BannerBox = styled.div`
  background: rgb(234, 235, 242);
`;

export const AppPatientListContainer = styled.div`
  height: 90vh;
  display: flex;
  flex-direction: column;
  flex: 1;

  .title-bar {
    .back-btn {
      margin-right: 100px;
    }
    .title {
      margin-right: 0;
    }
  }
`;

const AppointmentList = () => {
  const cfg = global.config.restAPI; //"http://localhost:3000/";
  const [appList, setAppList] = useState([]);

  const [menuAnchorEl, setMenuAnchorEl] = React.useState(null);
  const navigate = useNavigate();
  const { patientId, referralId } = useParams();

  const menuOpen = Boolean(menuAnchorEl);
  const handleMenuClick = (event) => {
    setMenuAnchorEl(event.currentTarget);
  };
  const handleMenuClose = (e) => {
    setMenuAnchorEl(null);
    switch (e.currentTarget.innerText) {
      case "Appointment List":
        navigate(`../patient/${patientId}/referral/${referralId}/appointment/`);
        break;
      case "Waitlist Management":
        navigate(
          `/waitlist/patient/${patientId}/referral/${referralId}/waitlist/`,
        );
        break;
      case "RTT pathway":
        navigate(`/waitlist/patient/${patientId}/referral/${referralId}/rtt/`);
        break;
      default:
        return;
    }
  };
  useEffect(() => {
    // Fetch rtt for the referral
    axios
      .get(
        `${cfg.url + cfg.endpoints.appointments}/?q={"internalpatientid":{"$eq":"${patientId}"},"referralnumber":{"$eq":"${referralId}"}}`,
      )
      .then((response) => {
        const allItems = response.data.items;
        setAppList(allItems);
      });
  }, [patientId, referralId, cfg]);

  return (
    <AppPatientListContainer>
      <NavAppBar title="Appointment List">
        <Button
          variant="outlined"
          onClick={() =>
            navigate(
              `../patient/${patientId}/referral/${referralId}/appointment/create`,
            )
          }
          startIcon={<AddIcon />}
        >
          Appointment{" "}
        </Button>
        <Button variant="outlined" onClick={handleMenuClick}>
          More{" "}
        </Button>
        <Menu anchorEl={menuAnchorEl} open={menuOpen} onClose={handleMenuClose}>
          <MenuItem onClick={handleMenuClose}>Modify waitlist</MenuItem>
          <MenuItem onClick={handleMenuClose}>Suspend waitlist</MenuItem>
          <MenuItem onClick={handleMenuClose}>Manage notes</MenuItem>
          <MenuItem onClick={handleMenuClose}>View referral</MenuItem>
          <MenuItem onClick={handleMenuClose}>Schedule appointment</MenuItem>
          <MenuItem onClick={handleMenuClose}>RTT pathway</MenuItem>
          <MenuItem onClick={handleMenuClose}>Remove from waitlist</MenuItem>
          <MenuItem onClick={handleMenuClose}>Patient communications</MenuItem>
          <MenuItem onClick={handleMenuClose}>Appointment List</MenuItem>
          <MenuItem onClick={handleMenuClose}>Waitlist Management</MenuItem>
        </Menu>
      </NavAppBar>
      <BannerBox>
        <BannerReferral
          patientId={patientId}
          referralId={referralId}
          onSelect={() =>
            navigate(`/referral/patient/${patientId}/referral/{referralId}`)
          }
        />
      </BannerBox>
      <PatientListContainer style={{ flex: 1, overflow: "scroll" }}>
        <AppointmentPatientGrid
          records={appList}
          onSelect={(appId) =>
            navigate(
              `../patient/${patientId}/referral/${referralId}/appointment/${appId}`,
            )
          }
        />
      </PatientListContainer>
    </AppPatientListContainer>
  );
};

export default AppointmentList;
