import React, { useState, useEffect } from "react";
import axios from "axios";
import styled from "styled-components";
import { useParams, useNavigate } from "react-router-dom";
import { Button, FormControl, MenuItem, Select } from "@mui/material";
import XButtonGroup from "../../components/xButtonGroup";
import AddIcon from "@mui/icons-material/Add";
import TheatreGrid from "./theatreGrid";
import SearchByTypesForm from "../../components/searchByTypesForm";
import SequencingDialogue from "./sequencingDialogue";
import ClearAllIcon from "@mui/icons-material/ClearAll";
import "../../config";
import { FormContainer } from "../../pages/theatreMgr";
import { GridContainer } from "../../components/containers";

const MainContainer = styled.div`
  height: 90vh;
  width: 87vw; /* tree menu */

  display: flex;
  flex-direction: column;
  flex: 1;

  .title {
    background-color: #4a4c52;
    height: 40px;
    text-align: center;

    h2 {
      margin: 8px;
      color: #ffffff;
      font-size: 18px;
    }
  }

  .search-btn {
    margin-top: 4px;
    margin-left: 14px;
  }
`;

// Remove location filter becase this information is not included in the pre-op endpoint
const filterPairs = (filter) =>
  Object.entries(filter).filter((d) => d[0] !== "theatrelocationcode");

const TheatreTabBookings = ({ tab, sessionFilter, onViewModeChange }) => {
  const [bookings, setBookings] = useState();
  const [openSequencing, setOpenSequencing] = useState();
  const cfg = global.config.restAPI;
  const navigate = useNavigate();
  //const [viewMode, setViewMode] = useState("Bookings");
  const viewMode = "Bookings";
  const sessionCode = sessionFilter.theatresessioncode; // Get the speciality from the tab code

  const query = {}; // upcoming: "true" };

  useEffect(() => {
    // Fetch everything to start with
    const query = { sessiondatesearchable: { $gt: { $date: new Date() } } };
    if (sessionFilter)
      for (const [key, value] of filterPairs(sessionFilter)) {
        query[key] = { $eq: value };
      }

    axios
      .get(
        `${cfg.url + cfg.endpoints.theatre.preopdetails}/?q=${JSON.stringify(query)}`,
      )
      .then((response) => {
        setBookings(response.data.items);
      });
  }, [sessionFilter, cfg]);

  const handleSelect = (id, rowData) => {
    const sessionCode = rowData.theatresessioncode;
    const sessionDate = rowData.sessiondate.replaceAll("/", "");

    navigate(
      `/theatre/${rowData.internalpatientid}/${sessionCode}/${sessionDate}/booking`,
    );
  };

  const handleFormSubmit = (formData) => {
    // Filtering out empty values from formData
    const nonEmptyFormData = Object.fromEntries(
      Object.entries(formData).filter(([key, value]) => {
        return value && value !== "";
      }),
    );

    // Get left-menu filters
    const query = { sessiondatesearchable: { $gt: { $date: new Date() } } };
    if (sessionFilter)
      filterPairs(sessionFilter).map(
        ([name, value]) => (query[name] = { $eq: value }),
      );

    // Get search and filter menu filters
    for (const [key, value] of Object.entries(nonEmptyFormData)) {
      if (key.includes("date")) query[key] = { $gt: { $date: value.utc() } };
      else if (key.includes("patientnameupper"))
        query[key] = { $instr: value.toUpperCase() };
      //if (!key.includes("theatrelocationcode"))
      else query[key] = { $eq: value };
    }

    // Fetch from the API
    axios
      .get(
        `${cfg.url + cfg.endpoints.theatre.preopdetails}/?q=${JSON.stringify(query)}`,
      )
      .then((response) => {
        setBookings(response.data.items);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <MainContainer>
      <FormContainer>
        <div className={["view-mode"]}>
          <XButtonGroup
            key="book"
            value={viewMode}
            list={["Bookings", "Availability", "Calendar"]}
            onChange={onViewModeChange}
          />
        </div>
        <div className={["flex1"]}>
          <SearchByTypesForm
            title=""
            onSubmit={handleFormSubmit}
            defaultQuery={query}
            types={["specialty", "sessiondatesearchable", "mainprocedure"]}
          >
            <Button
              type="primary"
              className="search-btn"
              variant="outlined"
              startIcon={<ClearAllIcon />}
              onClick={() => {
                setOpenSequencing(true);
              }}
            >
              Sequencing
            </Button>
            <Button
              className="search-btn"
              type="primary"
              variant="outlined"
              startIcon={<AddIcon />}
              onClick={() => navigate(`/theatre/${sessionCode}/booking/create`)}
            >
              Session Booking
            </Button>
          </SearchByTypesForm>
        </div>
      </FormContainer>
      {bookings && (
        <GridContainer style={{}}>
          <TheatreGrid records={bookings} onSelect={handleSelect} />
        </GridContainer>
      )}
      {!bookings && <p style={{ marginLeft: 24 }}>Loading...</p>}

      <SequencingDialogue
        open={openSequencing}
        bookings={bookings}
        sessionCode={sessionCode}
        onClose={() => setOpenSequencing(false)}
      />
    </MainContainer>
  );
};

export default TheatreTabBookings;
