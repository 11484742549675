/* Login.js */

import React, { useState, useEffect } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import { loginRequest } from "../authConfig";
import styled from "styled-components";
import MiyaPrecision from "../assets/MiyaPrecision.svg";
import { AppBar, Button, Toolbar } from "@mui/material";

const Background = styled.div`
  background: #f8f8f8;
  width: auto;
  height: 100vh;
`;
const Card = styled.div`
  background: FFF;
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  margin: auto;
  width: 30rem;
  height: 350px;
  background: #fff;
  border: 0.1rem solid #e8e8e8;
  -ms-border-radius: 0.4rem;
  border-radius: 0.4rem;
  box-sizing: border-box;
  padding-top: 6rem ;
  font-weight: 600;

  display: flex;
  flex-direction: column;
  align-items: center;

  & img {
    margin: auto;
    display: block;
    width: 23rem;
    flex: 1;
  }
  & span {
    color: #4b659b;
  font-weight: 700;
  }
& button{
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    list-style: none;
    box-sizing: border-box;
    font: inherit;
    margin: 0;
    overflow: visible;
    text-transform: none;
    -webkit-appearance: button;
    font-family: inherit;
    display: inline-block;
    margin-bottom: 0;
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    touch-action: manipulation;
    cursor: pointer;
    background-image: none;
    border: 1px solid transparent;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    border-radius: 4px;
    user-select: none;
    color: #333;
    background-color: #fff;
    border-color: #ccc;
    width: 230px;
}
  & footer {
  color: #CCC
}
`;
const ListPanel = styled.div`
  padding-top: 15px;
  flex: 1;
`;

const Login = () => {
  const { instance } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    /* redirect when authenticated */
    if (isAuthenticated) {
      const { from } = location.state || { from: { pathname: "/" } };
      navigate(from, { replace: true });
    }
  }, [isAuthenticated, location, navigate]);

  const handleLogin = (loginType) => {
    /* do Azure Login */
    if (loginType === "popup") {
      instance.loginPopup(loginRequest).catch((e) => {
        console.log(e);
      });
    } else if (loginType === "redirect") {
      instance.loginRedirect(loginRequest).catch((e) => {
        console.log(e);
      });
    }
  };

  return (
    <Background>
      <Card>
        <img src={MiyaPrecision} alt="Miya Precision" />
        <div>
          Login to <span>Miya PAS</span>
        </div>
        <ListPanel>
          <button onClick={() => handleLogin("redirect")}>
            Login with Alcidion
          </button>
        </ListPanel>
        <footer>
          © 2024 Alcidion Corporation Pty Ltd. All rights reserved.
        </footer>
      </Card>
    </Background>
  );
};
export default Login;
