import React, { useState, useEffect } from "react";
import axios from "axios";
import styled from "styled-components";
import { useNavigate, useParams } from "react-router-dom";
import { Button, ButtonGroup, Grid, InputLabel, Select, MenuItem } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import ReorderIcon from '@mui/icons-material/Reorder';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

import NavAppBar from "../components/navAppBar";
import "../config";
import { PatientListContainer } from "./patientList";
import ClinicSlotGrid from "../components/clinic/clinicSlotGrid";
import ClinicNavBar from "../components/clinic/clinicNavBar";
import ClinicCalendar from "../components/clinic/clinicCalendar";
import DisplaySection from "../components/displaySection";

const BannerBox = styled.div`
  background: rgb(234, 235, 242);
`;

export const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  .title-bar {
    .title {
      margin-right: 0;
    }
  }
`

const labelMapping = {
"clinic_information": {
    "clinic": "Clinic",
    "descript": "Description",
    "sys": "System",
    "dateFromDisplay": "Date From",
    "dateToDisplay": "Date To",
    "lastDateDisplay": "Last Date",
    "room": "Room",
    "staff": "Staff",
    "joint": "Joint",
  },

  "element_schedule": {
  "stime": "Start Time",
  "etime": "End Time",
  "day": "Day",
  "wk_freq": "Week Frequency",
  "week": "Week",
  "month": "Month",
  "cycle": "Cycle",
}
};


const TheatreSlotList = () => {
  const cfg = global.config.restAPI; //"http://localhost:3000/";
  const [slots, setSlots] = useState([]);
  const [clinicObj, setTheatreObj] = useState([]);
  const [showCal, setShowCal] = useState(false);

  const navigate = useNavigate();
  const { clinicCode } = useParams();

  useEffect(() => {
    // Fetch data for the code from the URL params
    axios
      .get(
        `${cfg.url + cfg.endpoints.clinicSessionSlots}/?q={"clinic":{"$eq":"${clinicCode}"}}`,
      )
      .then((response) => {
        setSlots(response.data.items);
      });

    // Fetch data for the code from the URL params
    axios
      .get(
        `${cfg.url + cfg.endpoints.clinicSessions}/?q={"clinic":{"$eq":"${clinicCode}"}}`,
      )
      .then((response) => {
        const d = response.data.items[0];
        d.dateFromDisplay = (d.date_fr? d.date_fr.slice(0,10):"");
        d.dateToDisplay= (d.date_to?d.date_to.slice(0,10):"");
        d.lastDateDisplay= (d.lastdate?d.lastdate.slice(0,10):"");
        setTheatreObj(d);
        
      });
  }, [clinicCode, cfg]);


  return (
    <ListContainer>
      <NavAppBar title="Theatre Details">
        <Button
          variant="outlined"
          onClick={() => navigate(`../config/${clinicCode}/slot/create`)}
          startIcon={<AddIcon />}
        >
          Theatre Element
        </Button>
        <Button  variant="outlined">
         Block Cancel 
        </Button>
      </NavAppBar>
      {!showCal &&
      <div style={{ height: 350, marginTop: 40, background: '#eaebf2'}}>
      <DisplaySection
        title="Theatre Information"
        labelMap={labelMapping.clinic_information}
        data={{...clinicObj, s:clinicObj}}
      />
      <DisplaySection
        title="Element 1 - Tuesday Theatre"
        labelMap={labelMapping.element_schedule}
        data={clinicObj}
      />
      </div>
      }
      {slots && !showCal && (
        <PatientListContainer style={{ flex: 1, overflow: "scroll" }}>
          <ClinicNavBar
            title="Clinic Slots"
          >
              <Button
                variant={!showCal ? "outlined" : "primary"}
                onClick={() => setShowCal(false)}
startIcon={<ReorderIcon/>}

              >
                List
              </Button>
              <Button
                variant={showCal ? "outlined" : "primary"}
                onClick={() => setShowCal(true)}
startIcon={<CalendarMonthIcon/>}
              >
                Calendar
              </Button>
          </ClinicNavBar>
          <ClinicSlotGrid
            clinicSlots={slots}
            onSelect={(slotId) =>
              console.log(`GOTO /config/clinic/${clinicCode}/slot/${slotId}`)
            }
          />
        </PatientListContainer>
)}
      {slots && showCal && (
        <ListContainer style={{ marginTop: 40 }} >
          <ClinicNavBar
            title={`Theatre Calendar`}
          >
            <ButtonGroup variant="outlined" size="small">
              <Button
                variant={!showCal ? "outlined" : "primary"}
                onClick={() => setShowCal(false)}
startIcon={<ReorderIcon/>}
              >
                List
              </Button>
              <Button
                variant={showCal ? "outlined" : "primary"}
                onClick={() => setShowCal(true)}
startIcon={<CalendarMonthIcon/>}
              >
                Calendar
              </Button>
            </ButtonGroup>
          </ClinicNavBar>
          <ClinicCalendar
            clinicSlots={slots}
            onSelect={()=>{console.log('selected')}}
          />
        </ListContainer>
      )}
    </ListContainer>
  );
};

export default TheatreSlotList;
