module.exports = global.muiTheme = {
  palette: {
    primary: {
      main: "#454545",
      light: "#fff",
      dark: "#ccc",
      contrastText: "#fff",
    },
    secondary: {
      main: "#333",
      light: "#fff",
      dark: "#ccc",
      contrastText: "#333",
    },
},
    spacing: 4,
    components: {
      MuiButton: {
        defaultProps: {
          size: "small",
        },
      },
      MuiFilledInput: {
        defaultProps: {
          margin: "dense",
        },
      },
      MuiFormControl: {
        defaultProps: {
          margin: "dense",
        },
      },
      MuiFormHelperText: {
        defaultProps: {
          margin: "dense",
        },
      },
      MuiIconButton: {
        defaultProps: {
          size: "small",
        },
      },
      MuiInputBase: {
        defaultProps: {
          margin: "dense",
        },
      },
      MuiInputLabel: {
        defaultProps: {
          margin: "dense",
        },
      },
      MuiListItem: {
        defaultProps: {
          dense: true,
        },
      },
      MuiOutlinedInput: {
        defaultProps: {
          margin: "dense",
        },
      },
      MuiFab: {
        defaultProps: {
          size: "small",
        },
      },
      MuiTable: {
        defaultProps: {
          size: "small",
        },
      },
      MuiTextField: {
        defaultProps: {
          margin: "dense",
        },
      },
      MuiToolbar: {
        defaultProps: {
          variant: "dense",
        },
      },
    },
  };
