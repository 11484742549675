/* pages/ReferralDetails.js */
import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import NavAppBar from "../components/navAppBar";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import "../config";
import styled from "styled-components";
import DisplaySection from "../components/displaySection";
import AddIcon from "@mui/icons-material/Add";
import AssignmentIcon from '@mui/icons-material/Assignment';
import BannerPatient from "../components/bannerPatient";
import AppointmentSummary from "../components/appointmentSummary";
import WaitlistSummary from "../components/waitlist/waitlistSummary";
import PrintDialog from "../components/referralPrint"

const BannerBox = styled.div`
  background: rgb(234, 235, 242);
`
const ReferralContainer = styled.div`
  .title {
    margin-right: 0 !important;
    margin-left: 250px !important;
  }
`
const MiyaRecordSection = styled.div`
display: flex;
  margin: 0 10%;
  flex: 1 1 48%;
  border-radius: 4px;
  min-height: 50px;
  margin-top: 1rem;
  & .overview-module-container {margin-right: 1rem;}
  & .overview-module-container:last-child {margin-right: 0;}

  & .detail {
    display: flex;
    flex-flow: row wrap;
    align-items: flex-start;
    align-content: flex-start;
  }
  & .info {
    flex: 0 1 200px;
    margin-bottom: 10px;
    font-size: 14px;
  }
  & .info dl dt {
    margin-bottom: 2px;
    color: #828282;
    margin: 0;
    padding: 0;
    font-weight: 400;
    line-height: normal;
  }
  & .info dl dd {
margin: 0;
    padding: 0;
    font-weight: 400;
    line-height: normal;
    }

}
`;
const Title = styled.span`
  font-size: 16px;
  font-weight: 600;
  height: 17.5938px;
  line-height: 17.6px;
  color: rgb(0, 0, 0);
  display: block;
  font-family: "Source Sans Pro", sans-serif;
`;

const labelMapping = {
  general: {
    referralnumber: "Referral Number",
    internalpatientid: "Internal Patient ID",
    pasid: "PAS ID",
    nhs_id: "NHS ID",
    referraldate: "Referral Date",
    referraltime: "Referral Time",
    daterecorded: "Date Recorded",
    receivinglocation: "Receiving Location",
  },
  referring: {
    referringsourcecode: "Referring Source Code",
    referringsourcedescription: "Referring Source Description",
    referrertype: "Referrer Type",
    referralsource: "Referral Source",
    referringpersontype: "Referring Person Type",
    referringperson: "Referring Person",
    referringdiscipline: "Referring Discipline",
  },
  referralDetails: {
    referralreason: "Referral Reason",
    referralpriority: "Referral Priority",
    suspectedcancer: "Suspected Cancer",
    suspectedcancertypecode: "Suspected Cancer Type Code",
    suspectedcancertypedescription: "Suspected Cancer Type Description",
    referralreference: "Referral Reference",
    referredtoperson: "Referred to Person",
    referredtospecialty: "Referred to Specialty",
    referredtospecialtydescription: "Referred to Specialty Description",
    referraltext: "Referral Text",
    currentlocation: "Current Location",
  },
  diagnostic: {
    diagnostictype: "Diagnostic Type",
    diagnosticdescription: "Diagnostic Description",
    diagnosticcode: "Diagnostic Code",
    diagnostictext: "Diagnostic Text",
  },
  closure: {
    closuredate: "Closure Date",
    closuretime: "Closure Time",
    persontypeclosing: "Person Type Closing",
    persontypeclosingdescription: "Person Type Closing Description",
    personclosing: "Person Closing",
    closingreason: "Closing Reason",
  },
  userCodes: {
    usedcount: "Used Count",
    usercodetype: "User Code Type",
    usercodetypedescript: "User Code Type Description",
    usrcodec_1: "User Code 1",
    usrcodec_2: "User Code 2",
    usrcodec_3: "User Code 3",
    usrcodec_4: "User Code 4",
    usrcodec_5: "User Code 5",
    usrcodec_6: "User Code 6",
    usrcodec_7: "User Code 7",
    usrcodec_8: "User Code 8",
    usrcodec_9: "User Code 9",
    usrcodec_10: "User Code 10",
  },
  other: {
    ref_org: "Ref Org",
    rtt: "RTT",
    ref_ctxt: "Ref CTXT",
  },
};
function isNumeric(value) {
  return !isNaN(value) && typeof value !== 'boolean';
}

const ReferralDetails = ({ match }) => {
  const [referral, setReferral] = useState(null);
  const [menuAnchorEl, setMenuAnchorEl] = React.useState(null);
  const [openPrintDialog, setOpenPrintDialog] = React.useState(false);
  const navigate = useNavigate();
  const { patientId, referralId } = useParams();
  const cfg = global.config.restAPI; //"http://localhost:3000/";

  useEffect(() => {
    const query = { internalpatientid: { $eq: patientId }, referralnumber: { $eq: referralId}  } ;
    const url = `${cfg.url + cfg.endpoints.referrals}/?q=${JSON.stringify(query)};`
    const demo = `${cfg.url + cfg.endpoints.referrals}/`

    // Fetch referral details based on the ID from the URL params
    axios
      .get(isNumeric(referralId)?url:demo) // 
      //.get(`${cfg.url + cfg.endpoints.referrals}/?q=${JSON.stringify(query)}`)
      .then((response) => {
        const firstItem = response.data.items[0];
        setReferral(firstItem);
      });
  }, [patientId, referralId, cfg]);

  if (!referral) {
    return <p style={{marginLeft: 24}}>Loading...</p>;
  }

  const menuOpen = Boolean(menuAnchorEl);

  const handleMenuClick = (event) => {
    setMenuAnchorEl(event.currentTarget);
  };
  const handleMenuClose = (e) => {
    setMenuAnchorEl(null);
    switch(e.currentTarget.innerText){
        case "Appointment List":
            navigate(`/clinic/patient/${patientId}/referral/${referralId}/appointment/`)
            break;
        case "Waitlist Management":
            navigate(`/waitlist/patient/${patientId}/referral/${referralId}/waitlist/`)
            break;
        case "RTT Pathway":
            navigate(`../patient/${patientId}/referral/${referralId}/rtt/`)
            break;
        case "Print Referral Letters":
            setOpenPrintDialog(true);
            break;
     default:
      return
    } 
  };

  return (
    <ReferralContainer style={{ background: "#eaebf2", flex: 1 }}>
      <NavAppBar title="Referral Details">
        <Button
          type="primary"
          variant="outlined"
          startIcon={<AssignmentIcon />}
          onClick={() =>{
            const url = `https://jade.demo.miya.cloud/MiyaFlow/0884b5d2-3deb-4983-b695-780493b59dd9/Ward/22b674b7-79d5-4283-8468-d5a2e46425de/Patient/0116282a-564e-58e7-a957-f0be3659afaf/Overview`;
            window.open(url, 'miyaFlow').focus();
          }}
        >
         Miya Record 
        </Button>
        <Button
          type="primary"
          variant="outlined"
          startIcon={<AddIcon />}
          onClick={() =>
            navigate(
              `/clinic/patient/${patientId}/referral/${referralId}/appointment/create`,
            )
          }
        >
          Appointment
        </Button>
        <Button
          variant="outlined"
          style={{ marginLeft: 10 }}
          onClick={handleMenuClick}
        >
          More
        </Button>
        <Menu anchorEl={menuAnchorEl} open={menuOpen} onClose={handleMenuClose}>
          <MenuItem onClick={handleMenuClose}>Modify Referral</MenuItem>
          <MenuItem onClick={handleMenuClose}>Link Referral</MenuItem>
          <MenuItem onClick={handleMenuClose}>Patient Notes</MenuItem>
          <MenuItem onClick={handleMenuClose}>Add to Waitlist</MenuItem>
          <MenuItem onClick={handleMenuClose}>Schedule Appointment</MenuItem>
          <MenuItem onClick={handleMenuClose}>RTT Pathway</MenuItem>
          <MenuItem onClick={handleMenuClose}>Close Referral</MenuItem>
          <MenuItem onClick={handleMenuClose}>Patient Communications</MenuItem>
          <MenuItem onClick={handleMenuClose}>Appointment List</MenuItem>
          <MenuItem onClick={handleMenuClose}>Waitlist Management</MenuItem>
          <MenuItem onClick={handleMenuClose}>Print Referral Letters</MenuItem>
          
        </Menu>
      </NavAppBar>

      <BannerBox>
      <BannerPatient patientId={patientId} onSelect={() => navigate(`/patient/${patientId}`)}/>
      </BannerBox>

      <DisplaySection
        title="General"
        labelMap={labelMapping.general}
        data={referral}
      />
      <div>
      <MiyaRecordSection>
         <AppointmentSummary patientId={patientId} referralId={referralId} /> 
         <WaitlistSummary patientId={patientId} referralId={referralId} /> 
      </MiyaRecordSection>
      </div>
      <DisplaySection
        title="Referring Details"
        labelMap={labelMapping.referring}
        data={referral}
      />
      <DisplaySection
        title="ReferralDetails"
        labelMap={labelMapping.referralDetails}
        data={referral}
      />
      <DisplaySection
        title="Diagnostic Details"
        labelMap={labelMapping.diagnostic}
        data={referral}
      />
      <DisplaySection
        title="Closure"
        labelMap={labelMapping.closure}
        data={referral}
      />
      <DisplaySection
        title="Other"
        labelMap={labelMapping.other}
        data={referral}
      />
          <PrintDialog
            open={openPrintDialog}
            onClose={() => {
              setOpenPrintDialog(false);
            }}
          />
    </ReferralContainer>
  );
};

export default ReferralDetails;
