import * as React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Button } from '@mui/material';

const columns = [
  {
    field: 'code',
    headerName: 'Code',
    width: 150,
    editable: true,
  },
  {
    field: 'name',
    headerName: 'Name',
    width: 250,
    editable: true,
  },
  {
    field: 'action',
    headerName: 'Action',
    width: 150,
    editable: true,
    flex: 1,
    type: 'singleSelect',
    valueOptions: [
      { value: "Doc/PDF", label: "Edit and print" },
      { value: "Printer", label: "Enhanced Print" },
      { value: "Email", label: "Email" },
    ],
  },
  {
    field: 'destination',
    headerName: 'Destination',
    width: 150,
    editable: true,
    flex: 1,
    type: 'singleSelect',
    valueOptions: [
      { value: "Word", label: "Word" },
      { value: "Letter", label: "Letter Print Queue M12" },
      //{ value: "Brail", label: "Brail Print Queue M13" },
      { value: "Email", label: "Email" },
    ],
  },
  {
    field: 'copies',
    headerName: 'Copies',
    width: 110,
    editable: true,
  },
  {
    field: 'printStatus',
    headerName: 'Print Status',
    width: 200,
    editable: true,
    flex: 1,
    type: 'singleSelect',
    valueOptions: [
      { value: "Printer", label: "Printer online" },
      { value: "Normal", label: "Normal/Available" },
    ],
  },
];


const PrintGrid = ({rows}) => {
  const [rowsState, setRowsState] = React.useState(rows);
  const handleRowEdit = (newRow, oldRow) => {
    const updatedRows = rowsState.map((row) =>
      row.id === oldRow.id ? newRow : row
    );
    setRowsState(updatedRows);
  };

  return (
    <div style={{ height: 400, width: '100%' }}>
      <DataGrid
        rows={rowsState}
        columns={columns}
        pageSize={5}
        onRowEditCommit={handleRowEdit}
        hideFooter={true}
        checkboxSelection disableRowSelectionOnClick
      />
    </div>
  );
};

export default PrintGrid;

