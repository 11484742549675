import React from "react";
import { DataGrid } from "@mui/x-data-grid";

const columns = [
  { field: "surname", headerName: "Surname", flex: 1 },
  { field: "date_of_birth", headerName: "Date of Birth", flex: 1 },
  { field: "address", headerName: "Address", flex: 1 },
  { field: "postcode", headerName: "Postcode", flex: 1 },
  { field: "date_of_death", headerName: "Date of Death", flex: 1 },
];

const PatientGrid = ({ patients, onSelect }) => {
  const handleRowClick = (params) => {
    
    console.log("Selected Row ID:", params.id);
    console.log("Selected Row Data:", params);

    onSelect(params.id, params.row);
  };

  return (
    <DataGrid
      rows={patients}
      columns={columns}
      pageSize={10}
      rowsPerPageOptions={[10, 25, 50, 100]}
      pagination
      autoheight
      onRowClick={handleRowClick}
      getRowId={(row) => row.internal_patient_id}
      columnHeaderHeight={25}
    />
  );
};

export default PatientGrid;
