import React, { useState } from "react";
import {
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "dayjs/locale/en-gb";
import styled from "styled-components";
import ComboSpecialty from "../../components/comboSpecialty";
import ComboLookupCode from "../../components/comboLookup";
import XDatePicker from "../../components/xDatePicker";

const GridParent = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  width: 100%;
`;

const GridItem = styled.div`
  display: block;
  flex: 1;
  padding: 0px 4px;
  align-items: center;

  &.btn-group {
    margin-top: 15px;
    text-align: right;
  }

  .btn-primary {
    background-color: #337ab7;
    border-color: #204d74;
    font-size: 14px;
    text-transform: none;
    
    &:hover {
      color: #fff;
      background-color: #286090;
      border-color: #204d74;
    }
  }

  &.small-cell {
    max-width: 120px;
  }

  & > div {
    background-color: #f4f4f4;
  }

  .input-label {
    color: #333;
    font-size: 12px;
    font-weight: 700;
  }
  
  .MuiAutocomplete-inputRoot,
  .MuiOutlinedInput-root {
    background-color: #ffffff;
  }
`;

const ClinicSearchForm = ({ onSubmit }) => {
  //const classes = useStyles();
  const [formData, setFormData] = useState({
    nhs_id: "",
    surname: "",
    name1: "",
    dob: new Date(0),
    age: "",
    sex: "",
    postCode: "",
    searchTypeCode: null,
    appointmentSlotTypeCode: null,
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let fd = formData;

    if (formData.dob.toString() === new Date(0).toString()) fd.dob = "";
    // Call the onSubmit prop with formData
    onSubmit(fd);
  };

  return (
    <form onSubmit={handleSubmit}>
      <GridParent>
        <GridItem xs={12} sm={6}>
          <ComboLookupCode
            label="Appointment Slot Type"
            value={formData.appointmentSlotTypeCode}
            refDomain="appointmentSlotType"
            onChange={(newData) => {
              setFormData({ ...formData, appointmentSlotTypeCode: newData });
            }}
          />
        </GridItem>
        <GridItem xs={12} sm={6}>
          <ComboLookupCode
            label="Apppointment Priority"
            value={formData.appointmentPriorityCode}
            refDomain="appointmentPriority"
            onChange={(newData) => {
              setFormData({ ...formData, appointmentPriorityCode: newData });
            }}
          />
        </GridItem>

        <GridItem xs={12} sm={6}>
          <ComboLookupCode
            label="Search Level"
            value={formData.unknown} //search query only
            refDomain="searchLevel"
            onChange={(newData) => {
              setFormData({ ...formData, searchLevel: newData });
            }}
          />
        </GridItem>
        <GridItem xs={12} sm={6}>
          <ComboLookupCode
            label="Search Method"
            value={formData.searchMethodCode} //search query only
            refDomain="searchMethod"
            onChange={(newData) => {
              setFormData({ ...formData, searchMethodCode: newData });
            }}
          />
        </GridItem>
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
          <GridItem xs={12} sm={6}>
            <InputLabel className="input-label">Search Date</InputLabel>
            <XDatePicker
              value={formData.searchdate}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(newValue) =>
                setFormData({ ...formData, searchdate: newValue })
              }
            />
          </GridItem>
          <GridItem xs={12} sm={6}>
            <InputLabel className="input-label">Time</InputLabel>
            <TimePicker
              value={formData.searchTime}
              onChange={(newValue) =>
                setFormData({ ...formData, searchTime: newValue })
              }
              slotProps={{ textField: { size: 'small', style: {width: 300, marginTop: 0}  } }}
            />
          </GridItem>
        </LocalizationProvider>
      </GridParent>
      <GridParent>
        <GridItem>
        </GridItem>
        <GridItem>
        </GridItem>
        <GridItem className="btn-group" xs={12} sm={6}>
          <div style={{width: 300}}>
            <Button className="btn-primary" type="submit" variant="contained">
              Search Clinics
            </Button>
          </div>
        </GridItem>
      </GridParent>
    </form>
  );
};

export default ClinicSearchForm;
