import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Button, Divider, Drawer, Menu, MenuItem } from "@mui/material";

import { useMsal } from "@azure/msal-react";

import { loginRequest } from "../authConfig";
import { callMsGraph } from "../graph";
import styled from "styled-components";

const ParentPanel = styled.div`
display: flex;
    align-items: flex-end;
    align-content: flex-end;
}
`
const SearchPanel = styled.div`
  margin: 0px 10px 10px 0px;
  font-size: 14px;
  font-weight: bold;
  color: #c2c2c2;
  cursor: pointer;
  &:hover {
   color: white;
  }
`

const Section = styled.div`
  width: 250px;
  color: #646464;
  margin: 10px 0px 5px 14px;
  font-weight: 400;
  font-size: 13px
`
const ProfilePanel = styled.div`
  color: #c2c2c2;
  align-content: space-evenly;
  margin: 0px 20px 7px 0px;
  border-left: 1px solid #878787;
  padding: 5px 7px;
  width: 110px;
  height: 30px;
  font-size: 11px;
  font-weight: bold;
cursor:pointer;
  & .card-title {
    font-size: 14px;
  }
  &:hover {
   color: white;
  }
`;
/**
 * Renders information about the signed-in user or a button to retrieve data about the user
 */
const UserMenu = () => {
  const [menuAnchorEl, setMenuAnchorEl] = React.useState(null);
  const { instance, accounts } = useMsal();
  const [graphData, setGraphData] = useState(null);
  const open = Boolean(menuAnchorEl);
  const navigate = useNavigate();

  function RequestProfileData() {
    // Silently acquires an access token which is then attached to a request for MS Graph data
    instance
      .acquireTokenSilent({
        ...loginRequest,
        account: accounts[0],
      })
      .then((response) => {
        callMsGraph(response.accessToken).then((response) =>
          setGraphData(response),
        );
      });
  }

  return (
    <ParentPanel>
            <SearchPanel onClick={()=>navigate('/patient/search')}> Patient Search </SearchPanel>
      <ProfilePanel onClick={(e) => setMenuAnchorEl(e.currentTarget)}>
        <div className="card-title">{accounts[0].name}</div>
        <div>Administrator</div>
      </ProfilePanel>
      <Menu open={open} onClose={() => setMenuAnchorEl(null)} anchorEl={menuAnchorEl}>
       <Section> Role </Section> 
        <MenuItem> Administrator</MenuItem>
       <Section> Facility </Section> 
        <MenuItem> Demo Trust </MenuItem>
        <br />
        <Divider/>
       <Section> Options</Section> 
        <MenuItem onClick={() => navigate('/clinic/wardAttenders/')}> Ward Attenders</MenuItem>
        <MenuItem onClick={() => navigate('/clinic/config/')}> Configure Clinics</MenuItem>
        <MenuItem onClick={() => navigate('/theatre/config/')}> Configure Theatres</MenuItem>
        <br />
        <Divider/>
       <Section> Login and Security</Section> 
        <MenuItem
          onClick={() =>
            instance.logoutRedirect({
              postLogoutRedirectUri: "/#/outpatient/referrals",
            })
          }
        >
          Sign-out
        </MenuItem>

        <Divider/>
        <MenuItem> About Miya Patient Admin</MenuItem>
      </Menu>
    </ParentPanel>
  );
};

export default UserMenu;
