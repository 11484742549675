import React, { useState, useEffect } from "react";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import "../config";


const XCheckbox = ({ label, value, onChange }) => {
  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={value}
          onChange={onChange}
          color="primary"
        />
      }
      label={label}
      style={{ marginLeft: -30, transform: "scale(0.85)", color: "#727272" }}
    
    />
  );
};

export default XCheckbox;
