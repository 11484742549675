import React, { useState } from "react";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import Button from "@mui/material/Button";
import ClinicSearch from "./clinicSearch";

function ClinicSearchDialogue(props) {
  const { onClose, value, open, clinicCode, specialtyCode } = props;

  const [currentVal, setCurrentVal] = useState(false);

  const handleClose = () => {
    onClose(currentVal);
  };

  const handleSelect = (newObj) => {
    setCurrentVal(newObj);
    onClose(newObj);
  };

  return (
    <Dialog
      fullWidth={true}
      onClose={handleClose}
      open={open}
      PaperProps={{
        sx: {
          maxWidth: "lg",
          minHeight: "90vh",
        },
      }}
    >
      <DialogTitle>
        Select Session Slot - {clinicCode}
        {specialtyCode}
      </DialogTitle>
      <ClinicSearch
        onSelect={handleSelect}
        clinicCode={clinicCode}
        specialtyCode={specialtyCode}
      />
    </Dialog>
  );
}
export default ClinicSearchDialogue;
