// pages/ClinicMgr.js
import React, { useState, useEffect } from "react";
import axios from "axios";
import styled from "styled-components";
import { useParams, useNavigate } from "react-router-dom";
import { Button, FormControl, MenuItem, Select } from "@mui/material";
import XButtonGroup from "../components/xButtonGroup";
import AddIcon from "@mui/icons-material/Add";
import AppointmentGrid from "../components/appointmentGrid";
import ClinicTabBookings from "../components/clinic/clinicTabBookings";
import ClinicTabAvailability from "../components/clinic/clinicTabAvailability";
import ClinicTabCalendar from "../components/clinic/clinicTabCalendar";
import PlaylistAddCheckIcon from "@mui/icons-material/PlaylistAddCheck";
import "../config";
import { FormContainer, Tab, MainContainer } from "../components/containers";

/* replaced with FormContainer
export const FormContainer = styled.div`
  background-color: #f4f4f4; //gray
  display: flex;

  &.alt {
    background-color: #3b4e6b; //darkblue
    height: 58px;
    display: flex;
    padding-left: 20px;
    color: white;
    justify-content: flex-start;
    align-items: flex-end;
  }
  & h2 {
    padding-right: 25px;
    font-size: 18px;
  }

  align-items: center;

  & div.flex1 {
    flex: 1;
  }
  & div.view-mode {
    padding-left: 20px;
  }
`;
*/
/* replaced with GridContainer 
export const ClinicMgrContainer = styled.div`
  .MuiDataGrid-columnHeaders {
    background-color: #ebf3fc;

    .MuiDataGrid-columnHeader {
      border-right: 1px solid #ededed;
    }
  }

  .MuiDataGrid-cell {
    border-right: 1px solid #ededed;
  }

  .MuiDataGrid-row {
    &:nth-child(even) {
      background-color: #fafafa;
    }
  }
  flex: 1;
  overflow: scroll;
`;
*/

const ClinicMgr = () => {
  const { types, view } = useParams();
  const navigate = useNavigate();
  const title = "Clinic Management";
  const [tab, setTab] = useState("ALL");
  const [viewMode, setViewMode] = useState(view ? view : "Availability");

  const handleViewModeChange = (e) => {
    setViewMode(e.target.value);
    navigate("/clinic/mgr/" + e.target.value);
  };

  return (
    <MainContainer>
      <FormContainer className={["alt"]}>
        <h2>{title}</h2>
        <Tab
          className={tab === "ALL" ? ["selected"] : []}
          onClick={() => setTab("ALL")}
        >
          <div>All Clinic</div>
          <div>Bookings</div>
        </Tab>
        <Tab
          className={tab === "ORTH" ? ["selected"] : []}
          onClick={() => setTab("ORTH")}
        >
          <div>NH </div>
          <div>ORTH Hip & Knee</div>
        </Tab>
        <Tab
          className={tab === "CARD" ? ["selected"] : []}
          onClick={() => setTab("CARD")}
        >
          <div>NH</div>
          <div>Cardiology</div>
        </Tab>
      </FormContainer>
      {viewMode === "Bookings" && (
        <ClinicTabBookings tab={tab} onViewModeChange={handleViewModeChange} />
      )}
      {viewMode === "Availability" && (
        <ClinicTabAvailability
          tab={tab}
          onViewModeChange={handleViewModeChange}
        />
      )}
      {viewMode === "Calendar" && (
        <ClinicTabCalendar tab={tab} onViewModeChange={handleViewModeChange} />
      )}
    </MainContainer>
  );
};

export default ClinicMgr;
