import React from "react";
import styled from "styled-components";

const RowStyled = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 80%;
  margin: 0% 10%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 55%;
  margin: 0% 30%;
`;
const ColumnStyled = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 90%;
  flex: 1;
  &.alert {
    margin: 10px -20px;
  }
`;

const SectionTitleStyled = styled.div`
  /*text-align: end;
    color: #727272;
    font-weight: 500;
    margin: 32px 10vw 5px;
    font-size: 0.8rem;
    text-transform: uppercase;
    margin-right: 73vw;*/

  text-align: end;
  color: #727272;
  font-weight: 500;
  margin: 32px 10vw 0px;
  font-size: 0.8rem;
  text-transform: uppercase;
  margin-right: 73vw;
  height: 0px;
`;
const VSpaceStyled = styled.div`
  height: 50px;
`;

const FormStyled = styled.div`
    margin: 50px 0px;
    overflow-y: auto;
`

const FooterStyled = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  position: ${props=>props.dialog?"relative":"fixed"};
  width: 100%;
  bottom: 0;

  & > div {
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  .btn-primary {
    background-color: #337ab7;
    border-color: #204d74;
    font-size: 14px;
    text-transform: none;
    &:hover {
      color: #fff;
      background-color: #286090;
      border-color: #204d74;
    }
  }

  .btn-cancel {
    background-color: #ffffff;
    border-color: #204d74;
    font-size: 14px;
    text-transform: none;
    margin-right: auto;
    &:hover {
      color: #333;
      background-color: #e6e6e6;
      border-color: #adadad;
    }
  }
`;
export function Row({ children }) {
  return <RowStyled>{children}</RowStyled>;
}

export function Column({ children }) {
  return <ColumnStyled>{children}</ColumnStyled>;
}
export function SectionTitle({ children }) {
  return <SectionTitleStyled>{children}</SectionTitleStyled>;
}
export function VSpace() {
  return <VSpaceStyled/>;
}
export function Form({children}) {
  return <FormStyled>{children}</FormStyled>
}
export function Footer ({dialog, children}) {
  return <FooterStyled dialog={dialog}>{children}</FooterStyled>
}
