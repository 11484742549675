import React, { useState, useEffect } from "react";
import {
  Autocomplete,
  Divider,
  TextField,
  Button,
  Box,
  FormControlLabel,
  Checkbox,
  InputLabel,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import axios from "axios";
import "dayjs/locale/en-gb";
import { useNavigate, useParams } from "react-router-dom";

import NavAppBar from "../components/navAppBar";
import ComboLookupCode from "../components/comboLookup";
import ComboProfDets from "../components/comboProfDets";
import ComboSpecialty from "../components/comboSpecialty";
import ComboClinic from "../components/comboClinic";
import ComboClinicGroup from "../components/comboClinicGroup";
import styled from "styled-components";
import {
  Footer,
  Form,
  Row,
  Column,
  SectionTitle,
  VSpace,
} from "../components/formLayout";
import "../config";
import InfoSnackbar from "../components/infoSnackbar";
import DialogQuestion from "../components/dialogQuestion";
import BannerPatient from "../components/bannerPatient";
import BannerReferral from "../components/bannerReferral";
import XDatePicker from "../components/xDatePicker";
import XCheckbox from "../components/xCheckbox";
import PrintDialog from "../components/waitlist/waitlistPrint";
import PatientSelector from "../components/patientSelector";
import ReferralSelector from "../components/referralSelector";

const BoxButtons = styled.div`
  display: flex;
  flex-direction: row-reverse;
  background-color: #4a4c52;
  & button {
    margin: 10px;
  }
`;

const WaitListContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  .input-label {
    font-size: 12px;
  }
`;

// Function to format date as "DD/MMM/YYYY"
const formatDate = (inputDate) => {
  const options = { day: "2-digit", month: "short", year: "numeric" };
  return new Date(inputDate).toLocaleDateString("en-GB", options);
};

const WaitlistForm = () => {
  const navigate = useNavigate();
  const { patientId, referralId } = useParams();
  const [formErrors, setFormErrors] = useState({});
  const [formData, setFormData] = useState({
    internalPatientId: patientId,
    referralId: referralId, // "11/JAN/2024"
    dateOnList: null,
    patientCategory: null,
    plannedAppt: null,
    appSlotType: null,
    appDate: null,
    appPriority: null,
    appNote: null,
    clinicCode: null,
    clinicGroup: null,
    specialty: null,
    consultant: null,
    slotPurpose: null,
    expectedBookingSystem: null,
    transportReq: null,
    shortCallAvail: null,
    noticeReq: null,
  });

  const [showInfoSnack, setShowInfoSnack] = useState();
  const [openPrintQuestion, setOpenPrintQuestion] = useState(false);
  const [openPrintDialogue, setOpenPrintDialogue] = useState(false);
  const resturl =
    global.config.restAPI.url + global.config.restAPI.endpoints.waitlistEntries + "/";

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setFormData({
      ...formData,
      [name]: checked,
    });
  };

  const handleComboChange = (domain, val) => {
    setFormData({ ...formData, [domain]: val });
  };

  const checkFormErrors = (postObj) => {
    const required = [
      "internalPatientId", // url parameter
      "referralId", // url parameter
      "dateOnList",
      "appPriority"
    ];

    // Check to post obj has required fields containing data
    const errors = required.filter((key) => !postObj[key]);
    const newFormErrors = Object.fromEntries(
      errors.map((key) => [key, "Required"]),
    );
    console.log("Missing Fields: " + errors.toString());

    setFormErrors(newFormErrors);
    return errors.length;
  };

  const handleSave = () => {
    // Make a POST request to save or update the data
    if (checkFormErrors(formData) > 0) return;

    const data = {
      internalpatientid: formData.internalPatientId,
      //originaldateonlist: "04/09/2019",
      currentdateonlist: formData.dateOnList.format("DD/MMM/YYYY"), // "04/09/2019",
      entrystatuscode: "A", //"A""Waiting",
      appointmentprioritycode: formData.appPriority, //"R",
      specialty: formData.specialty, //"CARD",
      locationtype: "LOC",
      location: "OPD",
      owninghospital: "DGH",
      expectedbookingsystemcode: formData.expectedBookingSystem,  //PB",
      referralnumber: formData.referralId,
      patientcategorycode: formData.patientCategory, //"1",
      slotpurposecode: formData.appointmentSlotType,
      clinic: formData.clinicCode,
      consultant: formData.consultant,
    };

    axios
      .post(resturl, data)
      .then((response) => {
        setShowInfoSnack(
          `Waitlist Created (${JSON.stringify(response.data)}).`,
        );
        setOpenPrintQuestion(true); // ask to create referral
      })
      .catch((error) => {
        console.error("Error saving data:", error);
        setShowInfoSnack(`Sorry, there was a problem.`);
      });
  };

  return (
    <WaitListContainer style={{ flex: 1 }}>
      <NavAppBar title="Add Outpatient Waitlist Entry"></NavAppBar>
      {(referralId || (formData.patientObj && formData.referralObj)) && (
        <BannerReferral
          patientId={patientId || formData.internalPatientId}
          patientObj={formData.patientObj}
          referralId={referralId}
          onSelect={() => {
            navigate(`/referral/patient/${patientId}/referral/${referralId}`)
          }
          }
        />
      )}
      {((patientId && !referralId) ||
        (formData.patientObj && !formData.referralObj)) && (
          <BannerPatient
            patientId={patientId || formData.internalPatientId}
            patientObj={formData.patientObj}
            onSelect={(id) => navigate(`/patient/${id}`)}
          />
        )}
      <Form>
        <Box spacing={2}>
          {!patientId && (
            <div>
              <SectionTitle>Patient Selection </SectionTitle>
              <Row>
                <Column>
                  <PatientSelector
                    selectedObj={formData.patientObj}
                    onChange={(id, rowData) => {
                      setFormData({
                        ...formData,
                        patientObj: rowData,
                        internalPatientId: id,
                      });
                    }}
                    error={formErrors.internalPatientId}
                  />
                </Column>
                <Column></Column>
              </Row>
            </div>
          )}
          {formData.patientObj && !referralId && (
            <Row>
              <Column>
                <ReferralSelector
                  selectedObj={formData.referralObj}
                  patientId={formData.internalPatientId}
                  onChange={(id, rowData) => {
                    setFormData({
                      ...formData,
                      referralObj: rowData,
                      referralId: id,
                    });
                  }}
                  error={formErrors.referralId}
                />
              </Column>
              <Column></Column>
              <Divider />
            </Row>
          )}

          <SectionTitle>Waitlist Details</SectionTitle>

          <Row>
            <LocalizationProvider
              dateAdapter={AdapterDayjs}
              adapterLocale="en-gb"
            >
              <Column>
                <InputLabel className="input-label">Date On List*</InputLabel>
                <XDatePicker
                  value={formData.dateOnList}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(newValue) =>
                    setFormData({ ...formData, dateOnList: newValue })
                  }
                  error={formErrors.dateOnList}
                />
              </Column>
              <Column>
                <ComboLookupCode
                  label="Patient Category"
                  value={formData.patientCategory}
                  refDomain="patientCategory"
                  onChange={(newData) => {
                    handleComboChange("patientCategory", newData);
                  }}
                />
              </Column>
            </LocalizationProvider>
          </Row>
          <Row></Row>

          <Row>
            <Column>
              <ComboLookupCode
                label="Appointment Slot Type"
                value={formData.appSlotType}
                refDomain="appointmentSlotType"
                onChange={(newData) => {
                  handleComboChange("appointmentSlotType", newData);
                }}
              />
            </Column>
            <Column>
              <LocalizationProvider
                dateAdapter={AdapterDayjs}
                adapterLocale="en-gb"
              >
                <InputLabel className="input-label">
                  Appointment Date
                </InputLabel>
                <XDatePicker
                  value={formData.appDate}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(newValue) =>
                    setFormData({ ...formData, appDate: newValue })
                  }
                  error={formErrors.appDate}
                />
              </LocalizationProvider>
            </Column>
          </Row>
          <Row>
            <Column>
              <ComboLookupCode
                label="Appointment Priority*"
                value={formData.appPriority}
                refDomain="appointmentPriority"
                onChange={(newData) => {
                  handleComboChange("appPriority", newData);
                }}
                error={formErrors.appPriority}
              />
              <XCheckbox
                label="Planned Appointment"
                name="plannedAppt"
                checked={formData.plannedAppt}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    plannedAppt: e.target.checked,
                  })
                }
              />
            </Column>
            <Column>
              <InputLabel className="input-label">Notes</InputLabel>
              <TextField
                placeholder="notes"
                value={formData.appNotes}
                onChange={handleInputChange}
                multiline
                rows={4}
                defaultValue=""
                size="small"
                style={{ width: 300, marginTop: 0 }}
              />
            </Column>
          </Row>
          <SectionTitle>Booking For</SectionTitle>
          <Row>
            <Column>
              <ComboClinic
                label="Clinic"
                value={formData.clinicCode}
                onChange={(code) => {
                  setFormData({ ...formData, clinicCode: code });
                  // auto populate specialty and consultant
                }}
              />
            </Column>
            <Column>
              <ComboClinicGroup
                label="Clinic Group"
                value={formData.clinicGroup}
                onChange={(code) => {
                  setFormData({ ...formData, clinicGroup: code });
                }}
              />
            </Column>
          </Row>
          <Row>
            <Column>
              <ComboSpecialty
                label="Specialty" /// add auto populate from clinic
                value={formData.specialty}
                onChange={(code) => {
                  setFormData({ ...formData, specialty: code });
                }}
              />
            </Column>
            <Column>
              <ComboProfDets
                label="Consultant" /// add auto populated from clinic rest
                value={formData.consultant}
                type="consultant"
                specialty={formData.specialty}
                onChange={(code) => {
                  setFormData({ ...formData, consultant: code });
                }}
              />
            </Column>
          </Row>
          <SectionTitle>Appointment Details</SectionTitle>
          <Row>
            <Column>
              <Autocomplete
                options={["Check Up", "General Appointment"]}
                style={{ width: 300 }}
                renderInput={(params) => (
                  <React.Fragment>
                    <InputLabel className="input-label">
                      Slot Purpose
                    </InputLabel>
                    <TextField
                      {...params}
                      variant="outlined"
                      InputLabelProps={{ shrink: false }}
                      style={{ marginTop: 0 }}
                    />
                  </React.Fragment>
                )}
                size="small"
                onChange={(newData) => {
                  handleComboChange("slotPurpose", newData);
                }}
              />
            </Column>
            <Column>
              <ComboLookupCode
                label="Expected Booking System"
                value={formData.expectedBookingSystem}
                refDomain="bookingSystem"
                onChange={(newData) => {
                  handleComboChange("expectedBookingSystem", newData);
                }}
              />
            </Column>
          </Row>
          <Row>
            <Column>
              <XCheckbox
                label="Transport Required"
                checked={formData.transportReq}
                onChange={handleCheckboxChange}
                name="transportReq"
              />
              <XCheckbox
                label="Short Call Availablility"
                checked={formData.shortCallAvail}
                onChange={handleCheckboxChange}
                name="shortCallAvail"
              />
              <InputLabel className="input-label">Notice Required</InputLabel>
              <TextField
                name="noticeReq"
                type="numeric"
                defaultValue="0"
                value={formData.noticeReq}
                onChange={handleInputChange}
                size="small"
                style={{ width: 300, marginTop: 0 }}
              />
            </Column>
            <Column>
              <InputLabel className="input-label">Addition Text</InputLabel>
              <TextField
                placeholder="text"
                name="referralText"
                multiline
                rows={4}
                defaultValue=""
                value={formData.referralText}
                onChange={handleInputChange}
                size="small"
                style={{ width: 300, marginTop: 0 }}
              />
            </Column>
          </Row>
          <Footer>
            <div>
              <BoxButtons>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSave}
                  className="btn-primary"
                >
                  Save
                </Button>
                <Button
                  variant="outlined"
                  onClick={() => {
                    navigate(-1);
                  }}
                  className="btn-cancel"
                >
                  Cancel
                </Button>
              </BoxButtons>
            </div>
          </Footer>
          <InfoSnackbar
            showMessage={showInfoSnack}
            onClose={() => setShowInfoSnack(false)}
          />
          <DialogQuestion
            title="Print?"
            desc="Would you like to print a waitlist letter?"
            open={openPrintQuestion}
            onClose={(isOK) => {
              const patId = patientId || formData.internalPatientId;
              const refId = referralId || formData.referralId;
              setOpenPrintQuestion(false);
              console.log("question response:" + (isOK ? "OK" : "Cancel"));
              if (isOK) {
                setOpenPrintDialogue(true);
              } else {
                navigate(
                  `../patient/${patId}/referral/${refId}/`, // return to referral details
                );
              }
            }}
          />
          <PrintDialog
            open={openPrintDialogue}
            onClose={() => {
              navigate('/waitlist/mgr/')
              /*const patId = patientId || formData.internalPatientId;
              const refId = referralId || formData.referralId;
              if (refId !== "undefined")
                navigate(`../patient/${patId}/referral/${refId}/`);
              else navigate(`../patient/${patId}/referrals/`);
*/
            }}
          />
        </Box>
      </Form>
    </WaitListContainer>
  );
};

export default WaitlistForm;
