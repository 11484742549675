import React, { useState, useEffect } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { InputLabel } from "@mui/material";
import axios from "axios";
import "../config";

const ComboClinic = ({ label, value, error, onChange }) => {
  const [data, setData] = useState([]);
  const cfg = global.config.restAPI;

  useEffect(() => {
    const query = `${cfg.url + cfg.endpoints.clinicSessions}`;
    const fetchData = async () => {
      axios.get(query).then((response) => {
        setData(response.data.items);
      });
    };

    if (cfg.endpoints.discipDets.specialty) fetchData();
    //    else
    //throw(new Error(`Can't find ${refDomain} in config.js`));
  }, [cfg]);

  return (
    <Autocomplete
      isOptionEqualToValue={(option, value) => option.clinic === value}
      onChange={(e, newData) => {
        const val = newData ? newData.clinic : null;
        onChange(val);
      }}
      options={data}
      getOptionLabel={(o) => (o.clinic? `${o.clinic} - ${o.descript} `:o)}
      style={{ width: 300 }}
      value={value}
      renderInput={(params) => (
        <React.Fragment>
          <InputLabel className="input-label">{label}</InputLabel>
          <TextField
            {...params}
            placehoder={label}
            variant="outlined"
            error={error ? true : false}
            helperText={error}
            InputLabelProps={{ shrink: false }}
            style={{ marginTop: 0 }}
          />
        </React.Fragment>
      )}
      size="small"
    />
  );
};

export default ComboClinic;
