import * as React from 'react';
import Snackbar from '@mui/material/Snackbar';
import Fade from '@mui/material/Fade';


export default function InfoSnackbar({showMessage, onClose}) {


  return (
      <Snackbar
        open={showMessage}
        onClose={onClose}
        TransitionComponent={Fade}
        message={showMessage?showMessage:"none"}
        autoHideDuration={1200}
        anchorOrigin={{ vertical:"bottom", horizontal:"center"}}
      />
  );
}
